import React from 'react';


import { createStackNavigator } from '@react-navigation/stack';
import InformacoesGerais from '../pages/informacoes';
import InformacoesContrato from '../pages/informacoesContrato';
import InformacoesEstoque from '../pages/informacoesEstoque';
import InformacoesTouro from '../pages/informacoesDoTouro';
import InformacoesDespesas from '../pages/informacoesDespesas';
import InformacoesRoyalties from '../pages/informacoesRoyalties';
import DadosGeraisTouro from '../pages/dadosGerais';
import PicCarousel from '../components/carousel';
import FotosTouro from '../components/fotosDoTouro';
import ChatSelection from '../pages/chatSelection';
import ChatUser from '../pages/chatUser';

const Stack = createStackNavigator();

const GeneralInformationStackNavigator = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="InformacoesGerais"
        component={InformacoesGerais}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesContrato"
        component={InformacoesContrato}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesEstoque"
        component={InformacoesEstoque}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesTouro"
        component={InformacoesTouro}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="FotosTouro"
        // @ts-ignore
        component={FotosTouro}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesDespesas"
        component={InformacoesDespesas}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesRoyalties"
        component={InformacoesRoyalties}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="DadosGeraisTouro"
        component={DadosGeraisTouro}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ChatSelection"
        component={ChatSelection}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ChatUser"
        component={ChatUser}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PicCarousel"
        component={PicCarousel}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default GeneralInformationStackNavigator;