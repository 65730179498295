import 'intl';
import 'intl/locale-data/jsonp/pt-BR';

export const canGoToPreviousPage = (currentPage: number): boolean => {
  if (currentPage === 1) return false
  return true
}

export const canGoToNextPage = (currentPage: number, lastPage: number) => {
  if (!lastPage) return false
  if (currentPage === lastPage) return false
  return true
}

export const brCurrencyMask = (value: number, prefix = "R$ "): string => {
  if (value) {
    const formattedValue = value
      .toString()

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat("pt-BR", options).format(
      parseFloat(formattedValue)
    );

    return `${prefix}${result}`;
  }
  return "";
};