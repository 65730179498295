import React, { useContext, useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  TextInput,
} from 'react-native';
import { styles, ids } from './styles';
import BullInformationContext from '../../services/bullInformationContext';
import api from '../../services/api';
import DropDownPicker from 'react-native-dropdown-picker';
import { Icon, Loading } from '../../shared/components';
import { Colors, canGoToNextPage, canGoToPreviousPage } from '../../shared/utils';

const TableTouroAdm = () => {
  const [bullInformation, setBullInformation] = useContext(BullInformationContext);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableTemp, setTableTemp] = useState<any[]>([]);
  const [tableDataToShow, setTableDataToShow] = useState<any[]>([]);
  const [allBulls, setAllBulls] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [table3, setTable3] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [finalIndex, setFinalIndex] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');

  const onPress = (data: any[]) => {
    const newData = {
      codTou: data[0],
      nomTou: data[1],
      dtNasc: data[2],
    };
    // @ts-ignore
    setBullInformation(newData);
  };

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push({ label: `${i}`, value: `${i}` });
    }
    setDropDownItems(temp);
  }, [totalPages]);

  useEffect(() => {
    let paginaAtual = currentPage;

    let inicial = paginaAtual * 10;
    let final = inicial + 10;

    setInitialIndex(inicial);
    setFinalIndex(final);
  }, [currentPage]);

  useEffect(() => {
    if (tableData.length > 0) {
      if (tableData.length < 10) setTableDataToShow(tableData.slice(0, tableData.length));
      else setTableDataToShow(tableData.slice(initialIndex, finalIndex));
    }
  }, [tableData, initialIndex, finalIndex]);

  const loadBulls = async () => {
    try {
      setLoading(true);
      const response = await api.post('/vidaTouroDadosIniciais', {
        cnpjCpf: 'TODOS',
      });
      if (!response?.data?.result) {
        return
      }
      const allBulls = response.data.result;
      const filteredAllBulls = allBulls.reduce((accumulator: any, current: any) => {
        let exists = accumulator.find((item: any) => {
          return item.nomTou === current.nomTou;
        });
        if (!exists) {
          accumulator = accumulator.concat(current);
        }
        return accumulator;
      }, []);
      setTableTemp(filteredAllBulls);
      setAllBulls(filteredAllBulls);
      setTotalPages(Math.ceil(filteredAllBulls.length / 10) - 1);
    } catch (e) {
      console.log(e);
      // @ts-ignore
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Erro',
        text2: 'Não foi possível buscar os usuários',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadBulls();
  }, []);

  useEffect(() => {
    if (allBulls.length === 0) return;
    let getTouro: any[] = [];
    const vidaTouroContratosAdendos: any[] = [];

    allBulls.forEach((elemento: any) => {
      getTouro.push({
        codTou: elemento.codTou,
        nomTou: elemento.nomTou, // Nome do Touro
        dtNasc: elemento.dtNasc, // Data de Nascimento
        acao: '',
      });
    });

    getTouro.forEach((elemento: any) => {
      let acao = [elemento.codTou, elemento.nomTou, elemento.dtNasc];
      vidaTouroContratosAdendos.push([
        elemento.codTou, //nome touro
        elemento.nomTou, // Nome guerra (apelido)
        elemento.dtNasc, // tipo movimento do estoque
        acao,
      ]);
    });

    setLoading(false);
    setTableData(vidaTouroContratosAdendos);
    setTable3(vidaTouroContratosAdendos);
  }, [allBulls]);

  //busca por nome do touro
  useEffect(() => {
    let temp: any[] = [];
    if (tableTemp) {

      const filterData = tableTemp.filter(item => {
        return item.apeTou.toLowerCase().includes(searchText.toLowerCase());
      });
      // array of arrays with filter data
      filterData.forEach((elemento: any) => {
        let acao = [elemento.codTou, elemento.nomTou, elemento.dtNasc];
        temp.push([
          elemento.codTou, //nome touro
          elemento.nomTou, // Nome guerra (apelido)
          elemento.dtNasc, // tipo movimento do estoque
          acao,
        ]);
      });
      if (searchText === '') {
        setTableData(table3);
      } else setTableData(temp);
    }

  }, [searchText]);

  const handleGoToFirstPage = () => {
    setCurrentPage(1);
  };

  const handleGoToLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleGoToPreviousPage = () => {
    // @ts-ignore
    setCurrentPage(parseInt(currentPage, 10) - 1);
  };

  const handleGoToNextPage = () => {
    // @ts-ignore
    setCurrentPage(parseInt(currentPage, 10) + 1);
  };

  function ListItem(data: any) {
    return data[0] === '' ? null : (
      // @ts-ignore
      <View style={styles.listItem}>
        {/* @ts-ignore */}
        <Text style={styles.listItemNome}>{data[1]?.toUpperCase()}</Text>
        <View style={{ flexDirection: 'row' }}>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View style={{ flex: 1, marginTop: 5 }}>
            <View style={{ flexDirection: 'row' }}>
              {/* @ts-ignore */}
              <Text style={styles.listItemTextLeft}>Código: </Text>
              {/* @ts-ignore */}
              <Text style={styles.listItemTextRight}>{data[0]}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}>
          <TouchableWithoutFeedback onPress={() => onPress(data)}>
            {/* @ts-ignore */}
            <Text style={styles.listItemEditar}>VISUALIZAR</Text>
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  }

  return (
    <>
      {loading ? <Loading /> : (
        <>
          <TextInput
            // @ts-ignore
            style={styles.inputBusca}
            placeholder="Pesquisar"
            placeholderTextColor={Colors.lightGreyColor}
            value={searchText}
            onChangeText={(text) => setSearchText(text)}
          />
          {tableDataToShow.map((rowData, index) => {
            return ListItem(rowData)
          })}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: 18
            }}>
            <Icon
              name="fast-rewind"
              disabled={!canGoToPreviousPage(currentPage)}
              onPress={handleGoToFirstPage}
            />
            <Icon
              name="chevron-left"
              disabled={!canGoToPreviousPage(currentPage)}
              onPress={handleGoToPreviousPage}
            />
            {/* @ts-ignore */}
            <Text style={styles.pageNumber}>{`${parseInt(currentPage, 10)}/${totalPages}`}</Text>
            <Icon
              name="chevron-right"
              disabled={!canGoToNextPage(currentPage, totalPages)}
              onPress={handleGoToNextPage}
            />
            <Icon
              name="fast-forward"
              disabled={!canGoToNextPage(currentPage + 1, totalPages)}
              onPress={handleGoToLastPage}
            />
            <DropDownPicker
              items={dropDownItems}
              open={open}
              setOpen={setOpen}
              setItems={setDropDownItems}
              value={currentPage}
              setValue={setCurrentPage}
              defaultIndex={1}
              containerStyle={{ height: 40, width: 90 }}
              style={{ backgroundColor: '#fafafa' }}
              // @ts-ignore
              placeholder={currentPage}
              dropDownDirection="TOP"
              listMode="SCROLLVIEW"
              itemStyle={{
                justifyContent: 'flex-start',
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 20,
                color: '#000',
                backgroundColor: '#fafafa',
              }}
              dropDownStyle={{ backgroundColor: '#fafafa' }}
            />
          </View>
        </>
      )}
    </>
  );
};

export default TableTouroAdm;
