import React, { forwardRef, Ref, ForwardedRef } from 'react';
import { TextInput, TextInputProps } from 'react-native';
import { styles } from './styles';

interface InputProps extends TextInputProps {
  placeholder?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  icon?: React.ReactNode;
  onSubmitEditing?: () => void;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
}

const Input = forwardRef(
  (
    {
      placeholder,
      value,
      onChangeText,
      icon: Icon,
      onSubmitEditing,
      autoCapitalize,
      ...props
    }: InputProps,
    ref: ForwardedRef<TextInput>,
  ) => {
    return (
      <TextInput
        ref={ref as Ref<TextInput>}
        placeholder={placeholder}
        style={styles.container}
        onChangeText={onChangeText}
        value={value}
        onSubmitEditing={onSubmitEditing}
        autoCapitalize={autoCapitalize}
        {...props}
      />
    );
  },
);

export default Input;
