import StyleSheet from "react-native-media-query";
// @ts-ignore
import { vh } from "react-native-expo-viewport-units";
import { Platform } from "react-native";
import { Colors } from "../../shared/utils";

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.maincolor,
  },
  contentContainer: {
    flex: 1,
  },
  textsContainer: {
    width: "100%",
  },
  content: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: Colors.color1,
    ...Platform.select({
      web: {
        // alignSelf: "flex-start",
        // marginLeft: vw(5),
      },
      default: {},
    }),
  },
  mainText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.color1,
    marginTop: 16,
    ...Platform.select({
      web: {
        // alignSelf: "flex-start",
        // marginLeft: vw(5),
      },
      default: {},
    }),
  },
  backButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    borderWidth: 1,
    borderColor: "#fff",
  },

  card: {
    flex: 1,
    // alignItems: "center",
    // alignContent: "center",
    // justifyContent: "center",
    marginTop: 15,
    paddingBottom: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",

    flexDirection: "column",

    ...Platform.select({
      web: {},
      default: {
        height: vh(85),
      },
    }),
  },
});
