import React, { useContext, useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import Toast from 'react-native-toast-message';
import api from '../../services/api';
import DropDownPicker from 'react-native-dropdown-picker';
import SelectList from 'react-native-dropdown-select-list';
import { Colors, canGoToNextPage, canGoToPreviousPage } from '../../shared/utils';

import { styles } from './styles';
import { Icon, Loading } from '../../shared/components';
import UserContext from '../../contexts/User/UserContext';
import { ROW_WIDTH, TABLE_HEAD } from './utils';

type Props = {
  text: string;
}

const TableEstoque = ({ text = '' }: Props) => {
  const { user } = useContext(UserContext);

  const [tableTemp, setTableTemp] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [allBulls, setAllBulls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCodTou, setSelectedCodTou] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [totalPages, setTotalPages] = React.useState(1);
  const [dropDownItems, setDropDownItems] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [flag, setFlag] = React.useState(false);
  const [selected, setSelected] = React.useState('');
  const [touDropDown, setTouDropDown] = React.useState([
    {
      key: 'BN-153431 - 7179903000120',
      value: 'ANAMÉLIA FIV M237 - BN - RGD:PS 153.431 SP 002787 - 1 000206',
    },
  ]);
  const [qtdEntradas, setQtdEntradas] = React.useState(0);
  const [qtdSaidas, setQtdSaidas] = React.useState(0);
  const [qtdDosesMovimentadas, setQtdDosesMovimentadas] = React.useState(0);
  const [flagTemEstoque, setFlagTemEstoque] = React.useState(false);

  useEffect(() => {
    async function getDropDownData() {
      setLoading(true);

      try {
        const response = await api.post('/getAllBullNames', {
          cnpjCpf: user.doc,
          profile: user.profile,
        });

        const dataFinal = response.data;

        dataFinal.sort(function (a: any, b: any) {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });

        if (dataFinal.length === 0) {
          setFlagTemEstoque(false);
          return null;
        }

        function addCnpjToValue(arr: any[]) {
          return arr.map(obj => {
            if (obj.value !== '') {
              const parts = obj.key.split(' - ');
              if (parts.length > 1) {
                const cnpjPart = parts.slice(1).join('-').trim();
                obj.value += ` - DOCUMENTO: ${cnpjPart}`;
              }
              return obj;
            }
          });
        }

        const filteredDataFinal = dataFinal.filter((item: any) => item.value !== '');

        setTouDropDown(addCnpjToValue(filteredDataFinal));

        setFlagTemEstoque(true);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar as opções de seleção',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    getDropDownData();
  }, []);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push({ label: `${i + 1}`, value: `${i + 1}` });
    }
    // @ts-ignore
    setDropDownItems(temp);
  }, [totalPages]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      if (selectedCodTou === 'Fechar') {
        return null;
      }

      try {
        let responseEstoque = await api.post('/vidaTouroEstoques', {
          cnpjCpf: selectedUser,
          codTou: selectedCodTou,
          pagina: currentPage,
        });

        const totalQtdMovEstE =
          responseEstoque?.data?.result[responseEstoque?.data?.result?.length - 1]?.qtdMovEstE;
        const totalQtdMovEstS =
          responseEstoque?.data?.result[responseEstoque?.data?.result?.length - 1]?.qtdMovEstS;
        const totalQtdmov =
          responseEstoque?.data?.result[responseEstoque?.data?.result?.length - 1]?.totalQtdmov;
        const totalPage =
          responseEstoque?.data?.result[responseEstoque?.data?.result?.length - 1]?.nroPaginas

        setQtdEntradas(totalQtdMovEstE);
        setQtdSaidas(totalQtdMovEstS);
        setQtdDosesMovimentadas(totalQtdmov);
        setAllBulls(responseEstoque.data.result);
        setFlag(true);
        setTotalPages(totalPage)
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os dados',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    if (selectedCodTou !== '' && selectedCodTou !== 'Fechar') {
      fetchData();
    } else {
      setSelectedCodTou('');
    }
  }, [selectedCodTou, currentPage]);

  const isABull = (codTou: string) => {
    return !codTou.includes('Saldo final')
  }

  useEffect(() => {
    let getTouro: any[] = [];
    const vidaTouroContratosAdendos: any[] = [];

    if (flag && allBulls[0] != undefined) {
      allBulls.forEach((element: any) => {
        getTouro.push({
          codTou: element?.codTou,
          qtdMovEst: element?.qtdMovEst,
          qtdMovEstS: element?.qtdMovEstS,
          qtdMovEstE: element?.qtdMovEstE,
          desDoc: element?.desDoc, //Contrato: "1",
          nomTou: element?.nomTou, // Nome do Touro
          desDesMov: element?.desDesMov, // Tipo
          apeTou: element?.apeTou, //  Nome de guerra do Touro
          estEntSai: element?.estEntSai, // Estado de entrada/saida
          datMovEst: element?.datMovEst, // Data do movimento
        });
      });

      getTouro.forEach((element: any) => {
        if (isABull(element.apeTou)) {
          let qtd = 0
          if (element.estEntSai === 'S') {
            qtd = element.qtdMovEstS
          } else {
            qtd = element.qtdMovEstE
          }
          vidaTouroContratosAdendos.push([
            element.nomTou, //nome touro
            element.apeTou, // Nome guerra (apelido)
            element.datMovEst, // data do movimento
            qtd, // quantidade do movimento
          ])
        }
      }
      );
    }
    // @ts-ignore
    setTableData(vidaTouroContratosAdendos);
    // @ts-ignore
    setTableTemp(vidaTouroContratosAdendos);
  }, [allBulls, flag]);

  useEffect(() => {
    const filterData = tableTemp.filter(item => {
      // @ts-ignore
      return item[0].toLowerCase().includes(text.toLowerCase());
    });
    setTableData(filterData);
  }, [text]);

  const onPress = (rowData: any) => {
    // @ts-ignore
    setContratoInformation(rowData);
  };

  const element = (data: any) => (
    <TouchableOpacity onPress={() => onPress(data)}>
      <View style={styles.btn}>
        <Text style={styles.btnText}>Visualizar Documento</Text>
      </View>
    </TouchableOpacity>
  );

  const handleGoToFirstPage = () => {
    setCurrentPage(1);
  };

  const handleGoToLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleGoToPreviousPage = () => {
    if (currentPage > 0) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage, 10) - 1);
    }
  };

  const handleGoToNextPage = () => {
    // @ts-ignore
    if (parseInt(currentPage, 10) + 1 <= totalPages) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage, 10) + 1);
    }
  };

  const handleSelectDrowpDown = (value: any) => {
    const user = value.split(' ').pop();
    const codTou = value.split(' ').shift();
    setSelectedUser(user);
    setSelectedCodTou(codTou);
  };

  const handleTotal = () => {
    if (qtdEntradas && qtdSaidas) return qtdEntradas - qtdSaidas
    return 0
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={styles.container}>
        {loading ? <Loading /> : (
          <View style={styles.container}>
            {flagTemEstoque ? (
              // @ts-ignore
              <SelectList
                setSelected={setSelected}
                data={touDropDown}
                placeholder="Selecione um touro"
                onSelect={() => handleSelectDrowpDown(selected)}
                boxStyles={{ width: '100%', height: 50, marginBottom: 10 }}
                inputStyles={{ fontSize: 12, color: '#000', width: 700 }}
                dropdownItemStyles={{
                  fontSize: 12,
                  color: '#000',
                  backgroundColor: '#fff',
                  padding: 10,
                  borderBottomWidth: 1,
                  borderBottomColor: '#ccc',
                }}
                searchPlaceholder="Pesquise um touro"
              />
            ) : (
              <View style={{ marginTop: 20 }}>
                {!loading && (
                  <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Nenhum touro encontrado</Text>
                )}
              </View>
            )}
            {selectedCodTou !== '' ? (
              <View style={{ marginTop: 20 }}>
                <View style={{ display: 'flex', marginBottom: 15 }}>
                  <Text style={styles.textHeaderResumo}>Resumo:</Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.textHeaderTop}>Quantidade de entradas:</Text>
                    <Text>{qtdEntradas}</Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.textHeaderTop}>Quantidade de saídas:</Text>
                    <Text>{qtdSaidas}</Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.textHeaderTop}>Quantidade de movimentos:</Text>

                    <Text>{qtdDosesMovimentadas}</Text>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.textHeaderTop}>Saldo final do estoque:</Text>
                    <Text>{handleTotal()}</Text>
                  </View>
                </View>
                {loading && Platform.OS === 'web' && (
                  <ActivityIndicator size="small" color="#0000ff" />
                )}
                <ScrollView horizontal={true}>
                  <View>
                    {/* @ts-ignore */}
                    <Table
                      borderStyle={{
                        borderWidth: 0,
                        borderColor: '#C1C0B9',
                      }}>
                      <Row
                        data={TABLE_HEAD}
                        widthArr={ROW_WIDTH}
                        style={styles.header}
                        textStyle={styles.textHeaderTable}
                      />
                    </Table>
                    <ScrollView style={styles.dataWrapper}>
                      {/* @ts-ignore */}
                      <Table
                        borderStyle={{
                          borderWidth: 1,
                          borderColor: '#C1C0B9',
                        }}>
                        {tableData.map((rowData: any[], index: number) => (
                          // @ts-ignore
                          <TableWrapper
                            key={index}
                            style={styles.row}
                            textStyle={{ color: Colors.inputWhite }}>
                            <Row
                              key={index}
                              // @ts-ignore
                              data={rowData.map((cellData, cellIndex) => (
                                <Cell
                                  key={cellIndex}
                                  data={cellIndex === 9 ? element(cellData) : cellData}
                                  textStyle={styles.text}
                                />
                              ))}
                              widthArr={ROW_WIDTH}
                              // @ts-ignore
                              style={[styles.row, index % 2 && { backgroundColor: Colors.inputWhite }]}
                              textStyle={styles.text}
                            />
                          </TableWrapper>
                        ))}
                      </Table>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          alignContent: 'center',
                        }}>
                        <Icon
                          name="fast-rewind"
                          disabled={!canGoToPreviousPage(currentPage)}
                          onPress={handleGoToFirstPage}
                        />
                        <Icon
                          name="chevron-left"
                          disabled={!canGoToPreviousPage(currentPage)}
                          onPress={handleGoToPreviousPage}
                        />
                        {/* @ts-ignore */}
                        <Text style={styles.pageNumber}>{`${parseInt(currentPage, 10)
                          }/${totalPages}`}</Text>
                        <Icon
                          name="chevron-right"
                          disabled={!canGoToNextPage(currentPage, totalPages)}
                          onPress={handleGoToNextPage}
                        />
                        <Icon
                          name="fast-forward"
                          disabled={!canGoToNextPage(currentPage, totalPages)}
                          onPress={handleGoToLastPage}
                        />
                        <DropDownPicker
                          items={dropDownItems}
                          open={open}
                          setOpen={setOpen}
                          // @ts-ignore
                          setItems={setDropDownItems}
                          value={currentPage}
                          setValue={setCurrentPage}
                          defaultIndex={1}
                          containerStyle={{ height: 50, width: 90 }}
                          style={{ backgroundColor: '#fff', borderColor: '#fff' }}
                          // @ts-ignore
                          placeholder={currentPage}
                          dropDownDirection="TOP"
                          listMode="SCROLLVIEW"
                          itemStyle={{
                            justifyContent: 'flex-start',
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                            fontSize: 20,
                            color: '#000',
                            backgroundColor: '#fafafa',
                          }}
                          dropDownStyle={{ backgroundColor: '#fafafa' }}
                        />
                      </View>
                    </ScrollView>
                  </View>
                </ScrollView>
              </View>
            ) : null}
          </View>
        )}
      </ScrollView>
    </View>
  );
};
export default TableEstoque;
