import StyleSheet from "react-native-media-query";

export const { ids, styles } = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    justifyContent: "center",
  },
  loginContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F1F1F1",
  },
  smallLogoContainer: {
    marginBottom: 16,
  },
  smallLogo: {
    width: 90,
    height: 60,
  },
  logobackground: {
    padding: 50,
    backgroundColor: "#F1F1F1",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 400,
    paddingTop: 32,
    paddingBottom: 32,
    borderRadius: 16,
    "@media (max-width: 998px)": {
    },
    "@media (max-width: 540px)": {
      height: "90%", //adicionei
      width: "90%", //adicionei
    },
  },
  inputTitleContainer: {
    marginTop: 16,
  },
  input: {
    marginTop: 8,
    marginBottom: 24,
    width: 300,
    maxWidth: "100%",
    padding: 16,
    borderRadius: 8,
    backgroundColor: "#F0F8FF",
    color: "black",
  },
  animationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#2C2967",
    alignSelf: "center",
    fontFamily: "Hind_700Bold",
  },
  titleBemvindo: {
    fontSize: 34,
    color: "#2C2967",
    alignSelf: "center",
    fontFamily: "Hind_400Regular",
    marginBottom: -20,
  },
  titleRealize: {
    fontSize: 18,
    color: "#737375",
    fontFamily: "Hind_700Bold",
    alignSelf: "center",
    padding: 16,
  },

  subTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#000",
  },
  logoContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 998px)": {
      display: "none",
    },
  },
  loginButton: {
    backgroundColor: "#2C2967",
    padding: 20,
    marginTop: 20,
  },
  logo: {
    width: "90%",
    height: 400,
    maxHeight: "90%",
    maxWidth: 400,
    "@media (max-width: 998px)": {
      display: "none",
    },
  },

  register: {
    fontSize: 18,
    color: "#2C2967",
    marginTop: 6,
    marginBottom: 15,
    alignSelf: "center",
    fontFamily: "Hind_300Light",
    textDecorationStyle: "solid",
    textDecorationColor: "#038a96",
    // @ts-ignore
    alignSelf: "center",
  },
  backgroundImage: {
    resizeMode: "cover",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
});
