import api from '../services/api';

type Props = {
  _id: string;
  createdAt: string;
  text: string;
  user: any; // TO DO
  image?: string;
}

export async function SendChatMessage(message: Props): Promise<void> {
  try {

    await api.post("/addmessage", message);

  } catch (e) {
    console.error(e);
  }
}
