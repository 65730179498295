import React from "react";
import { View, Text, TouchableOpacity, FlatList, SafeAreaView } from "react-native";
import { styles } from "./styles";
import Header from "../../components/header";

type Props = {
  navigation: any;
}

const InformacoesAdmin = ({ navigation }: Props) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <Header />

        <View style={styles.textsContainer}>
          <FlatList
            data={[
              { id: 1, key: "Atendimentos", componentName: "ChatAdm" },
              {
                id: 2,
                key: "Administrar usuários",
                componentName: "InformacoesUsuarios",
              },
              {
                id: 3,
                key: "Administrar touros",
                componentName: "InformacoesTouroAdm",
              },
            ]}
            renderItem={({ item }) => (
              <View style={styles.whiteLine}>
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate(item.componentName, {
                      navigation: navigation,
                    })
                  }
                >
                  <Text style={styles.item}>{item.key}</Text>
                </TouchableOpacity>
              </View>
            )}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default InformacoesAdmin;
