import { StyleSheet } from "react-native";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  icon: {
    right: 16,
    alignSelf: "flex-start",
    color: Colors.inputWhite,
  },

  container: {
    display: "flex",
    marginTop: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.blueColor,
    height: 60,
    color: "#000",
    borderRadius: 8,
    paddingRight: 16,
    paddingLeft: 16,
    marginLeft: 15,
    marginRight: 15,
  },
  logo: {
    resizeMode: "contain",
    width: 40,
    height: 40,
    alignSelf: "center",
  },
  text: {
    color: Colors.inputWhite,
    fontFamily: "Poppins_600SemiBold",
    fontSize: 14,
  },
});
