import StyleSheet from "react-native-media-query";
import { Colors } from "../../../shared/utils";

export const { styles } = StyleSheet.create({
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 10,
    borderRadius: 4,
    backgroundColor: "white",
    borderBottomColor: Colors.blueColor,
    shadowColor: Colors.blueColor,
    shadowOffset: { width: 0, height: 8 },
    borderColor: Colors.blueColor,
    borderWidth: 1,
    shadowOpacity: 1,
    shadowRadius: 1,
    marginTop: 6,
    marginBottom: 15,
  },
  listItemNome: {
    fontSize: 14,
    fontFamily: "Poppins_700Bold",
    color: Colors.color1,
  },
  listItemEditar: {
    fontSize: 12,
    fontFamily: "Poppins_700Bold",
    color: Colors.blueColor,
    alignSelf: "flex-end",
  },
  listItemTextRight: {
    fontSize: 12,
    color: Colors.greyColor,
    fontFamily: "Poppins_400Regular",
  },
  listItemTextLeft: {
    fontSize: 12,
    color: Colors.greyColor,
    fontFamily: "Poppins_700Bold",
  },
});
