import { StyleSheet } from "react-native";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  container: {
    height: "90%",
    marginBottom: 20,
  },
  topChatText: {
    flexDirection: "row",
    alignItems: "center",
  },
  textTop: {
    marginRight: 10,
  },
  containermodal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    marginTop: 80,
    width: 600,
    positio: "absolute",
  },
  containerheader: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    marginBottom: 50,
    paddingBottom: 20,
  },
  card: {
    marginTop: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
  },
  textnochat: {
    fontSize: 16,
    fontFamily: "Poppins_400Regular",
    textAlign: "center",
    marginTop: 10,
  },
  containernochat: {
    height: "90%",
    marginBottom: 20,
  },
});
