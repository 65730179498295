import React, { useContext, useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  TextInput,
} from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import { styles } from './styles';
import api from '../../services/api';
import { Colors, canGoToNextPage, canGoToPreviousPage } from '../../shared/utils';
import DropDownPicker from 'react-native-dropdown-picker';
import { Icon, Loading } from '../../shared/components';
import UserContext from '../../contexts/User/UserContext';
import Toast from 'react-native-toast-message';
var base64js = require('base64-js');

type Props = {
  dateInicial: any;
  dateFinal: any;
}

const TableRoyalties = ({ dateInicial, dateFinal }: Props) => {
  const tableHead = [
    'Proprietário',
    'Competência',
    'Recebimentos abertos',
    'Recebimento de vendas',
    'Faturados',
    'Posição do estoque',
  ];

  const [tableTemp, setTableTemp] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [allBulls, setAllBulls] = useState<any[]>([]);
  const { user } = useContext(UserContext);

  //parte do pagination
  const [tableDataToShow, setTableDataToShow] = useState<any[]>([]);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [finalIndex, setFinalIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [temRoyaltiesFlag, setTemRoyaltiesFlag] = useState<boolean>(true);
  const [userBusca, setUserBusca] = useState<string>('');
  const width = Dimensions.get('window');

  let widthArr: number[] = [];
  if (width.width < 768) widthArr = [170, 120, 120, 120, 120];
  else
    widthArr = [
      width.width / 4,
      width.width / 7,
      width.width / 7,
      width.width / 7,
      width.width / 7,
      width.width / 7,
    ];

  useEffect(() => {
    if (tableData.length > 0) {
      setTotalPages(Math.ceil(tableData.length / 10));

      setTableDataToShow(tableData.slice(initialIndex, finalIndex));
    }
  }, [tableData, initialIndex, finalIndex]);

  useEffect(() => {
    let paginaAtual = currentPage;

    let inicial = paginaAtual * 10;
    let final = inicial + 10;

    setInitialIndex(inicial);
    setFinalIndex(final);
  }, [currentPage]);

  useEffect(() => {
    if (tableData.length > 0) {
      let temp = [];
      for (let i = 0; i < totalPages; i++) {
        temp.push({ label: `${i + 1}`, value: `${i}` });
      }
      setDropDownItems(temp);
    }
  }, [totalPages, tableData]);

  useEffect(() => {
    async function fetchDataAdm() {
      let newData2 = '';
      let newData2Final = '';

      let newData = dateInicial.map((date: any) => {
        newData2 = date.split('-').reverse().join('/');
        return newData2;
      });

      const year = parseInt(newData[0]);  // ano
      const month = parseInt(newData[1]);  // mês

      const firstDayNextMonth = new Date(year, month, 1);

      // @ts-ignore
      const lastDayThisMonth = new Date(firstDayNextMonth - 1);

      newData2Final = `${lastDayThisMonth.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

      newData2 = '01' + '/' + newData[1] + '/' + newData[0];

      try {
        const response = await api.post('/vidaTouroRoyalties', {
          cnpjCpf: '',
          profile: "Adm",
          dataIni: newData2,
          dataFim: newData2Final,
        });

        if (
          response.data.result && response.data.result.length >= 1
        ) {
          setAllBulls(response.data.result);
        } else {
          setTemRoyaltiesFlag(false);
        }
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os dados',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    async function fetchData() {
      let newData2 = '';
      let newData2Final = '';

      let newData = dateInicial.map((date: any) => {
        newData2 = date.split('-').reverse().join('/');
        return newData2;
      });

      const year = parseInt(newData[0]);  // ano
      const month = parseInt(newData[1]);  // mês

      const firstDayNextMonth = new Date(year, month, 1);

      // @ts-ignore
      const lastDayThisMonth = new Date(firstDayNextMonth - 1);

      newData2Final = `${lastDayThisMonth.getDate().toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

      newData2 = '01' + '/' + newData[1] + '/' + newData[0];

      try {
        const response = await api.post('/vidaTouroRoyalties', {
          // @ts-ignore
          cnpjCpf: user.doc,
          dataIni: newData2,
          dataFim: newData2Final,
        });

        if (
          response.data.result
        ) {
          if (Array.isArray(response.data.result)) {
            setAllBulls(response.data.result);
          } else {
            setAllBulls([response.data.result]);
          }
        } else {
          setTemRoyaltiesFlag(false);
        }
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os dados',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    // @ts-ignore
    if (user.profile == 'Adm') {
      fetchDataAdm();
    } else {
      fetchData();
    }
  }, [dateInicial, dateFinal]);

  // @ts-ignore
  useEffect(() => {
    const vidaTouroContratosAdendos = [];

    if (allBulls == undefined) {
      return null;
    }

    //transform the data to be showed in the table from yyyy-mm-dd to mm/yyyy
    let newData2 = '';
    //join array into date
    let newData = dateInicial.map((date: any) => {
      newData2 = date.split('-').reverse().join('/');
      return newData2;
    });

    newData2 = newData[1] + '/' + newData[0];

    if (allBulls.length == undefined) {
      // @ts-ignore
      if (allBulls.aprProprietario && allBulls.aprProprietario.length > 0) {
        vidaTouroContratosAdendos.push([
          // @ts-ignore
          allBulls.aprProprietario,
          newData2,
          // @ts-ignore
          allBulls.arqBase64_02,
          // @ts-ignore
          allBulls.arqBase64_01,
          // @ts-ignore
          allBulls.arqBase64_04,
          // @ts-ignore
          allBulls.arqBase64_03,
        ]);
      }
    } else {
      for (const bull of allBulls) {
        if (bull.aprProprietario && bull.aprProprietario.length > 0) {
          vidaTouroContratosAdendos.push([
            bull.aprProprietario,
            newData2,
            bull.arqBase64_02,
            bull.arqBase64_01,
            bull.arqBase64_04,
            bull.arqBase64_03,
          ]);
        }
      }
    }

    setTableData(vidaTouroContratosAdendos);
    setTableTemp(vidaTouroContratosAdendos);
  }, [allBulls]);

  useEffect(() => {
    const filterData = tableData.filter(item => {
      return item[0]?.toLowerCase().includes(userBusca.toLowerCase());
    });
    if (!userBusca || userBusca === "") {
      setTableData(tableTemp)
    } else {
      setTableData(filterData);
    }
  }, [userBusca]);

  const handleGoToFirstPage = () => {
    setCurrentPage(0);
  };

  const handleGoToLastPage = () => {
    // @ts-ignore
    setCurrentPage(parseInt(totalPages - 1));
  };

  const handleGoToPreviousPage = () => {
    if (currentPage >= 1) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage) - 1);
    }
  };

  const handleGoToNextPage = () => {
    // @ts-ignore
    if (parseInt(currentPage, 10) + 1 < totalPages) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage) + 1);
    }
  };

  const onPress = (rowData: any[]) => {
    var binary_string = base64js.toByteArray(rowData);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string[i];
    }
    var blob = new Blob([bytes], { type: 'application/pdf' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'file.pdf';
    // @ts-ignore
    window.open(link, '_blank');
  };

  const element = (data: any) => {
    const handleOnPress = () => {
      if (data && data.length < 40 || data === "" || !data) {
        return Toast.show({
          type: 'info',
          position: 'top',
          text1: 'Aviso',
          text2: 'Arquivo não disponível',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      }
      return onPress(data)
    }

    return (
      <View
        style={{
          padding: 6,
          marginRight: 6,
          marginLeft: 6,
          alignSelf: 'center',
        }}>
        <TouchableOpacity onPress={handleOnPress} disabled={loading}>
          <Text style={styles.btnText}>Visualizar</Text>
        </TouchableOpacity>
      </View>
    )
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }} horizontal={true}>
      {!temRoyaltiesFlag ? (
        <View style={styles.container}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: '#000',
              marginTop: 20,
              marginBottom: 20,
              textAlign: 'center',
            }}>
            Não há royalties para esse período
          </Text>
        </View>
      ) : (
        <View style={{ flex: 1, paddingBottom: 100 }}>
          {loading ? <Loading /> : (
            <View>
              <View style={styles.container}>
                <TextInput
                  style={styles.inputBusca}
                  placeholder="Pesquisar"
                  placeholderTextColor={Colors.lightGreyColor}
                  value={userBusca}
                  onChangeText={text => setUserBusca(text)}
                />
              </View>
              {/* @ts-ignore */}
              <Table>
                <Row
                  data={tableHead}
                  widthArr={widthArr}
                  style={styles.header}
                  textStyle={styles.textHeader}
                />
              </Table>
              <View style={styles.dataWrapper}>
                {/* @ts-ignore */}
                <Table>
                  {tableDataToShow.map((rowData: any[], index: number) => (
                    // @ts-ignore
                    <TableWrapper
                      key={index}
                      style={styles.row}
                      textStyle={{ color: Colors.inputWhite }}>
                      <Row
                        key={index}
                        // @ts-ignore
                        data={rowData.map((cellData: any, cellIndex: number) => (
                          <Cell
                            key={cellIndex}
                            data={cellIndex > 1 ? element(cellData) : cellData}
                            textStyle={styles.text}
                          />
                        ))}
                        widthArr={widthArr}
                        // @ts-ignore
                        style={[styles.row, index % 2 && { backgroundColor: '#F7F6E7' }]}
                        textStyle={styles.text}
                      />

                    </TableWrapper>
                  ))}
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <Icon
                      name="fast-rewind"
                      disabled={!canGoToPreviousPage(currentPage + 1)}
                      onPress={handleGoToFirstPage}
                    />
                    <Icon
                      name="chevron-left"
                      disabled={!canGoToPreviousPage(currentPage + 1)}
                      onPress={handleGoToPreviousPage}
                    />
                    {/* @ts-ignore */}
                    <Text style={styles.pageNumber}>{`${parseInt(currentPage, 10) + 1
                      }/${totalPages}`}</Text>
                    <Icon
                      name="chevron-right"
                      disabled={!canGoToNextPage(currentPage + 1, totalPages)}
                      onPress={handleGoToNextPage}
                    />
                    <Icon
                      name="fast-forward"
                      disabled={!canGoToNextPage(currentPage + 1, totalPages)}
                      onPress={handleGoToLastPage}
                    />
                    <DropDownPicker
                      items={dropDownItems}
                      open={open}
                      setOpen={setOpen}
                      setItems={setDropDownItems}
                      value={currentPage}
                      setValue={setCurrentPage}
                      defaultIndex={1}
                      containerStyle={{ height: 50, width: 90 }}
                      style={{ backgroundColor: '#fff', borderColor: '#fff' }}
                      // @ts-ignore
                      placeholder={currentPage + 1}
                      dropDownDirection="TOP"
                      listMode="SCROLLVIEW"
                      itemStyle={{
                        justifyContent: 'flex-end',
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        fontSize: 20,
                        color: '#000',
                        backgroundColor: '#fafafa',
                      }}
                      dropDownStyle={{ backgroundColor: '#fafafa' }}
                    />
                  </View>
                </Table>
              </View>
            </View>
          )}

        </View>
      )}
    </ScrollView>
  );
};

export default TableRoyalties;
