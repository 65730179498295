import "react-native-gesture-handler";
import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";

import Toast from "react-native-toast-message";

import BullInformationContext from "./src/services/bullInformationContext";
import EstoqueInformationContext from "./src/services/estoqueInformationContext";
import ContratoInformationContext from "./src/services/contratoInformationContext";

import {
  useFonts,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_700Bold,
  Poppins_300Light,
  Poppins_600SemiBold,
} from "@expo-google-fonts/poppins";
import {
  useFonts as useFontsHind,
  Hind_300Light,
  Hind_400Regular,
  Hind_500Medium,
  Hind_600SemiBold,
  Hind_700Bold,
} from "@expo-google-fonts/hind";
import { UserProvider } from "./src/contexts/User/UserContext";
import DrawerNavigator from "./src/navigators/DrawerNavigator";

const App = () => {
  const [bullInformation, setBullInformation] = useState({});
  const [estoqueInformation, setEstoqueInformation] = useState({});
  const [contratoInformation, setContratoInformation] = useState({});

  const [fontsLoaded] = useFonts({
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_700Bold,
    Poppins_300Light,
    Poppins_600SemiBold,
  });

  const [fontsLoadedhind] = useFontsHind({
    Hind_300Light,
    Hind_400Regular,
    Hind_500Medium,
    Hind_600SemiBold,
    Hind_700Bold,
  });

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  if (!fontsLoaded) {
    return null;
  }
  if (!fontsLoadedhind) {
    return null;
  }

  return (
    <UserProvider>
      <BullInformationContext.Provider
        value={[bullInformation, setBullInformation]}
      >
        <EstoqueInformationContext.Provider
          value={[estoqueInformation, setEstoqueInformation]}
        >
          <ContratoInformationContext.Provider
            value={[contratoInformation, setContratoInformation]}
          >
            <NavigationContainer>
              <DrawerNavigator />
              <Toast />
            </NavigationContainer>
          </ContratoInformationContext.Provider>
        </EstoqueInformationContext.Provider>
      </BullInformationContext.Provider>
    </UserProvider>
  );
};

export default App;
