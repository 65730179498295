import React from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import StyleSheet from 'react-native-media-query';

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: 24,
    textAlign: 'center'
  },
  spacer: {
    marginTop: 18
  }
});


const Loading = () => {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color="#0000ff" />
      <View style={styles.spacer} />
      <Text style={styles.text}>Aguarde enquanto</Text>
      <Text style={styles.text}>carregamos as informações</Text>
    </View>
  );
};

export default Loading;
