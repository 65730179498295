import { StyleSheet } from "react-native";
import { Platform } from "react-native";
// @ts-ignore
import { vh, vw } from "react-native-expo-viewport-units";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 540px)": {
      width: 300,
    },
    flex: 1,
  },
  card: {
    width: 250,
    height: 200,
    maxHeight: 200,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 8,
    ...Platform.select({
      web: {
        marginTop: 30,

      },
      default: {
        display: 'flex',
        marginTop: 30,
        width: 160,
        height: 90,
      },
    }),
  },
  picDate: {
    fontSize: 18,
    // fontWeight: "bold",
    marginTop: -20,
  },

  text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
    marginTop: 8,
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignSelf: "flex-start",
    flex: 1,
  },
  backButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    borderWidth: 1,
    borderColor: "#fff",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: vw(80),
    margin: 8,
    ...Platform.select({
      web: {
        marginTop: 20,
        "@media (max-width: 540px)": {
          width: 300,
        },
        flex: 1,
      },
      default: {
      },
    }),
  },
  secondContainer: {
    flexWrap: "wrap",
    height: vh(70),
    ...Platform.select({
      web: {
        marginTop: 20,
        display: "flex",
        flexDirection: "row",
        "@media (max-width: 540px)": {
          width: 300,
        },
        flex: 1,
      },
      default: {
      },
    }),
  },

  carouselContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 100,
  },

  carouselIconContainer: {
    ...Platform.select({
      web: {
        alignSelf: "flex-end",
        marginRight: 25,
        marginBottom: 20,
        marginTop: -20,
      },
      default: {
        alignSelf: "flex-end",
        marginRight: 25,
        marginBottom: 20,
        marginTop: 50,
      },
    }),
  },
  textTile: {
    fontFamily: "Hind_600SemiBold",
    fontSize: 18,
    color: Colors.greyColor
  },
  textSubTile: {
    marginLeft: 4,
    fontFamily: "Hind_400Regular",
    fontSize: 16,
    color: Colors.greyColor,
    alignSelf: "center"
  },


  gridView: {
    flex: 1,
  },
  itemContainer: {
    justifyContent: 'flex-end',
    borderRadius: 5,
    padding: 10,
    height: 150,
    margin: 8,
  },
  itemName: {
    fontSize: 16,
    color: '#fff',
    fontWeight: '600',
    margin: 8,
  },
  itemCode: {
    fontWeight: '600',
    fontSize: 12,
    color: '#fff',
  },
});
