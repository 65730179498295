import React from "react";
import { TouchableOpacity } from "react-native";
import { styles } from "./styles";
import { Text } from "react-native";

type Props = {
  children: any;
  onPress: () => void;
}

const ButtonClean = ({ children, onPress }: Props) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.content}>{children}</Text>
    </TouchableOpacity>
  );
};

//styles

export default ButtonClean;
