import React from 'react';
import { Modal, View, ActivityIndicator } from 'react-native';
import { styles } from './styles';

type Props = {
  loading: boolean
}

const LoadingModal = ({ loading }: Props) => {
  return (
    <Modal visible={loading} transparent>
      <View style={styles.modal}>
        <ActivityIndicator size="large" color="blue" />
      </View>
    </Modal>
  );
};

export default LoadingModal;
