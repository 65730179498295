import React, { useContext, useState } from 'react';
import { View, Text, TextInput } from 'react-native';

import { styles } from './styles';
import FotosTouro from '../../components/fotosDoTouro';
import Header from '../../components/headerscreens';
import BullsList from '../../components/BullsList';
import BullInformationContext from '../../services/bullInformationContext';

type Props = {
  navigation: any;
}

const InformacoesTouro = ({ navigation }: Props) => {
  const [bullInformation, setBullInformation] = useContext(BullInformationContext);
  // @ts-ignore
  const { codTou, nomTou, dtNasc } = bullInformation;

  const [temTouro, setTemTouro] = useState(true);
  const handleChangeTemTouro = (value: boolean) => {
    setTemTouro(value);
  };

  const handleSelectTouro = (selected: any) => {
    // @ts-ignore
    setBullInformation(selected);
  };

  return (
    <View style={styles.container}>
      <Header title={'Fotos do Touro'} flagLocalExato={true} />

      <View
        style={[
          styles.card,
          {
            borderRadius: 8,
            marginLeft: 15,
            marginRight: 15,
            padding: 10,
            backgroundColor: '#fff',
          },
        ]}>
        <View style={styles.contentContainer}>
          {/* @ts-ignore */}
          {bullInformation.codTou ? (
            <FotosTouro codTou={codTou} nomTou={nomTou} navigation={navigation} />
          ) : temTouro ? (
            <>
              <View style={styles.textsContainer}>
                <BullsList handleChangeTemTouro={handleChangeTemTouro} handleSelectTouro={handleSelectTouro} />
              </View>
            </>
          ) : (
            <View style={styles.textsContainer}>
              <Text style={styles.title}>Você não possui touros cadastrados</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default InformacoesTouro;
