import StyleSheet from "react-native-media-query";
// @ts-ignore
import { vw } from "react-native-expo-viewport-units";
import { Platform } from "react-native";
import { Colors } from "../../shared/utils";

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    paddingBottom: 20,
  },
  contentContainer: {
    width: vw(80),
    display: "flex",
    flexDirection: "column",
  },
  textsContainer: {
    width: "100%",
  },
  content: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: Colors.color1,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  mainText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.color1,
    marginTop: 16,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  backButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    borderWidth: 1,
    borderColor: "#fff",
    marginTop: 20,
  },
  tableContainer: {
    marginTop: 32,
  },
  safeContainer: {

  },

  card: {
    marginTop: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
    flex: 1,
  },
  containerheader: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    paddingBottom: 150,
  },
});
