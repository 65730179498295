import React from 'react';

import Icon from "react-native-vector-icons/Feather";

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Homepage from '../pages/homepage';

const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: { display: "none" },
      }}
    >
      <Tab.Screen
        name="TabScreen"
        component={Homepage}
        options={{
          tabBarActiveBackgroundColor: "#3C2C3E",
          tabBarLabel: "Remover ",
          tabBarActiveTintColor: "white",
          tabBarIcon: ({ color, size }) => (
            <Icon name="home" color={color} size={size} />
          ),
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
};

export default TabNavigator;