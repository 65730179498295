import React, {
  useState,
  useLayoutEffect,
  useContext,
} from "react";
import {
  TouchableOpacity,
  Text,
  View,
  ActivityIndicator,
  ScrollView,
} from "react-native";

import { Avatar } from "react-native-elements";
import {
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { database } from "../../utils/firebaseconfig";
import CustomListItem from "../../components/customListItem";

import Toast from "react-native-toast-message";

import api from "../../services/api";
import { styles } from "./styles";
import Header from "../../components/headerscreens";
import { Card } from "react-native-elements";
import { Colors } from "../../shared/utils";
import UserContext from "../../contexts/User/UserContext";

type Props = {
  route: any;
  navigation: any;
  params: any;
}

export default function ChatAdm({ route, navigation, params }: Props) {
  const { user } = useContext(UserContext);

  const [userData, setUserData] = useState<any[]>([]);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);
  const [loadingFlag, setLoadingFlag] = useState<boolean>(true);

  //Constantes para determinar os status do antedimento
  const aguardando = "aguardando";
  const emAtendimento = "em atendimento";
  const offline = "offline";

  useLayoutEffect(() => {
    // @ts-ignore
    let intName = user.name;
    intName = intName.substr(0, 1);

    navigation.setOptions({
      title: "",

      headerTitleStyle: { color: "black" },
      headerTintColor: "black",

      headerRight: () => (
        <View style={styles.topChatText}>
          {notificationVisible && (
            <Avatar
              rounded
              size={64}
              source={require("../../assets/notificationIcon.png")}
            />
          )}
          {/* @ts-ignore */}
          <Text style={styles.textTop}>{`${user.name}/Admin`}</Text>
          <View style={{ marginRight: 40 }}>
            <TouchableOpacity>
              <Avatar
                rounded
                title={intName}
                size="medium"
                activeOpacity={0.7}
                containerStyle={{
                  backgroundColor: "gray",
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      ),
    });
  }, [navigation, notificationVisible]);

  const getStatus = async () => {
    try {
      const statusAguardando = await api.get("/getstatustrue");

      setNotificationVisible(statusAguardando.data.status.length > 0);

      const groupbyListChat: any[] = [];

      const listUserChat = await api.post("/getallstatususer", {
        toTairana: user.isTairana
      });

      const users_ids: any[] = [];
      const user_atendimento: any[] = [];
      listUserChat.data.resp.forEach((element: any) => {
        user_atendimento.push({
          userid: element.userid,
          status: element.status,
        });
        users_ids.push(element.userid);
      });
      if (users_ids && users_ids.length === 0) {
        return setUserData([])
      }

      const users = await api.post("/user_id", {
        id: users_ids,
      });

      users.data.users.forEach((element: any) => {
        const found = user_atendimento.find(
          (item) => item.userid === element.id
        );

        groupbyListChat.push({
          name: element.name,
          email: element.email,
          status: found.status,
        });
      });

      groupbyListChat.length > 0
        ? setUserData(groupbyListChat)
        : setUserData([]);

    } catch (e) {
      console.log(e);
    } finally {
      setLoadingFlag(false)
    }
  };

  useLayoutEffect(() => {
    async function fetchData() {
      try {
        const auth = getAuth();

        auth.onAuthStateChanged((userr) => {
          if (userr) {
            const collectionRef = collection(database, "statusatendimento");

            const q = query(collectionRef);

            getStatus();

            // onSnapshot(q, (querySnapshot) => {
            //   querySnapshot.docs.map((doc) => {
            //   });
            // });
          } else {
            // @ts-ignore
            signInWithEmailAndPassword(auth, user.email, user.password).then(
              () => {
                const collectionRef = collection(database, "statusatendimento");

                const q = query(collectionRef);

                getStatus();

                // const unsubscribe = onSnapshot(q, (querySnapshot) => {
                //   querySnapshot.docs.map((doc) => {
                //   });
                // });
              }
            );
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  const enterChat = (id: string, email: string, status: string) => {
    let notUpdatedStatus = true;

    if (status === aguardando) {
      async function fetchData() {
        try {
          const userData = await api.post("/user", {
            email,
          });

          const response = await api.post("/setstatus", {
            id: userData.data.resp[0].id,
            status: emAtendimento,
            toTairana: user.isTairana
          });
          notUpdatedStatus = response.data.error;

          if (notUpdatedStatus === false) {
            navigation.navigate("Chat", {
              // params: {
              id: id,
              // @ts-ignore
              email: user.email,
              userreceive: email,
              userreceivename: userData.data.resp[1].name,
              userreceiveId: userData.data.resp[0].id,
              // @ts-ignore
              profile: user.profile,
              // @ts-ignore
              name: user.name,
              status: aguardando,
              // },

              navigation: navigation,
            });
          } else {
            console.log("Erro update status");
          }
        } catch (e) {
          console.log(e);
        }
      }
      fetchData();
    } else if (status === emAtendimento) {
      Toast.show({
        type: "error",
        position: "top",
        text1: "Atendimento em andamento",
        autoHide: true,
        visibilityTime: 3000,
      });
    } else if (status === offline) {
      navigation.navigate("Chat", {
        id: id,
        // @ts-ignore
        email: user.email,
        userreceive: email,
        // @ts-ignore
        profile: user.profile,
        // @ts-ignore
        name: user.name,
        status: offline,

        navigation: navigation,
      });
    }
  };
  const getColorStatus = (status: string) => {
    if (status === aguardando) {
      return Colors.blueColor;
    } else if (status === emAtendimento) {
      return null;
    } else {
      return null;
    }
  };
  return (
    <View style={styles.containerheader}>
      <Header title={"Chat e Comunicação"} />
      {/* @ts-ignore */}
      <Card containerStyle={styles.card}>
        {
          loadingFlag ? (
            <View style={styles.container}>
              <ActivityIndicator size="large" color={Colors.blueColor} />
            </View>
          ) : (
            null
          )
        }
        {userData.length <= 0 && !loadingFlag ? (
          <>
            <View style={styles.container}>
              <Text style={styles.textnochat}>
                Nenhuma conversa até o momento
              </Text>
            </View>
          </>
        ) : (
          <>
            <ScrollView style={styles.container}>
              {userData.map((item) => (
                <CustomListItem
                  key={item.email}
                  id={item.email}
                  chatName={item.name}
                  chatEmail={item.email}
                  enterChat={enterChat}
                  aColor={getColorStatus(item.status)}
                  status={item.status}
                />
              ))}
            </ScrollView>
          </>
        )}
      </Card>
    </View>
  );
}
