import StyleSheet from "react-native-media-query";
import { Colors } from "../../shared/utils";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  container: {
    paddingBottom: 20,
    flexDirection: "column",
  },
  head: {
    backgroundColor: "#2b2f33",
    color: "#fff",
    width: "100%",
  },
  header: { height: 50, backgroundColor: "#537791" },
  text: { textAlign: "center", fontWeight: "100" },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: "#E7E6E1" },
  textIdRow: {
    margin: 12,
    fontWeight: "bold",
  },
  inputBusca: {
    width: "100%",
    // height: vh(5),
    borderRadius: 5,
    borderColor: "#707070",
    padding: 10,
    borderWidth: 1,
    alignSelf: "center",
    fontFamily: "Hind_400Regular",
    backgroundColor: Colors.inputWhite,
    marginBottom: 20,
    ...Platform.select({
      web: {
        "@media (minWidth: 768px)": {
          width: "50%",
        },
      },
      default: {},
    }),
  },
  textHeader: {
    color: "#fff",
    margin: 12,
    fontWeight: "bold",
  },
  btn: {
    maxWidth: "100%",
    paddingVertical: 4,
    paddingHorizontal: 12,
    backgroundColor: "#d73c4d",
    borderRadius: 4,
    "@media (max-width: 1240px)": {
      paddingHorizontal: 0,
      maxWidth: "80%",
    },
  },
  btnText: {
    textAlign: "center",
    color: "#fff",
  },
});
