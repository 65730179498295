import React from 'react';

import Icon from "react-native-vector-icons/Feather";

import { createStackNavigator } from '@react-navigation/stack';
import { TouchableOpacity } from 'react-native';
import Chat from '../pages/chat';
import InformacoesContrato from '../pages/informacoesContrato';
import InformacoesEstoque from '../pages/informacoesEstoque';
import { createDrawerNavigator } from '@react-navigation/drawer';
import LoginStackNavigator from './LoginStackNavigator';
import TabNavigator from './TabNavigator';
import GeneralInformationStackNavigator from './GeneralInformationStackNavigator';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const MainStackNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Homepage">
      <Stack.Screen
        name="Home"
        component={TabNavigator}
        options={{
          headerShown: false,
          drawerLockMode: "locked-open",
          title: "",
          // @ts-ignore
          headerLeft: false,
          statusBarColor: "blue",
          headerRight: () => (
            <TouchableOpacity>
              <Icon name="menu" size={30} color="white" />
            </TouchableOpacity>
          ),
        }}
      />
      <Stack.Screen
        name="Chat"
        component={Chat}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="InformacoesContrato"
        component={InformacoesContrato}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesEstoque"
        component={InformacoesEstoque}
        options={{
          headerShown: false,
        }}
      />
      <Drawer.Screen
        name="Informações gerais"
        component={GeneralInformationStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Login"
        component={LoginStackNavigator}
        options={{
          headerShown: false,
          // @ts-ignore
          swipeEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default MainStackNavigator;