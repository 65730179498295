import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '#212a57',
    height: 44,
    border: 0,
    color: '#312e38',
    marginTop: 16,
    borderRadius: 10,
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  disabledContainer: {
    backgroundColor: 'lightgrey',
    height: 44,
    border: 0,
    color: '#312e38',
    marginTop: 16,
    borderRadius: 10,
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  content: {
    fontFamily: 'Hind_700Bold',
    fontSize: 14,
    color: 'white',
  },
});
