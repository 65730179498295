import React from 'react';
import { View, SafeAreaView } from 'react-native';

import { styles } from './styles';
import Header from '../../components/headerscreens';
import TableContratos from '../../components/tableContratos';

const InformacoesContrato = () => {

  return (
    <View style={styles.containerheader}>
      <Header title={'Contratos'} />
      <SafeAreaView style={styles.safeContainer}>
        <View style={styles.card}>
          <View style={{ flex: 1 }}>
            <TableContratos />
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
};

export default InformacoesContrato;
