import React, { useState, useEffect } from 'react';
import { View, Image, Text, TouchableOpacity, ScrollView, Platform, ActivityIndicator } from 'react-native';
import { styles } from './styles';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { getApps, initializeApp } from "firebase/app";

// @ts-ignore
import Grid from 'react-native-grid-component';
import api from '../../services/api';

type Props = {
	codTou: string;
	nomTou: string;
	navigation: any;
}

const FotosTouro = ({ codTou, nomTou, navigation }: Props) => {
	const [picArray, setPicArray] = useState<any[]>([]);
	const [flag, setFlag] = useState<boolean>(false);
	const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
	const [flagTemTouro, setFlagTemTouro] = useState<boolean>(true);

	const firebaseConfig = {
		apiKey: "AIzaSyAVTPyLZoZ1yZC65eRHh21JocH4QP1hZEw",
		authDomain: "hml-vida-do-touro.firebaseapp.com",
		databaseURL: "https://hml-vida-do-touro.firebaseio.com",
		storageBucket: "hml-vida-do-touro.appspot.com/fotosDoTouro",
		messagingSenderId: "174290674130",
		APP_ID: "1:174290674130:web:147fa12c3d506f9f94680b",
	};

	// Editing this file with fast refresh will reinitialize the app on every refresh, let's not do that
	if (!getApps().length) {
		initializeApp(firebaseConfig);
	}

	useEffect(() => {
		//get all the pictures from firebase
		const getImages = async () => {
			setLoadingFlag(true);
			const storage = getStorage();
			const pathReference = ref(storage, `/fotosDoTouro/${codTou}/`);
			listAll(pathReference)
				.then(async (res) => {
					if (res.items.length < 1) {
						setFlagTemTouro(false);
					}
					let arrayDasFotos = [];
					const items = res.items;
					for (const item of items) {
						let url = await getDownloadURL(item)
						let id = item.name
						const date = await api.post(`/getPicData`, {
							codTou: codTou,
							picId: id
						})
						if (date.data.error) {
							setLoadingFlag(false)
							return setFlagTemTouro(false)
						}
						let dateArray = date.data.dataFoto.split('-')
						let dateFormated = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0]
						if (dateFormated == 'undefined/undefined/') {
							dateFormated = 'Data não informada';
						}
						arrayDasFotos.push({
							link: url, picDate: dateFormated, id: id
						})
					}
					setPicArray(arrayDasFotos);

					setLoadingFlag(false);

				}).catch((error) => {
					console.log(error)
				});

		}
		if (!flag) {
			getImages();
		}
		setFlag(true);
	}, [picArray])

	const _renderItem = (data: any, i: number) => {
		return (
			<TouchableOpacity
				key={i}
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				onPress={() => navigation.navigate("PicCarousel", {
					picArray: picArray,
					index: i,
				})}>
				<Image
					style={styles.card}
					source={{ uri: data.link }}
				/>
				<Text style={
					{
						backgroundColor: 'rgba(0,0,0,0.5)',
						color: 'black',
						padding: 5,
						fontSize: 10,
						marginBottom: 10,
						textAlign: 'center',
						width: '80%',
						fontWeight: 'bold'
					}
				}>
					{data.picDate}
				</Text>
			</TouchableOpacity >
		)
	};

	const normalRender = () => {
		return (
			<ScrollView>
				<View style={styles.container}>
					<View style={styles.firstContainer}>
						<View style={styles.textContainer}>
							<Text style={styles.textTile}>
								{nomTou}
							</Text>
							<View style={{ flexDirection: 'row' }}>
								<Text style={styles.textTile}>
									Código:
								</Text>
								<Text style={styles.textSubTile}>
									{codTou}
								</Text>
							</View>
						</View>
					</View>
					{loadingFlag ? <ActivityIndicator size="small" color="#0000ff" /> : null}

					{picArray.length > 0 ? (
						<View >
							{Platform.OS == 'web' ? (
								<View style={styles.secondContainer}>
									{picArray.map((pic, index) => {
										return (
											<TouchableOpacity
												key={pic.id}
												style={styles.card}
												onPress={() => navigation.navigate("PicCarousel", {
													picArray: picArray,
													index,
													returnPage: "user"
												})}>
												<Image
													style={{
														width: 200,
														height: 150,
														borderRadius: 10,
														resizeMode: 'contain',
														marginBottom: 20
													}}
													source={{ uri: pic.link }}
												/>
												<Text style={styles.picDate}>
													{pic.picDate}
												</Text>
											</TouchableOpacity>
										)
									})}
								</View>
							) : (
								<Grid
									style={styles.gridView}
									data={picArray}
									renderItem={_renderItem}
									numColumns={2}
									keyExtractor={(item: any, index: number) => index.toString()}
								/>
							)}
						</View>
					) : (
						<View>
							{flagTemTouro ? (null) : <Text style={{
								fontSize: 20,
								fontWeight: 'bold',
								color: 'black',
								textAlign: 'center'
							}}>Esse touro ainda não possui fotos cadastradas</Text>}
						</View>
					)}
				</View>
			</ScrollView>
		)
	}
	return normalRender();
}

export default FotosTouro;