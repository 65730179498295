import React, {
  useState,
  useLayoutEffect,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { TouchableOpacity, View, Modal } from "react-native";
import { GiftedChat, Bubble, Send } from "react-native-gifted-chat";
import {
  collection,
  orderBy,
  query,
  onSnapshot,
} from "firebase/firestore";
import FontAwesome from 'react-native-vector-icons/FontAwesome'

import { SendChatMessage } from '../../helpers/sendChatMessage'
import { database } from "../../utils/firebaseconfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Toast from "react-native-toast-message";
import {
  Button,
  Paragraph,
  Dialog,
  Portal,
  Provider,
} from "react-native-paper";
import * as DocumentPicker from 'expo-document-picker'
import api from "../../services/api";
import messageIdGenerator from "../../utils/messageIdGenerator";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import Entypo from "react-native-vector-icons/Entypo";
import Header from "../../components/headerscreens";
import { Colors } from "../../shared/utils";
import { ActionChatModal } from '../../components/actionChatModal'
import { uploadChatFiles } from '../../helpers/uploadFiles'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import UserContext from "../../contexts/User/UserContext";

type Props = {
  route: any;
}

const Chat = ({ route }: Props) => {
  const { user } = useContext(UserContext);

  const [messages, setMessages] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const [visibleModal, setVisibleModal] = useState<boolean>(false)

  const navigate = useNavigation();

  const endChat = () => {
    setVisible(false);
    // @ts-ignore
    setBeginAndEndChat(`O usuário ${user.name} finalizou o atendimento` + "\n");

    let messageEmail = "Histórico de conversas:";
    for (let i = 0; i < messages.length; i++) {
      messageEmail =
        messageEmail +
        "\n" +
        "\n" +
        `${moment(messages[i].createdAt).format("DD/MM/YYYY HH:mm")} - ${messages[i].user._id
        }`;
      messageEmail = messageEmail + "\n" + messages[i].text;
    }
    async function fetchData() {
      try {
        // const setstatus = await api.post("/setstatus", {
        //   id: route.params.userreceiveId,
        //   status: "offline",
        // });
        // let date = moment(new Date()).format("DD/MM/YYYY");
        // const response = await api.post("/sendemail", {
        //   to: "andersonguitaraaman@gmail.com", //TODO: trocar para o email do usuário para route.params.userreceive,
        //   prefixedSubject: "",
        //   subject: `Atendimento - ${date} - ${user.name}`,
        //   text: messageEmail,
        // });
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();

    navigate.goBack();
  };
  const hideDialog = () => setVisible(false);

  const setBeginAndEndChat = (texttoSend: string) => {
    const newId = messageIdGenerator();
    async function fetchData() {
      try {
        const response = await api.post("/addmessage", {
          _id: `${newId}${new Date()}`,
          createdAt: new Date(),
          text: texttoSend,
          user: {
            _id: "Adm",
            // @ts-ignore
            emailAdm: user.email,
            userreceive: route.params.userreceive,
          },
          image: "",
        });
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  };

  const setEndChat = (texttoSend: string) => {
    const newId = messageIdGenerator();
    async function fetchData() {
      try {
        const response = await api.post("/addmessage", {
          _id: `${newId}${new Date()}`,
          createdAt: new Date(),
          text: texttoSend,
          user: {
            _id: "Adm",
            // @ts-ignore
            emailAdm: user.email,
            userreceive: route.params.userreceive,
          },
          image: "",
        });
        const setstatus = await api.post("/setstatus", {
          id: route.params.userreceiveId,
          status: "offline",
        });
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  };

  useLayoutEffect(() => {
    const getMessage = (m: any) => {
      const dbMessage = [];

      for (let i in m) {
        if (m.length > 0) {
          if (
            m[i].user.userreceive === route.params.userreceive ||
            m[i].user._id === route.params.userreceive
          ) {
            dbMessage.push(m[i]);
          }
        }
      }
      setMessages(dbMessage);
    };
    let unsubscribe
    const auth = getAuth();

    auth.onAuthStateChanged((userr) => {
      if (userr) {
        const collectionRef = collection(database, "chats");
        const q = query(collectionRef, orderBy("createdAt", "desc"));
        unsubscribe = onSnapshot(q, (querySnapshot) => {
          getMessage(
            querySnapshot.docs.map((doc) => ({
              _id: doc.data()._id,
              createdAt: doc.data().createdAt,
              text: doc.data().text,
              user: doc.data().user,
              image: doc.data().image,
            }))
          );
        });
      } else {
        // @ts-ignore
        signInWithEmailAndPassword(auth, user.email, user.password).then(
          () => {
            const collectionRef = collection(database, "chats");

            const q = query(collectionRef, orderBy("createdAt", "desc"));
            unsubscribe = onSnapshot(q, (querySnapshot) => {
              getMessage(
                querySnapshot.docs.map((doc) => ({
                  _id: doc.data()._id,
                  createdAt: doc.data().createdAt,
                  text: doc.data().text,
                  user: doc.data().user,
                  image: doc.data().image,
                }))
              );
            });
          }
        );
      }
    });

    return unsubscribe;
  }, []);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const getUserId = async () => {
      const response = await api.post('/getUserId', {
        email: route.params.userreceive,
      })
      setUserId(response.data.userId)
    }
    getUserId()
  }, [])

  const pickChatFiles = async () => {
    try {
      const uuid = messageIdGenerator()

      const sendDocumentMessage = async (messageText: string, previewDocument: any) => {
        const _id = `${uuid}${new Date()}`
        const text = fileName
        const createdAt = new Date()
        const user = {
          _id: route.params.userreceive,
          userreceive: 'Adm',
        }
        const image = previewDocument

        if (image === undefined) return

        setMessages((previousMessages) => GiftedChat.append(previousMessages, {
          // @ts-ignore
          _id: _id,
          text: text,
          createdAt: createdAt,
          user: user,
          image: image,
        }))

        // @ts-ignore
        await SendChatMessage(_id, createdAt, text, user, image)
      }

      const result = await DocumentPicker.getDocumentAsync({})

      // @ts-ignore
      if (result.mimeType !== 'application/pdf' && result.mimeType !== 'image/jpeg') {
        Toast.show({
          type: 'error',
          text1: 'Formato de arquivo inválido',
          text2: 'Por favor envie somente arquivos .pdf, .jpeg, .jpg.',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        })
        return
      }

      // @ts-ignore
      if (result.size > 5242880) {
        Toast.show({
          type: 'error',
          text1: 'Arquivo muito grande',
          text2: 'Por favor não envie arquivos maiores que 5MB.',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        })
        return
      }

      // @ts-ignore
      const fileName = result.name
      // @ts-ignore
      const fileSize = result.size
      // @ts-ignore
      const fileType = result.mimeType
      // @ts-ignore
      const fileUri = result.uri

      const maxFileSize = 5242880

      if (fileSize > maxFileSize) {
        const byteConstant = 1024
        const decimalsConstant = 2
        const decimals = decimalsConstant < 0 ? 0 : decimalsConstant
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(fileSize) / Math.log(byteConstant))
        const fileSizeConverted = parseFloat((fileSize / Math.pow(byteConstant, i)).toFixed(decimals)) + sizes[i]

        const text = `Por favor não envie arquivos maiores que 5Mb. Tamanho do arquivo atual: ${fileSizeConverted}`
        // @ts-ignore
        return sendDocumentMessage(text)
      }

      const fileTypes = {
        pdf: 'application/pdf',
        jpeg: 'image/jpeg',
      }

      if (fileType !== fileTypes.pdf && fileType !== fileTypes.jpeg) {
        const text = 'Formato de arquivo inválido, por favor envie somente arquivos .pdf, .jpeg, .jpg.'
        // @ts-ignore
        return sendDocumentMessage(text)
      }

      await uploadChatFiles(userId, fileUri, fileName)

      const storage = await getStorage()
      const storageRef = await getDownloadURL(ref(storage, `documentsChat/${userId}/${fileName}`))

      await sendDocumentMessage(fileName, storageRef)
    } catch (error) {
      console.error(error)
    }
  }

  const onSend = useCallback(async (messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );

    let { user, image } = messages[0]

    // @ts-ignore
    messages[0].image = typeof image == 'undefined' ? '' : image

    await SendChatMessage(messages[0])
  }, []);

  const action = () => {
    //show form modal?
    setVisible(true);
  };
  return (
    <>
      <Header title={route.params.userreceivename} action={action} />
      <GiftedChat
        messages={messages}
        showAvatarForEveryMessage={false}
        showUserAvatar={false}
        currentMessage={'a'}
        // @ts-ignore
        onSend={(messages) => onSend(messages)}
        sen={'Enviar'}
        messagesContainerStyle={{
          backgroundColor: "#fff",
          marginLeft: 15,
          marginRight: 15,
          borderRadius: 8,
        }}
        textInputStyle={{
          backgroundColor: '#fff',
          marginRight: 20,
          marginLeft: 20,
        }}
        user={{
          // @ts-ignore
          _id: user.email,
          // @ts-ignore
          userreceive:
            route.params.profile === 'Adm'
              ? route.params.userreceive
              : 'Adm',
        }}
        placeholder={"Digite aqui"}
        // @ts-ignore
        renderAvatar={null}
        renderSend={(props) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 20,
                justifyContent: 'space-between',
              }}
            >
              <View
                {...props}
                style={{
                  marginRight: 7,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setVisibleModal(true)
                  }}
                >
                  <MaterialIcons name="drive-folder-upload" size={24} color={Colors.blueColor} />
                </TouchableOpacity>
                <Modal
                  visible={visibleModal}
                  animationType="slide"
                  onRequestClose={() => setVisibleModal(false)}
                >
                  <ActionChatModal
                    handleClose={() => setVisibleModal(false)}
                    userId={userId}
                  />
                </Modal>
              </View>
              <View
                {...props}
                style={{
                  marginRight: 7,
                }}
              >
                <TouchableOpacity onPress={() => pickChatFiles()}>
                  <Entypo name="attachment" size={24} color="black" />
                </TouchableOpacity>
              </View>
              <Send
                {...props}
                // @ts-ignore
                containerStyle={{
                  justifyContent: 'center',
                }}
                alwaysShowSend={true}
              >
                <FontAwesome
                  name="send"
                  size={24}
                  color={Colors.blueColor}
                />
              </Send>
            </View>
          );
        }}
        renderBubble={(props) => {
          return (
            <Bubble
              {...props}
              textStyle={{
                right: {
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                },
                left: {
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 10,
                },
              }}
              bottomContainerStyle={{
                right: {
                  marginLeft: 10,
                  marginRight: 10,
                  // @ts-ignore
                  textAlign: "center",
                },
                left: {
                  marginLeft: 10,
                  marginRight: 10,
                },
              }}
            />
          );
        }}

      // renderActions={() => (
      //   <>
      //     <View
      //       style={{
      //         height: "100%",
      //         justifyContent: "center",
      //         // marginTop: 10,
      //         alignContent: "center",
      //         alignItems: "center",
      //         // marginBottom: 10,
      //         right: 48,
      //         bottom: 5,
      //         position: "absolute",
      //       }}
      //     >
      //       <TouchableOpacity onPress={() => pickChatFiles()}>
      //         <Entypo name="attachment" size={24} color="black" />
      //       </TouchableOpacity>
      //     </View>
      //     <View
      //       style={{
      //         height: "100%",
      //         justifyContent: "center",
      //         // marginTop: 10,
      //         alignContent: "center",
      //         alignItems: "center",
      //         // marginBottom: 10,
      //         right: 75,
      //         bottom: 5,
      //         position: "absolute",
      //         marginRight: 7,
      //       }}
      //     >
      //       <TouchableOpacity
      //         onPress={() => {
      //           setVisibleModal(true)
      //         }}
      //       >
      //         <MaterialIcons name="drive-folder-upload" size={24} color={Colors.blueColor} />
      //       </TouchableOpacity>
      //       <Modal
      //         visible={visibleModal}
      //         animationType="slide"
      //         onRequestClose={() => setVisibleModal(false)}
      //       >
      //         <ActionChatModal
      //           handleClose={() => setVisibleModal(false)}
      //           userId={userId}
      //         />
      //       </Modal>
      //     </View>

      //   </>
      // )}
      />

      {visible && (
        <Provider>
          <Portal>
            <Dialog visible={visible} onDismiss={hideDialog}>
              <Dialog.Title>Encerrar conversa</Dialog.Title>
              <Dialog.Content>
                <Paragraph>
                  Tem certeza que quer encerrar a conversa?
                </Paragraph>
              </Dialog.Content>
              <Dialog.Actions>
                <Button onPress={hideDialog}>Não</Button>
                <Button onPress={endChat}>Sim</Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        </Provider>
      )}
    </>
  );
};

export default Chat;
