import StyleSheet from "react-native-media-query";
import { Colors } from "../../shared/utils";

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    backgroundColor: "#2b2f33",
    color: Colors.inputWhite,
    width: "100%",
  },
  textHeaderTable: {
    textAlign: "center",
    fontWeight: "100",
    color: "#fff",
    fontFamily: "Hind_400Regular",
  },

  header: {
    height: 40, backgroundColor:
      Colors.blueColor, marginTop: 24
  },
  text: { textAlign: "center", fontWeight: "100" },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: Colors.inputWhite },

  textIdRow: {
    margin: 12,
    fontWeight: "bold",
  },
  textHeader: {
    color: "#fff",
    margin: 12,
    fontWeight: "bold",
  },
  btn: {
    maxWidth: "100%",
    paddingVertical: 4,
    paddingHorizontal: 12,
    backgroundColor: "#d73c4d",
    borderRadius: 4,
    "@media (max-width: 1240px)": {
      paddingHorizontal: 0,
      maxWidth: "80%",
    },
  },
  btnText: {
    textAlign: "center",
    color: "#fff",
  },
});
