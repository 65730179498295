import React, { useContext, useState } from 'react';
import { View, Text } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { styles } from './styles';
import api from '../../services/api';
import UserContext from '../../contexts/User/UserContext';
import messageIdGenerator from '../../utils/messageIdGenerator';
import Header from '../../components/headerscreens';
import Button from '../../components/button';

type Props = {
  navigation: any;
}

export default function ChatSelection({ navigation }: Props) {
  const [selectedValue, setSelectedValue] = useState('');

  const { user } = useContext(UserContext);
  const { email, phone, name, doc, profile, id, password } = user;

  const handleChat = async () => {
    await changeStatus();
    await beginChat();
    navigation.navigate("ChatUser", {
      email,
      phone,
      name,
      doc,
      profile,
      id,
      password,
      status: true,
      toTairana: selectedValue === "mercado"
    });
  };

  const changeStatus = async () => {
    try {
      await api.post("/setstatus", {
        id: id,
        status: "aguardando",
        toTairana: selectedValue === "mercado"
      });
    } catch (e) {
      console.log(e);
    }
  };

  const beginChat = async () => {
    const newId = messageIdGenerator();

    try {
      await api.post("/addmessage", {
        _id: `${newId}${new Date()}`,
        createdAt: new Date(),
        text: `Olá! Obrigado por nos contatar. Por favor aguarde`,
        user: {
          _id: id,
          userreceive: email,
        },
        image: "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <View style={styles.container}>
      <Header title={'Chat'} flagLocalExato={true} />
      <View style={styles.content}>
        <Text style={styles.title}>Selecione um tipo de atendimento</Text>
        <Picker
          selectedValue={selectedValue}
          onValueChange={(value, index) => setSelectedValue(value)}
          style={styles.picker}
          prompt="Selecione uma opção"
        >
          <Picker.Item label="Selecione uma opção" value="" />
          <Picker.Item label="Mercado" value="mercado" />
          <Picker.Item label="Outros assuntos" value="outros" />
        </Picker>

        <Button
          onPress={handleChat}
          disabled={!selectedValue}
        >Continuar</Button>
      </View>
    </View>
  );
}