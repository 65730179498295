import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
  },

  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  buttonClose: {
    marginTop: 15,
    marginLeft: 20,
    marginBottom: 10,
  },

  fileTextTitle: {
    flex: 1,
    // marginLeft: -20,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  lineDivider: {
    borderColor: '#eee',
    backgroundColor: '#eee',
    borderWidth: 1,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 15,
  },

  contentList: {
    marginBottom: 20,
    marginRight: 20,
    marginLeft: 20,
  },

  fileCard: {
    marginBottom: 10,

    borderStyle: 'solid',
    backgroundColor: '#eee',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#eee',
    height: '100%',
    maxHeight: 60,
  },

  fileButton: {
    flexDirection: 'row',
    alignContent: 'flex-start',
  },

  fileText: {
    margin: 5,
    alignSelf: 'center',
  },

  fileImage: {
    height: 50,
    maxHeight: '100%',
    width: 50,
    maxWidth: '100%',
    resizeMode: 'contain',
    margin: 5,
  },
})
