import { StyleSheet } from "react-native";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.maincolor,
  },
  title: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
  picker: {
    marginBottom: 20,
  },
  content: {
    paddingHorizontal: 20
  }
});