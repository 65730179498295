import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
  Modal,
  ActivityIndicator,
  TextInput,
} from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import Toast from 'react-native-toast-message';
import { Colors, canGoToNextPage, canGoToPreviousPage, stringToDate } from '../../shared/utils';
import api from '../../services/api';
import { styles } from './styles';
import ContratoInformation from '../../services/contratoInformationContext';
var base64js = require('base64-js');
import DropDownPicker from 'react-native-dropdown-picker';
import { Icon, Loading } from '../../shared/components';
import UserContext from '../../contexts/User/UserContext';
import { TABLE_HEAD, TABLE_HEAD_MODAL, WEB_ROW_WIDTH } from './utils';

const TableContratos = () => {
  const { user } = useContext(UserContext);
  const [contratoDetailVisible, setcontratoDetailVisible] = useState<boolean>(false);
  const [temContratoFlag, setTemContratoFlag] = useState<boolean>(true);
  const [tableDataToShow, setTableDataToShow] = useState<any[]>([]);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [finalIndex, setFinalIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState('');

  const [contratoInformation, setContratoInformation] = useContext(ContratoInformation);

  const [tableData, setTableData] = React.useState([]);
  const [tableTemp, setTableTemp] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [modalTableData, setModalTableData] = React.useState([]);

  function removeEmptyStrings(dataArray: any[]) {
    return dataArray.filter(item => item !== "");
  }

  useEffect(() => {
    const vidaTouroContratosAdendos: any[] = [];

    async function fetchDataAdm() {
      setLoading(true);

      try {
        let allTouros: any[] = [];

        const allBullsResponse = await api.post('/vidaTouroGeral', {
          cnpjCpf: "",
          profile: "Adm"
        });

        // @ts-ignore
        allBullsResponse.data.result.map(bull => {
          allTouros.push(bull);
        });

        const allTourosJunto = [].concat.apply([], allTouros);

        const sortedStock = removeEmptyStrings(allTourosJunto).sort((a: any, b: any) => {
          const dateA = stringToDate(a.datVigIni);
          const dateB = stringToDate(b.datVigIni);

          // @ts-ignore
          return dateB - dateA;
        });

        sortedStock.forEach((element: any) => {
          vidaTouroContratosAdendos.push([
            element.codTou,
            element.nomTou,
            element.apeTou,
            element.perPar,
            element.datVigIni,
            element.datVigFim,
            element.empPro,
            element.qtdOpr,
            'Visualizar',
            element.cnpjCpf,
          ]);
        });

        setTotalPages(Math.ceil(vidaTouroContratosAdendos.length / 10));
        // @ts-ignore
        setTableData(vidaTouroContratosAdendos);
        // @ts-ignore
        setTableTemp(vidaTouroContratosAdendos);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os contratos',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    async function fetchData() {
      setLoading(true);

      try {
        const response = await api.post('/vidaTouroDadosIniciais', {
          // @ts-ignore
          cnpjCpf: user.doc,
          pagina: 1,
        });

        if (response.data.result === undefined || response.data.result.apeTou == '') {
          setTemContratoFlag(false);
          return null;
        }
        let codTouro = '';
        response.data.result.forEach((element: any) => {
          codTouro = codTouro + ',' + element.codTou;
        });
        codTouro = codTouro.substring(1, codTouro.length);

        const responseEstoque = await api.post('/vidaTouroGeral', {
          // @ts-ignore
          cnpjCpf: user.doc,
          codTouro: codTouro,
        });

        if (responseEstoque.data.result === undefined || responseEstoque.data.result.apeTou == '') {
          setTemContratoFlag(false);
          return null;
        }

        const sortedStock = removeEmptyStrings(responseEstoque.data.result).sort((a: any, b: any) => {
          const dateA = stringToDate(a.datVigIni);
          const dateB = stringToDate(b.datVigIni);

          // @ts-ignore
          return dateB - dateA;
        });

        sortedStock.forEach((element: any) => {
          vidaTouroContratosAdendos.push([
            element.codTou,
            element.nomTou, // Nome do Touro
            element.apeTou, //  Nome de guerra do Touro
            element.perPar, // Porcentagem do proprietário,
            element.datVigIni, //dadosGerais.datVigIni, //DtInicio: "5", Verificar
            element.datVigFim, //dadosGerais.datVigFim, // DtFim: "6", Verificar
            element.empPro, //Verificar
            element.qtdOpr, // QtdContratos: "8",
            'Visualizar',
            element.cnpjCpf,
          ]);
        });

        setTotalPages(Math.ceil(vidaTouroContratosAdendos.length / 10));
        // @ts-ignore
        setTableData(vidaTouroContratosAdendos);
        // @ts-ignore
        setTableTemp(vidaTouroContratosAdendos);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os contratos',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    // @ts-ignore
    if (user.profile == 'Adm') {
      fetchDataAdm();
    } else {
      fetchData();
    }
  }, []);

  //busca o contrato pelo codigo do touro
  useEffect(() => {
    const filterData = tableTemp.filter(item => {
      // @ts-ignore
      return item[0].toString().toLowerCase().includes(searchText.toLowerCase()) ||
        // @ts-ignore
        item[1].toString().toLowerCase().includes(searchText.toLowerCase());
    });
    setTableData(filterData);
  }, [searchText]);

  const getDocuments = useCallback(async (rowData: any) => {
    setLoading(true);
    try {
      const vidaTouroContratosAdendos: any[] = [];
      async function getModalTableData() {
        const response = await api.post('/vidaTouroContratosAdendos', {
          // @ts-ignore
          cnpjCpf: user.profile === 'Adm' ? rowData[9] : user.doc,
          codTouro: rowData[0],
        });

        if (response.data.result === undefined) {
          throw new Error('There is no documents for this contract.');
        }

        response.data.result.forEach((element: any) => {
          vidaTouroContratosAdendos.push([
            element.seqDoc,
            element.desDoc,
            {
              seq: element.seqDoc,
              cod: element.codTou,
            },
          ]);
        });

        // @ts-ignore
        setModalTableData(vidaTouroContratosAdendos);
      }
      await getModalTableData();
      setcontratoDetailVisible(true);
      // @ts-ignore
      setContratoInformation(rowData);
    } catch (error) {
      console.log(error);
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Erro',
        text2: 'Não há documentos para este contrato!',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const element = (rowData: any[]) => (
    <View style={{ alignSelf: 'center' }}>
      <TouchableOpacity onPress={() => getDocuments(rowData)}>
        <Text style={styles.btnText}>Visualizar</Text>
      </TouchableOpacity>
    </View>
  );

  const modalOnPress = async (rowData: any[]) => {
    setLoading(true);
    const response = await api.post('/vidaTouroRelatoriosContratos', {
      // @ts-ignore
      codTouro: rowData.cod,
      // @ts-ignore
      seqDoc: rowData.seq,
      // @ts-ignore
      cnpjCpf: user.profile === 'Adm' ? contratoInformation[11] : user.doc,
    });
    setLoading(false);
    if (
      response.data.result.arqBase64_01 === '' ||
      response.data.result.arqBase64_01 === 'REGISTRO NÃO ENCONTRADO !'
    ) {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Erro',
        text2: 'Não existe arquivo para este documento',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      return;
    } else {
      //WEB
      var binary_string = base64js.toByteArray(response.data.result.arqBase64_01);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string[i];
      }
      var blob = new Blob([bytes], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'file.pdf';
      // @ts-ignore
      window.open(link, '_blank');
      return;
    }
  };

  const modalElement = (data: any) => (
    <View style={{ alignSelf: 'center' }}>
      <TouchableOpacity onPress={() => modalOnPress(data)}>
        <Text style={styles.btnText}>Visualizar</Text>
      </TouchableOpacity>
    </View>
  );

  //pagination
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push({ label: `${i + 1}`, value: `${i}` });
    }

    setDropDownItems(temp);
  }, [totalPages]);

  useEffect(() => {
    let paginaAtual = currentPage;

    let inicial = paginaAtual * 10;
    let final = inicial + 10;

    setInitialIndex(inicial);
    setFinalIndex(final);
  }, [currentPage]);

  useEffect(() => {
    if (tableData.length > 0) {
      if (tableData.length < 10) {
        setTableDataToShow(tableData.slice(0, tableData.length));
      } else {
        setTableDataToShow(tableData.slice(initialIndex, finalIndex));
      }
    }
  }, [tableData, initialIndex, finalIndex]);

  const handleGoToFirstPage = () => {
    setCurrentPage(0);
  };

  const handleGoToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const handleGoToPreviousPage = () => {
    if (currentPage > 0) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage, 10) - 1);
    }
  };

  const handleGoToNextPage = () => {
    // @ts-ignore
    if (parseInt(currentPage, 10) + 1 < totalPages) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage, 10) + 1);
    }
  };
  //

  const clearModal = () => {
    setcontratoDetailVisible(false);
    setModalTableData([]);
  };

  return (
    <SafeAreaView>
      <Modal
        animationType="fade"
        transparent
        visible={contratoDetailVisible}
        onRequestClose={() => {
          setcontratoDetailVisible(contratoDetailVisible => !contratoDetailVisible);
        }}>
        <Toast />
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {loading ? <ActivityIndicator size="small" color="#0000ff" /> : null}
            <View style={{ flex: 1, width: '100%' }}>
              {/* @ts-ignore */}
              <Table borderStyle={{ borderWidth: 0, borderColor: '#C1C0B9' }}>
                <Row
                  data={TABLE_HEAD_MODAL}
                  style={styles.header}
                  textStyle={styles.textHeaderTable}
                />
              </Table>
              <ScrollView style={styles.dataWrapper}>
                {/* @ts-ignore */}
                <Table>
                  {modalTableData.map((rowData: any[], index: number) => (
                    // @ts-ignore
                    <TableWrapper
                      key={index}
                      style={styles.row}
                      textStyle={{ color: Colors.inputWhite }}>
                      <Row
                        key={index}
                        data={rowData.map((cellData, cellIndex) => {
                          if (cellIndex === 2) {
                            return modalElement(cellData);
                          }
                          return cellData;
                        })}
                        // @ts-ignore
                        style={[styles.row, index % 2 && { backgroundColor: Colors.inputWhite }]}
                        textStyle={styles.text}
                      />
                    </TableWrapper>
                  ))}
                </Table>
              </ScrollView>
            </View>

            <TouchableOpacity onPress={() => clearModal()}>
              <Text style={styles.closeModal}>Fechar</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      {loading ? <Loading /> : (
        <>
          <View style={styles.textsContainer}>
            <TextInput
              style={styles.inputBusca}
              placeholder="Pesquisar"
              placeholderTextColor={Colors.lightGreyColor}
              value={searchText}
              onChangeText={text => setSearchText(text)}
            />
          </View>
          <View>
            {temContratoFlag ? (
              <View>
                <View style={styles.container}>
                  <ScrollView horizontal={true}>
                    <View>
                      {/* @ts-ignore */}
                      <Table borderStyle={{ borderWidth: 0, borderColor: '#C1C0B9' }}>
                        <Row
                          data={TABLE_HEAD}
                          widthArr={WEB_ROW_WIDTH}
                          style={styles.header}
                          textStyle={styles.textHeaderTable}
                        />
                      </Table>
                      <ScrollView style={styles.dataWrapper}>
                        {/* @ts-ignore */}
                        <Table borderStyle={{ borderWidth: 1, borderColor: '#C1C0B9' }}>
                          {tableDataToShow.map((rowData, index) => {
                            return (
                              // @ts-ignore
                              <TableWrapper
                                key={index}
                                style={styles.row}
                                textStyle={{ color: Colors.inputWhite }}>
                                <Row
                                  key={index}
                                  data={rowData.map((cellData: any[], cellIndex: number) => (
                                    <Cell
                                      key={cellIndex}
                                      data={
                                        cellIndex === 8 ? element(rowData) : cellData
                                      }
                                      textStyle={styles.text}
                                    />
                                  ))}
                                  widthArr={WEB_ROW_WIDTH}
                                  style={[
                                    styles.row,
                                    // @ts-ignore
                                    index % 2 && { backgroundColor: Colors.inputWhite },
                                  ]}
                                  textStyle={styles.text}
                                />
                              </TableWrapper>
                            );
                          })}
                        </Table>
                      </ScrollView>
                    </View>
                  </ScrollView>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}>
                    <Icon
                      name="fast-rewind"
                      disabled={!canGoToPreviousPage(currentPage + 1)}
                      onPress={handleGoToFirstPage}
                    />
                    <Icon
                      name="chevron-left"
                      disabled={!canGoToPreviousPage(currentPage + 1)}
                      onPress={() => handleGoToPreviousPage()}
                    />
                    {/* @ts-ignore */}
                    <Text style={styles.pageNumber}>{`${parseInt(currentPage, 10) + 1}/${totalPages}`}</Text>
                    <Icon
                      name="chevron-right"
                      disabled={!canGoToNextPage(currentPage + 1, totalPages)}
                      onPress={handleGoToNextPage}
                    />
                    <Icon
                      name="fast-forward"
                      disabled={!canGoToNextPage(currentPage + 1, totalPages)}
                      onPress={handleGoToLastPage}
                    />
                    <DropDownPicker
                      items={dropDownItems}
                      open={open}
                      setOpen={setOpen}
                      setItems={setDropDownItems}
                      value={currentPage}
                      setValue={setCurrentPage}
                      defaultIndex={1}
                      containerStyle={{ height: 50, width: 90 }}
                      style={{ backgroundColor: '#fff', borderColor: '#fff' }}
                      // @ts-ignore
                      placeholder={currentPage + 1}
                      dropDownDirection="TOP"
                      listMode="SCROLLVIEW"
                      itemStyle={{
                        justifyContent: 'flex-start',
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        fontSize: 20,
                        color: '#000',
                        backgroundColor: '#fafafa',
                      }}
                      dropDownStyle={{ backgroundColor: '#fafafa' }}
                    />
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.container}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginTop: 20,
                  }}>
                  Você não possui contratos
                </Text>
              </View>
            )}
          </View>
        </>
      )}
    </SafeAreaView>
  );
};

export default TableContratos;
