import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  ActivityIndicator,
  ImageBackground,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

import { styles, ids } from './styles';
import logo from '../../assets/logo.png';
import logobackground from '../../assets/logobackground.jpeg';
import smallLogo from '../../assets/logomobile.png';
import Button from '../../components/button';
import Input from '../../components/input';
import UserContext from '../../contexts/User/UserContext';
import { handleChangeCPFtoCNPJ } from '../../utils/utils';

type Props = {
  navigation: any;
}

const Login = ({ navigation }: Props) => {
  const { isLoading, handleLogin } = useContext(UserContext)
  const [doc, setLogin] = useState('');
  const [password, setSenha] = useState('');
  const [seePasswordFlag, setSeePasswordFlag] = useState(true);

  const passwordInputRef = useRef();

  const handleSubmit = async () => {
    try {
      await handleLogin(doc, password)

      navigation.navigate('Homepage')
    } catch (err) {
      console.error('Could not Login, error: ', err)
    }
  }

  const handleCpfCnpjChange = useCallback((event: any) => {
    const data = handleChangeCPFtoCNPJ(event);

    setLogin(data);
  }, []);

  const handlePasswordIconPress = useCallback(() => {
    setSeePasswordFlag(value => !value);
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const user = localStorage.getItem('user');
      if (user) {
        localStorage.removeItem('user');
      }
    } else {
      async function clearStorage() {
        const user = await AsyncStorage.getItem('user');
        if (user) await AsyncStorage.removeItem('user');
      }
      clearStorage();
    }
  }, []);

  const handlePassFocus = useCallback(() => {
    // @ts-ignore
    passwordInputRef.current.focus();
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <ScrollView>
        <SafeAreaView style={styles.container}>
          <View style={styles.loginContainer}>
            <View style={styles.content} dataSet={{ media: ids.content }}>
              <View style={styles.animationContainer}>
                <View style={styles.smallLogoContainer} dataSet={{ media: ids.smallLogoContainer }}>
                  <Image
                    source={smallLogo}
                    resizeMode="contain"
                    style={styles.smallLogo}
                    dataSet={{ media: ids.smallLogo }}
                  />
                </View>
                {isLoading ? (
                  <>
                    <ActivityIndicator />
                  </>
                ) : null}
                <View style={styles.inputTitleContainer}>
                  <Text style={styles.titleBemvindo}>Bem vindo</Text>
                  <Text style={styles.title}>A Vida do Touro</Text>
                </View>

                <ImageBackground
                  source={logobackground}
                  resizeMode="contain"
                  style={styles.logobackground}>
                  <View>
                    <Text style={styles.titleRealize}>Realize seu login</Text>

                    <Input
                      placeholder="CPF/CNPJ"
                      style={styles.input}
                      onChangeText={handleCpfCnpjChange}
                      value={doc}
                      onSubmitEditing={handlePassFocus}
                    />

                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // backgroundColor: "#F0F8FF",
                        width: 300,
                        borderRadius: 8,
                      }}>
                      <Input
                        // @ts-ignore
                        ref={passwordInputRef}
                        placeholder="Confirmar Senha"
                        value={password}
                        onChangeText={text => setSenha(text)}
                        secureTextEntry={seePasswordFlag}
                        style={styles.input}
                        autoCapitalize={'none'}
                        onSubmitEditing={handleSubmit}
                      />
                      <Icon
                        name={seePasswordFlag ? 'eye-off-outline' : 'eye-outline'}
                        size={16}
                        color="black"
                        style={{
                          position: 'absolute',
                          right: 20,
                          top: 25,
                          alignSelf: 'center',
                          opacity: 0.4,
                          justifyContent: 'center',
                        }}
                        onPress={handlePasswordIconPress}
                      />
                    </View>
                    <View>
                      <Text
                        style={styles.register}
                        onPress={() => navigation.navigate('ResetPassword')}>
                        Esqueci a Senha
                      </Text>
                    </View>
                    <Button onPress={handleSubmit} disabled={isLoading}>
                      ENTRAR
                    </Button>
                  </View>
                </ImageBackground>
              </View>
            </View>
          </View>
          <View style={styles.logoContainer} dataSet={{ media: ids.logoContainer }}>
            <Image style={styles.logo} dataSet={{ media: ids.logo }} source={logo} />
          </View>
        </SafeAreaView>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default Login;
