import { StyleSheet } from "react-native";
// @ts-ignore
import { vw } from "react-native-expo-viewport-units";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#fff", marginBottom: 20 },
  header: {
    height: 40,
    backgroundColor:
      Colors.blueColor,
    marginTop: 24
  },

  text: { textAlign: "center", fontWeight: "100" },
  textHeaderTable: {
    textAlign: "center",
    fontWeight: "100",
    color: "#fff",
    fontFamily: "Hind_400Regular",
  },
  dataWrapper: { marginTop: -1 },
  row: {
    height: 38,
    backgroundColor: "#fff",
  },

  head: {
    backgroundColor: "#808B97",
    width: vw(60),
  },

  btn: {

    backgroundColor: Colors.maincolor,
    borderRadius: 2,
    marginLeft: 10,
    marginRight: 10,
    // @ts-ignore
    borderRadius: 5,
  },
  btnText: {
    textAlign: "center",
    color: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  textHeaderTop: {
    fontFamily: "Hind_600SemiBold",
    marginRight: 5
  },

  textHeaderResumo: {
    fontFamily: "Hind_700Bold",
    marginRight: 5
  },
  textHeader: {
    textAlign: "center",
    fontWeight: "100",
    color: "#fff",
    fontFamily: "Hind_400Regular",
  },
  textAnswerHeader: {
    fontFamily: "Hind_400Regular",
    fontSize: 12,
  }

});
