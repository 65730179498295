import StyleSheet from "react-native-media-query";
// @ts-ignore
import { vw, vh } from "react-native-expo-viewport-units";
import { Colors } from "../../shared/utils";
import { Platform } from "react-native";

export const { ids, styles } = StyleSheet.create({
  head: {
    backgroundColor: "#2b2f33",
    color: "#fff",

    width: "100%",
  },
  // container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: "#fff" },
  header: { height: 50, backgroundColor: "#537791" },
  text: { textAlign: "center", fontWeight: "100" },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: "#E7E6E1" },

  // @ts-ignore
  text: {
    margin: 12,
  },
  inputBusca: {
    width: "100%",
    borderRadius: 5,
    borderColor: "#707070",
    fontFamily: "Hind_400Regular",
    padding: 10,
    borderWidth: 1,
    marginBottom: 24,
    alignSelf: "center",
    backgroundColor: Colors.inputWhite,
    ...Platform.select({
      web: {
        "@media (minWidth: 768px)": {
          width: "50%",
        },
      },
      default: {},
    }),
  },
  textIdRow: {
    margin: 12,
    // @ts-ignore
    fontWeFight: "bold",
  },
  textHeader: {
    color: "#fff",
    margin: 12,
    fontWeight: "bold",
  },
  // @ts-ignore
  row: {
    flexDirection: "row",
    backgroundColor: "#fff",
    borderBottomWidth: 1,
    borderColor: "#e2e2e2",
  },
  btn: {
    maxWidth: "100%",
    paddingVertical: 4,
    paddingHorizontal: 12,
    backgroundColor: "#d73c4d",
    borderRadius: 4,
    "@media (max-width: 1240px)": {
      paddingHorizontal: 0,
      maxWidth: "80%",
    },
  },
  btnText: {
    textAlign: "center",
    color: "#fff",
  },

  table: {
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
    elevation: 1,
    flexDirection: "column",
    padding: 10,
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 10,
    borderRadius: 4,
    backgroundColor: "white",
    borderBottomColor: Colors.blueColor,
    borderColor: Colors.blueColor,
    borderWidth: 1,
    marginTop: 6,
    marginBottom: 15,
  },
  listItemNome: {
    fontSize: 14,
    fontFamily: "Poppins_700Bold",
    color: Colors.color1,
  },
  listItemEditar: {
    fontSize: 12,
    fontFamily: "Poppins_700Bold",
    color: Colors.blueColor,
    alignSelf: "flex-end",
  },
  listItemTextRight: {
    fontSize: 12,
    color: Colors.greyColor,
    fontFamily: "Poppins_400Regular",
  },
  listItemTextLeft: {
    fontSize: 12,
    color: Colors.greyColor,
    fontFamily: "Poppins_700Bold",
  },
  containerflutuantebotton: {
    position: "absolute",
    bottom: 20,
    right: 0,
    margin: 20,
    alignItems: "center",
    marginBottom: 20,
    marginTop: 20,
  },
  // @ts-ignore
  button: {
    position: "absolute",
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    justifyContent: "center",
    alignItems: "center",
    shadowRadius: 10,
    shadowColor: Colors.blueColor,
    shadowOpacity: 0.3,
    shadowOffset: { height: 10 },
  },
  menu: {
    backgroundColor: Colors.blueColor,
  },
  submenu: {
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    backgroundColor: Colors.inputWhite,
  },
  legend: {
    fontSize: 16,
    color: Colors.greyColor,
    fontFamily: "Poppins_600SemiBold",
    marginLeft: -180,
    backgroundColor: Colors.inputWhite,
    shadowColor: Colors.blueColor,
    shadowOffset: { width: 0, height: 8 },
    shadowOpacity: 1,
    shadowRadius: 50,
    padding: 4,
  },

  containerheader: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    marginBottom: 50,
    paddingBottom: 20,
  },
  container: {
    flex: 1,
    paddingBottom: 20,

    flexDirection: "column",

    ...Platform.select({
      web: {
        "@media (max-width: 768px)": {
          width: "30%",
        },
        "@media (max-width: 1024px)": {
          width: "50%",
        },
        "@media (minWidth: 1280px)": {
          width: "100%",
        },
      },
      default: {},
    }),
  },
  contentContainer: {
    flex: 1,
    width: vw(80),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  iconTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  textsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  secondContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: vh(5),
  },
  content: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    fontWeight: "bold",
    color: Colors.color1,
    ...Platform.select({
      web: {
        fontSize: 32,
      },
      default: { fontSize: 20 },
    }),
  },
  mainText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.color1,
    marginTop: 16,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },

  card: {
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
    paddingBottom: 10,
  },
});
