import React, { useEffect, useState } from 'react'
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Platform,
  ActivityIndicator
} from 'react-native'
import * as FileSystem from 'expo-file-system'
import { getDownloadURL, getMetadata, getStorage, listAll, ref } from 'firebase/storage'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import pdfIcon from '../../../assets/pdfIcon.png'
import { Colors } from '../../shared/utils'
import { StorageAccessFramework } from 'expo-file-system';
import { styles } from './styles'
import * as Permissions from 'expo-permissions'
import * as MediaLibrary from 'expo-media-library'
import { Toast } from 'react-native-toast-message/lib/src/Toast'

type Props = {
  handleClose: () => void;
  userId: string;
}

export function ActionChatModal({ handleClose, userId }: Props) {
  const [fileList, setFileList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const storage = getStorage()
  const storageRef = ref(storage, `documentsChat/${userId}`)

  useEffect(() => {
    let fileNamesArray: string[] = []
    listAll(storageRef)
      .then((listResult) => {
        fileNamesArray = listResult.items.map((itemRef) => itemRef.name).reverse()
        const promisesUrl = Promise.all(listResult.items.map((itemRef) => getDownloadURL(itemRef)).reverse())
        const promisesMetadata = Promise.all(listResult.items.map((itemRef) => getMetadata(itemRef)).reverse())

        return Promise.all([promisesMetadata, promisesUrl])
      })
      .then((urlsArray) => {
        let listMetadata: any[] = []
        let listUrls: any[] = []
        setLoading(false)
        urlsArray.map((item, index) => {
          index === 0 ? listMetadata = item : listUrls = item
        })

        const listContentTypes = listMetadata.map((item, index) => {
          return item.contentType
        })

        const fileList = listContentTypes.map((item, index) => {
          return ({
            name: fileNamesArray[index],
            fileUrl: listUrls[index],
            contentType: listContentTypes[index],
          })
        })

        setFileList(fileList)
      })
  }, [])

  const handleDownload = async (fileUrl: string, name: string, contentType?: string) => {
    if (Platform.OS == 'web') {
      const link = document.createElement('a')
      link
        .setAttribute('href', fileUrl)
      link.setAttribute('download', name)
      document.body.appendChild(link)
      // @ts-ignore
      window.open(link, '_blank')
      link.remove()
    } else {
      //download to mobile device
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL)
      if (status === 'granted') {
        const callback = (downloadProgress: any) => {
          const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite
        }
        const downloadResumable = FileSystem.createDownloadResumable(
          fileUrl,
          FileSystem.documentDirectory + name,
          {},
          callback,
        )

        try {
          // @ts-ignore
          const { uri } = await downloadResumable.downloadAsync()
          if (name.includes('.pdf')) {
            const permissions = await StorageAccessFramework.requestDirectoryPermissionsAsync();
            if (!permissions.granted) {
              return
            }

            const base64File = await FileSystem.readAsStringAsync(uri, { encoding: FileSystem.EncodingType.Base64 })

            await StorageAccessFramework.createFileAsync(permissions.directoryUri, name, 'application/pdf')
              .then(async (uri) => {
                await FileSystem.writeAsStringAsync(uri, base64File, { encoding: FileSystem.EncodingType.Base64 })
              })
            return alert(`O documento ${name} foi salvo no seu dispositivo`)
          }
          const asset = await MediaLibrary.createAssetAsync(uri)
          await MediaLibrary.createAlbumAsync('Documentos', asset, false)
          Toast.show({
            type: 'success',
            text1: 'Sucesso',
            text2: 'Documento salvo com sucesso',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
            position: 'top'
          })
        } catch (e) {
          console.error(e)
          Toast.show({
            type: 'error',
            text1: 'Erro',
            text2: 'Não foi possível salvar o documento',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
            position: 'top'
          })
        }
      } else {
        throw new Error('Camera roll permission not granted')
      }
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <View
        style={styles.title}
      >
        <TouchableOpacity onPress={handleClose} style={styles.buttonClose}>
          <FontAwesome name="chevron-left" size={24} color={Colors.blueColor} />
        </TouchableOpacity>
        <Text
          style={styles.fileTextTitle}
        >
          Arquivos enviados
        </Text>
      </View>

      {loading ? <ActivityIndicator size="large" color={Colors.blueColor} /> : null}
      <View
        style={styles.lineDivider}
      />
      <ScrollView
        style={styles.contentList}
      >
        {
          fileList.map((item, index) => {
            if (!item) {
              return (
                <Text>Não há itens para serem exibidos</Text>
              )
            }
            return (
              <View
                key={index}
                style={styles.fileCard}
              >
                <TouchableOpacity
                  // @ts-ignore
                  title={item.name}
                  style={styles.fileButton}
                  onPress={() => handleDownload(item.fileUrl, item.name)}
                >
                  {
                    item.contentType === 'application/pdf' ? (
                      <Image
                        source={pdfIcon}
                        style={styles.fileImage}
                      />
                    ) : (
                      <Image
                        source={{ uri: item.fileUrl }}
                        style={styles.fileImage}
                      />
                    )

                  }
                  {/* */}
                  <Text
                    style={styles.fileText}
                  >
                    {item.name}
                  </Text>
                </ TouchableOpacity>
              </View>
            )
          })
        }
      </ScrollView>
    </SafeAreaView>
  )
}