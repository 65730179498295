import StyleSheet from "react-native-media-query";
// @ts-ignore
import { vw } from "react-native-expo-viewport-units";
import { Platform } from "react-native";
import { Colors } from "../../shared/utils";

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1, backgroundColor: "#fff", marginBottom: 20
  },
  contentContainer: {
    width: vw(80),
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  textsContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  content: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    color: Colors.color1,
    ...Platform.select({
      web: {
        // alignSelf: "flex-start",
        // marginLeft: vw(5),
      },
      default: {},
    }),
  },
  mainText: {
    fontSize: 20,
    fontWeight: "bold",
    color: Colors.color1,
    marginTop: 16,
    ...Platform.select({
      web: {
        // alignSelf: "flex-start",
        // marginLeft: vw(5),
      },
      default: {},
    }),
  },
  backButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    borderWidth: 1,
    borderColor: "#fff",
    marginTop: 20,
  },
  inputBusca: {
    width: "100%",
    // height: vh(5),
    borderRadius: 5,
    borderColor: "#707070",
    padding: 10,
    borderWidth: 1,
    marginBottom: 24,
    // color: Colors.inputWhite,
    alignSelf: "center",
    backgroundColor: Colors.inputWhite,
    ...Platform.select({
      web: {
        "@media (minWidth: 768px)": {
          width: "50%",
        },
      },
      default: {},
    }),
  },

  //////////  NEW STYLES  //////////
  containerheader: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    paddingBottom: 20,
  },
  safeContainer: {

    flex: 1,
    // paddingBottom: 20,
    backgroundColor: Colors.maincolor,
    flexDirection: "column",
  },

  card: {
    marginTop: 15,
    borderRadius: 8,
    backgroundColor: "#fff",
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
    padding: 10
  },
  cardScrowll: {
    marginTop: 15,
    // marginBottom: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
    // elevation: 1,
    // flexDirection: "column",
    // flex: 1,
    // paddingBottom: 10,
    display: "flex",
    //card all screen
    width: "100%",
    height: "100%",
  },
});
