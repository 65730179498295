import React, { useContext, useEffect } from "react";

import {
  Image,
  Text,
  View,
  SafeAreaView,
} from "react-native";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "./styles";
import Header from "../../components/header";
import upImage from "../../../assets/boiprovisorio.jpg";
import { Card } from "react-native-elements";
import UserContext from "../../contexts/User/UserContext";

type Props = {
  navigation: any;
}

const Homepage = ({ navigation }: Props) => {
  const { user, setUser } = useContext(UserContext);
  const { name } = user;

  useEffect(() => {
    let usuario

    if (Platform.OS === 'web') {
      usuario = localStorage.getItem("user");

    } else {
      async function getUser() {
        usuario = await AsyncStorage.getItem("user");
        // @ts-ignore
        setUser(JSON.parse(usuario));

      }
      getUser();
    }

    if (usuario) {
      setUser(JSON.parse(usuario));
    }
  }, []);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <Header />

      <View style={styles.container}>
        <View>
          {/* @ts-ignore */}
          <Card containerStyle={styles.card}>
            <Image
              source={upImage}
              resizeMode="stretch"
              style={styles.upperImageBack}
            // dataSet={{ media: ids.upperImage }}
            />

            <View style={styles.textsContainer}>
              <Text style={styles.title}>Olá, {name}!</Text>
              <Text style={styles.secundaryText}>
                Seja bem-vindo ao aplicativo A Vida do Touro!
              </Text>
              <Text style={styles.secundaryText} textBreakStrategy="balanced">
                Essa inovadora ferramenta multiplataforma é a sua nova aliada
                para acompanhar, de onde você estiver, todas as informações de
                seus touros em coleta na Central Tairana.
              </Text>
            </View>
          </Card>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Homepage;
