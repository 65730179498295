import StyleSheet from "react-native-media-query";
// @ts-ignore
import { vw, vh } from "react-native-expo-viewport-units";
import { Platform } from "react-native";
import { Colors } from "../../shared/utils";

export const { ids, styles } = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    marginBottom: 50,
    paddingBottom: 20,
  },
  contentContainer: {
    width: vw(90),
    display: "flex",
    flexDirection: "column",
  },
  textsContainer: {
    marginLeft: 15,
    marginRight: 15,
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  whiteLine: {
    displa: "flex",
    alignContent: "center",
    borderRadius: 16,
    justifyContent: "center",
    width: "100%",
    height: 50,
    backgroundColor: "#fff",
    padding: 10,
    marginTop: 10,
  },
  content: {
    fontWeight: "bold",
    fontSize: 24,
    color: Colors.color1,
    marginTop: 20,
    marginBottom: 20,
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#fff",
    marginTop: vh(20),
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  mainText: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#fff",
    marginTop: 16,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  inputBusca: {
    width: "100%",
    height: vh(5),
    borderRadius: 10,
    padding: 10,
    color: "#000",
    marginTop: vh(5),
    backgroundColor: "rgba(0,0,0,0.5)",
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  tableContainer: {
    marginTop: 32,
  },
});
