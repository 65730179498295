import React, { useContext, useState } from "react";
import {
  View,
  ScrollView,
  Text,
  SafeAreaView,
} from "react-native";
import { styles } from "./styles";
import Header from "../../components/headerscreens";
import TableTouroAdm from "../../components/tableTouroAdm";
import BullInformationContext from "../../services/bullInformationContext";
import FotosTouroAdm from "../../components/fotosTouroAdm";
import { Card } from "react-native-elements";

type Props = {
  navigation: any;
}

const InformacoesTouroAdm = ({ navigation }: Props) => {
  const [temTouro, setTemTouro] = useState(true);
  const [bullInformation, setBullInformation] = useContext(
    BullInformationContext
  );

  // @ts-ignore
  const { codTou, nomTou, dtNasc } = bullInformation;

  return (
    <View style={styles.containerheader}>
      <Header title={"Fotos do Touro"} flagLocalExato={true} />
      <SafeAreaView style={styles.safeContainer}>
        {/* @ts-ignore */}
        <Card style={styles.card}>
          {/* @ts-ignore */}
          {bullInformation.codTou ? (
            <ScrollView>
              <FotosTouroAdm
                codTou={codTou}
                nomTou={nomTou}
                navigation={navigation}
              />
            </ScrollView>
          ) : (
            <ScrollView>
              <TableTouroAdm />
            </ScrollView>
          )}
        </Card>
      </SafeAreaView>
    </View >
  );
};

export default InformacoesTouroAdm;