import { StyleSheet } from "react-native";
import { Platform } from "react-native";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 540px)": {
      width: 300,
      height: 300,
    },
  },

  card: {
    ...Platform.select({
      web: {
        width: 400,
        height: 200,
        marginRight: 10,
        marginLeft: 10,
      },
      default: {
        width: "100%",
        height: "100%",
        margin: 2,
        flex: 1,
      }
    }),
    flex: 1,
    maxWidth: "100%",
    width: 150,
    height: 80,
    resizeMode: "cover",
  },

  dateInput: {
    width: "100%",
    borderRadius: 10,
    padding: 10,
    backgroundColor: "rgba(0,0,0,0.5)",
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },

  text: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
    marginRight: 10,
  },

  textPequeno: {
    fontSize: 12,
    fontWeight: "bold",
    color: "black",
  },

  input: {
    width: 120,
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 8,
    marginTop: -20,
    marginLeft: 25,
    display: "flex",
    flexDirection: "row",
  },

  textContainer: {

    ...Platform.select({
      web: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
      },
      default: {
        display: "flex",
        flexDirection: "column",
        width: "60%",
      }
    }),
  },
  textInputContainer: {
    display: "flex",
    flexDirection: "column",
    width: 120,
    marginTop: 8,
    alignSelf: "flex-end"
  },

  modalContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "lightblue",
    borderRadius: 10,
    padding: 10,
  },

  modalContent: {
    backgroundColor: "aliceblue",
    position: "absolute",
    top: '50%',
    width: 300,
    height: 150,
    borderRadius: 10,
    display: "flex",
    alignSelf: "center",
    opacity: 0.9,
  },

  modalText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
    marginTop: 8,
    marginBottom: 15,
  },

  modalButton: {
    width: "40%",
    height: 40,
    borderRadius: 5,
    marginTop: 8,
    margin: 10,
  },

  modalButtons: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 8,
  },
  modalChoiceText: {
    fontFamily: "Hind_700Bold",
    color: Colors.blueColor
  },


  backButton: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start",
  },
  firstContainer: {
    flexDirection: "row",
    margin: 8,
    ...Platform.select({
      web: {
        marginTop: 20,
      },
      default: {
      },
    }),
  },

  uploadContainer: {
    justifyContent: "flex-end",
    margin: 8,
    ...Platform.select({
      web: {
      },
      default: {
      },
    }),
  },

  secondContainer: {
    ...Platform.select({
      web: {
        marginTop: 40,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      },
      default: {
        width: "100%",
      },
    }),
  },
  imageContainer: {
    ...Platform.select({
      web: {
        marginTop: 20,
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,

      },
      default: {
        width: 120,
        height: "50%",
        flex: 1,
      },
    }),
  },

  imageSaveContainer: {

    ...Platform.select({
      web: {
        marginTop: 20,
        width: 200,
        height: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,

      },
      default: {
        width: 200,
        height: "50%",
        display: 'flex',
      },
    }),
  },

  imageContainer2: {
    margin: 8,
  },

  carouselContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },

  carouselIconContainer: {
    ...Platform.select({
      web: {
        alignSelf: "flex-end",
        marginRight: 25,
        marginTop: -20,
      },
      default: {
        alignSelf: "flex-end",
        marginRight: 25,
        marginTop: 50,
      },
    }),
  },

  uploadButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    borderColor: "#fff",
    backgroundColor: "white",
    borderWidth: 1,
    borderRadius: 5,
    width: 120,
    height: 60,
    ...Platform.select({
      web: {
      },
      default: {
      },
    }),
  },

  picDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-start",
    borderColor: "#fff",
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    height: 20,
  },
  textHeader: {
    fontSize: 18,
    fontFamily: "Hind_700Bold",
    color: Colors.greyColor
  },
  textAnswerHeader: {
    fontSize: 16,
    marginLeft: 4,
    fontFamily: "Hind_400Regular",
    color: Colors.greyColor
  },
  button: {
    position: 'absolute',
    bottom: -20,
    right: -30,
    margin: 20,
    zIndex: 1,
    width: 80,
    height: 40,
    backgroundColor: Colors.buttonColor,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },

  controlButtons: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: "100%",
  },
  // Style for iOS ONLY...
  datePicker: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 260,
    height: 260,
    display: 'flex',
  },
});
