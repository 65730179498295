import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Dimensions,
  TextInput,
} from 'react-native';
import {
  Table,
  TableWrapper,
  Row,
  Cell
} from 'react-native-table-component-2';
import { styles } from './styles';
import api from '../../services/api';
import DropDownPicker from 'react-native-dropdown-picker';
var base64js = require('base64-js');
import Toast from 'react-native-toast-message';
import { Colors, brCurrencyMask, canGoToNextPage, canGoToPreviousPage, stringToDate } from '../../shared/utils';
import { Icon, Loading } from '../../shared/components';
import UserContext from '../../contexts/User/UserContext';

const TableDespesas = () => {
  const { user } = useContext(UserContext);

  const tableHead = [
    'N° Documento',
    'Dt emissão',
    'Cliente',
    'Valor Documento',
    'Link NF',
    'Link Boleto',
  ];

  const width = Dimensions.get('window');

  const [tableTemp, setTableTemp] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [allBulls, setAllBulls] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [temDespesasFlag, setTemDespesasFlag] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState('');
  let widthArr: number[];

  //WEB
  if (width.width < 768) widthArr = [110, 90, 300, 120, 120, 140];
  else
    widthArr = [
      width.width / 7.5,
      width.width / 7,
      width.width / 4,
      width.width / 7,
      width.width / 7,
      width.width / 7,
    ];

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push({ label: `${i + 1}`, value: `${i + 1}` });
    }
    setDropDownItems(temp);
  }, [totalPages]);

  useEffect(() => {
    async function fetchDataAdm() {
      setLoading(true);
      try {

        const response = await api.post('/vidaTouroDespesas', {
          cnpjCpf: "",
          profile: "Adm",
          // @ts-ignore
          pagina: parseInt(currentPage, 10),
        });

        if (response.data.result === undefined) {
          setTemDespesasFlag(false);
          setLoading(false);
          return null;
        }

        setTotalPages(parseInt(response.data.result[response.data.result.length - 1].nroPaginas));

        let array = response.data.result;
        //se for um array
        if (Array.isArray(array)) {
          array.pop();
        }
        setLoading(false);

        setAllBulls(array);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os dados',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    async function fetchData() {
      setLoading(true);

      try {
        const response = await api.post('/vidaTouroDespesas', {
          // @ts-ignore
          cnpjCpf: user.doc,
          pagina: currentPage,
        });

        if (response.data.result === undefined || response.data.result.codSnf == '') {
          setTemDespesasFlag(false);
          setLoading(false);
          return null;
        }

        let array = response.data.result;

        setTotalPages(parseInt(array[array.length - 1].nroPaginas));

        //remove o ultimo elemento do array que é o numero de paginas
        if (Array.isArray(array)) {
          array.pop();
        }

        setAllBulls(array);
        setLoading(false);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os dados',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }

    // @ts-ignore
    if (user.profile !== 'Adm') {
      fetchData();
    } else {
      fetchDataAdm();
    }
  }, [currentPage]);

  useEffect(() => {
    const filterData = tableTemp.filter(item => {
      return item[2].toLowerCase().includes(searchText.toLowerCase());
    });
    setTableData(filterData);
  }, [searchText]);

  useEffect(() => {
    let getTouro: any[] = [];
    const vidaTouroContratosAdendos: any[] = [];

    allBulls.forEach(element => {
      getTouro.push({
        numNfe: element.numNfe,
        dataEmi: element.dataEmi,
        nomProprietario: element.nomProprietario,
        valorTotal: element.valorTotal, // valor total do doc
        apeTou: element.apeTou, //  Nome de guerra do Touro
        estEntSai: element.estEntSai, // Estado de entrada/saida
        datMovEst: element.datMovEst, // Data do movimento
        cnpjCpf: element.cnpjCpf, // CNPJ/CPF do proprietario
        codSnf: element.codSnf, // Codigo da SNF
        hasTicket: element.temBoleto,
      });
    });

    const sortedGetBulls = getTouro.sort((a, b) => {
      const dateA = stringToDate(a.dataEmi);
      const dateB = stringToDate(b.dataEmi);

      // @ts-ignore
      return dateB - dateA;
    });

    sortedGetBulls.forEach(element =>
      vidaTouroContratosAdendos.push([
        element.numNfe, //numero da nota fiscal
        element.dataEmi, // Nome guerra (apelido)
        element.nomProprietario, // tipo movimento do estoque
        brCurrencyMask(element.valorTotal), // documento
        element.datMovEst, // data do movimento
        element.qtdMovEst, // quantidade do movimento
        element.cnpjCpf,
        element.codSnf,
        element.hasTicket,
      ]),
    );

    setTableData(vidaTouroContratosAdendos);
    setTableTemp(vidaTouroContratosAdendos);
  }, [allBulls]);

  useEffect(() => {
    const filterData = tableTemp.filter(item => {
      return item[2].toLowerCase().includes(searchText.toLowerCase());
    });
    setTableData(filterData);
  }, [searchText]);

  const handleGoToFirstPage = () => {
    setCurrentPage(1);
    setLoading(true);
  };

  const handleGoToLastPage = () => {
    setLoading(true);
    // @ts-ignore
    setCurrentPage(parseInt(totalPages));
  };

  const handleGoToPreviousPage = () => {
    if (currentPage > 1) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage) - 1);
      setLoading(true);
    }
  };

  const handleGoToNextPage = () => {
    // @ts-ignore
    if (parseInt(currentPage, 10) + 1 <= parseInt(totalPages)) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage) + 1);
      setLoading(true);
    }
  };

  const onPressNf = async (rowData: any[]) => {
    setLoading(true);

    const response = await api.post('/vidaTouroNf', {
      cnpjCpf: rowData[6],
      codSnf: rowData[7],
      numNfv: rowData[0],
      dataIni: rowData[1],
    });

    if (response.data.result.arqBase64_01 == '') {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Erro',
        text2: 'Não existe arquivo para essa nota fiscal',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      setLoading(false);
      return;
    } else {
      //WEB
      var binary_string = base64js.toByteArray(response.data.result.arqBase64_01);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string[i];
      }
      var blob = new Blob([bytes], { type: 'application/pdf' });

      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'file.pdf';
      // link.click();
      // @ts-ignore
      window.open(link, '_blank');
    }
    setLoading(false);
  };

  const onPressBoleto = async (rowData: any[]) => {
    if (rowData[8] === 'L') {
      return Toast.show({
        type: 'info',
        position: 'top',
        text1: 'Atenção',
        text2: 'Boleto Liquidado.',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    }
    setLoading(true);
    const response = await api.post('/vidaTouroBoleto', {
      cnpjCpf: rowData[6],
      codSnf: rowData[7],
      numNfv: rowData[0],
    });

    if (response.data.result.arqBase64_01 == '') {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Erro',
        text2: 'Não existe arquivo para este boleto',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      setLoading(false);
      return;
    } else {
      //WEB
      var binary_string = base64js.toByteArray(response.data.result.arqBase64_01);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string[i];
      }
      var blob = new Blob([bytes], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'file.pdf';
      // link.click();
      // @ts-ignore
      window.open(link, '_blank');
    }
    setLoading(false);
  };

  const elementNf = (data: any) => (
    <View style={{ alignSelf: 'center' }}>
      <TouchableOpacity onPress={() => onPressNf(data)} disabled={loading}>
        <Text style={styles.btnText}>Visualizar</Text>
      </TouchableOpacity>
    </View>
  );

  const elementBoleto = (data: any) => (
    <View style={{ alignSelf: 'center' }}>
      <TouchableOpacity onPress={() => onPressBoleto(data)} disabled={loading}>
        <Text style={styles.btnText}>Visualizar</Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }} horizontal={true}>
      {!temDespesasFlag ? (
        <View style={styles.container}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: '#000',
              marginTop: 20,
              marginBottom: 20,
              textAlign: 'center',
            }}>
            Nenhum resultado encontrado
          </Text>
        </View>
      ) : (
        <View style={{ flex: 1, paddingTop: 24 }}>
          {loading ? <Loading /> : (
            <>
              <View style={styles.textsContainer}>
                {user?.profile === "Adm" && <TextInput
                  style={styles.inputBusca}
                  placeholder="Pesquisar"
                  placeholderTextColor={Colors.lightGreyColor}
                  value={searchText}
                  onChangeText={(text) => setSearchText(text)}
                />}
              </View>
              <View>
                {/* @ts-ignore */}
                <Table>
                  <Row
                    data={tableHead}
                    widthArr={widthArr}
                    style={styles.header}
                    textStyle={styles.textHeaderTable}
                  />
                </Table>
                <View style={styles.dataWrapper}>
                  {/* @ts-ignore */}
                  <Table>
                    {tableData.map((rowData, index) => (
                      // @ts-ignore
                      <TableWrapper key={index} style={styles.row}>
                        <Row
                          key={index}
                          // @ts-ignore
                          data={rowData.map((cellData, cellIndex) => (
                            <Cell
                              key={cellIndex}
                              data={
                                cellIndex === 4
                                  ? elementNf(rowData)
                                  : cellIndex === 5
                                    ? elementBoleto(rowData)
                                    : cellData
                              }
                              textStyle={styles.text}
                            />
                          ))}
                          widthArr={widthArr}
                          // @ts-ignore
                          style={[styles.row, index % 2 && { backgroundColor: Colors.inputWhite }]}
                          textStyle={styles.text}
                        />

                      </TableWrapper>
                    ))}
                  </Table>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}>
                    <Icon
                      name="fast-rewind"
                      disabled={!canGoToPreviousPage(currentPage)}
                      onPress={handleGoToFirstPage}
                    />
                    <Icon
                      name="chevron-left"
                      disabled={!canGoToPreviousPage(currentPage)}
                      onPress={handleGoToPreviousPage}
                    />
                    {/* @ts-ignore */}
                    <Text style={styles.pageNumber}>{`${parseInt(currentPage, 10)
                      }/${totalPages}`}</Text>
                    <Icon
                      name="chevron-right"
                      disabled={!canGoToNextPage(currentPage, totalPages)}
                      onPress={handleGoToNextPage}
                    />
                    <Icon
                      name="fast-forward"
                      disabled={!canGoToNextPage(currentPage, totalPages)}
                      onPress={handleGoToLastPage}
                    />
                    <DropDownPicker
                      items={dropDownItems}
                      open={open}
                      setOpen={setOpen}
                      setItems={setDropDownItems}
                      value={currentPage}
                      setValue={setCurrentPage}
                      defaultIndex={1}
                      containerStyle={{ height: 50, width: 90 }}
                      style={{ backgroundColor: '#fff', borderColor: '#fff' }}
                      // @ts-ignore
                      placeholder={currentPage}
                      dropDownDirection="TOP"
                      listMode="SCROLLVIEW"
                      itemStyle={{
                        justifyContent: 'flex-end',
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        fontSize: 20,
                        color: '#000',
                        backgroundColor: '#fafafa',
                      }}
                      dropDownStyle={{ backgroundColor: '#fafafa' }}
                    />
                  </View>
                </View>
              </View>
            </>
          )}

        </View>
      )}
    </ScrollView>
  );
};

export default TableDespesas;
