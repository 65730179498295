import { Dimensions, Platform } from "react-native";

const width = Dimensions.get('window');

export const TABLE_HEAD = [
  'Nome',
  'Nome de Guerra',
  'Status',
  'Dt. Entrada Central',
  'Início Quarentena',
  'Previsão Coleta',
  'Início Coleta',
  'Saída da central',
];

export const ROW_WIDTH = Platform.OS !== 'web' ? 
[150, 200, 100, 100, 100, 100, 100, 100] 
: width.width < 768 
? [150, 200, 100, 100, 100, 100, 100, 100] 
: [
  width.width / 9,
  width.width / 4.78,
  width.width / 13,
  width.width / 9,
  width.width / 9,
  width.width / 9,
  width.width / 9,
  width.width / 9,
]