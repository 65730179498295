import React from "react";
import {
  View,
  Image,
  TouchableOpacity,
  SafeAreaView,
  Text,
  Platform
} from "react-native";
import { styles } from "./styles";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";

const Header = () => {
  const navigation = useNavigation();

  const openMenu = () => {
    // @ts-ignore
    navigation.openDrawer();
  };

  return (
    <SafeAreaView style={{ marginTop: 15 }}>
      <View style={styles.container}>
        <TouchableOpacity activeOpacity={10}
          style={Platform.OS === "web" ? {
          }
            : {
              alignSelf: "center",
            }}
        >
          <Icon name="menu" size={30} color="#2C2967" />
          {/* Colocado para dividir */}
        </TouchableOpacity>
        <View>
          <Image
            source={require("../../assets/logocomborda.png")}
            style={styles.logo}
          />
          <Text style={styles.text}>Vida do Touro</Text>
        </View>
        <View>
          <TouchableOpacity
            style={
              Platform.OS === "web" ? {
                position: "absolute",
                right: 0,
                top: -15,
                marginRight: 10,
              }
                : {
                  // display: "flex",
                  // flexDirection: "row",
                  // alignContent: "center",
                  // alignItems: "center",
                  // justifyContent: "center",
                  marginTop: Constants.statusBarHeight - 56,
                }}
            onPress={() => openMenu()}
          >
            <Icon name="menu" size={30} color="white"
              style={styles.icon}
            />
          </TouchableOpacity>
          <TouchableOpacity></TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>

    // <View style={styles.container}>/View>
    // <View style={styles.header}>
    //   <MaterialIcons
    //     name="menu"
    //     size={28}
    //     onPress={openMenu}
    //     style={styles.icon}
    //   />
    // </View>
  );
};

export default Header;
