import React, { useCallback, useEffect, useState } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  TextInput,
  Text,
  FlatList,
  Animated,
  ActivityIndicator,
} from 'react-native';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';

import { styles } from './styles';
import Header from '../../components/headerscreens';
import api from '../../services/api';
import { Colors } from '../../shared/utils';
import { Loading } from '../../shared/components';

type Props = {
  navigation: any;
  route: any;
}

type ListItemType = {
  data: any
}

const InformacoesUsuarios = ({ navigation, route }: Props) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [tableTemp, setTableTemp] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const handleCpfCnpjChange = (data: any) => {
    if (data.length > 11) {
      // It's cnpj
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(5, 3)}/`;
      if (data.length > 12) {
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      } else {
        cnpj += data.substr(8);
      }
      data = cnpj;
    } else {
      // It's cpf
      let cpf = '';
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
      }
      data = cpf;
    }
    return data;
  };

  useEffect(() => {
    const localData: any[] = [];
    async function fetchData() {
      setLoading(true);
      try {
        const res = await api.get('/allusers');
        const response = res.data.users;
        let maskDoc = '';
        response.forEach((element: any) => {
          maskDoc = handleCpfCnpjChange(element.doc);
          localData.push({
            nome: element.name,
            doc: maskDoc,
            perfil: element.profile,
            active: element.active,
          });
        });
        // @ts-ignore
        setUserData(localData);
        // @ts-ignore
        setTableTemp(localData);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os usuários',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }
    if (route.params.refresh !== undefined) {
      //refresh component
      setTableTemp([]);
      setPage(1);
      setUserData([]);
      fetchData();
    }
  }, [route.params]);

  function ListItem({ data }: ListItemType) {
    return (
      // @ts-ignore
      <View style={styles.listItem}>
        {/* @ts-ignore */}
        <Text style={styles.listItemNome}>{data?.nome ? data?.nome.toUpperCase() : ''}</Text>
        <View style={{ flexDirection: 'row' }}>
          {/* @ts-ignore */}
          <Text style={styles.listItemTextLeft}>CPF/CNPJ: </Text>
          {/* @ts-ignore */}
          <Text style={styles.listItemTextRight}>{data.doc}</Text>
        </View>

        <View
          style={{
            flex: 1,
            width: '100%',
          }}>
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: 'row',
                width: '100%',
              }}>
              <View style={{ flexDirection: 'row', width: 100 }}>
                {/* @ts-ignore */}
                <Text style={styles.listItemTextLeft}>PERFIL: </Text>

                {/* @ts-ignore */}
                <Text style={styles.listItemTextRight}>{data.perfil}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                {/* @ts-ignore */}
                <Text style={styles.listItemTextLeft}>Ativo: </Text>

                <Text
                  style={data.active ? styles.listItemTextRightGreen : styles.listItemTextRightRed}>
                  {data.active ? 'Sim' : 'Não'}
                </Text>
              </View>
            </View>
            <View>
              <TouchableWithoutFeedback onPress={() => openEdicao(data.doc)}>
                {/* @ts-ignore */}
                <Text style={styles.listItemEditar}>EDITAR</Text>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </View>
      </View>
    );
  }

  const RenderEmptyList = useCallback(() => {
    return (
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        <Text>Não foram encontrados usuários</Text>
      </View>
    );
  }, []);

  const openEdicao = (documento: any) => {
    const docRegex = documento.replace(/[^\d]+/g, '');
    navigation.navigate('EditUser', {
      documento: docRegex,
    });
  };

  useEffect(() => {
    const localData: any[] = [];
    async function fetchData() {
      setLoading(true);
      try {
        const res = await api.get('/allusers');
        const response = res.data.users;
        let maskDoc = '';
        response.forEach((element: any) => {
          maskDoc = handleCpfCnpjChange(element.doc);
          localData.push({
            nome: element.name,
            doc: maskDoc,
            perfil: element.profile,
            active: element.active,
          });
        });
        // @ts-ignore
        setUserData(localData);
        // @ts-ignore
        setTableTemp(localData);
      } catch (error) {
        console.log(error);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Erro',
          text2: 'Não foi possível buscar os usuários',
          visibilityTime: 4000,
          autoHide: true,
          topOffset: 30,
          bottomOffset: 40,
        });
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const searchFilterFunction = (text: string) => {
    // Check if searched text is not blank
    if (text) {
      const newData = tableTemp.filter(item => {
        // @ts-ignore
        const itemData = item.nome ? item.nome.toUpperCase() : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });

      setSearch(text);
      setUserData(newData);
    } else {
      setSearch(text);
      setUserData(tableTemp);
    }
  };

  const callRegister = () => {
    navigation.navigate('Signup');
  };

  const animation = new Animated.Value(0);
  let open = false;
  const toggleMenu = () => {
    const toValue = open ? 0 : 1;

    Animated.spring(animation, {
      toValue,
      friction: 5,
      useNativeDriver: true,
    }).start();
    open = !open;
  };

  const rotation = {
    transform: [
      {
        rotate: animation.interpolate({
          inputRange: [0, 1],
          outputRange: ['0deg', '45deg'],
        }),
      },
    ],
  };
  const userStyle = {
    transform: [
      {
        scale: animation,
      },
      {
        translateY: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, -70],
        }),
      },
    ],
  };

  return (
    // @ts-ignore
    <View style={styles.containerheader}>
      <Header title={'Relação de usuários'} />

      {loading ? <Loading /> : (
        <>
          <View
            style={[
              styles.card,
              {
                borderRadius: 8,
                marginLeft: 15,
                marginRight: 15,
                padding: 10,
                backgroundColor: '#fff',
              },
            ]}>
            <TextInput
              // @ts-ignore
              style={styles.inputBusca}
              placeholder="Pesquisar"
              value={search}
              onChangeText={text => searchFilterFunction(text)}
            />

            {loading ? (
              <View
                style={{ flex: 1, alignSelf: 'center', justifyContent: 'center', paddingVertical: 16 }}>
                <ActivityIndicator size={'small'} color={Colors.blueColor} />
              </View>
            ) : (
              <FlatList
                style={{ marginTop: 10, paddingHorizontal: 8 }}
                data={userData}
                // @ts-ignore
                keyExtractor={item => item.doc.toString()}
                renderItem={({ item }) => <ListItem data={item} />}
                onEndReachedThreshold={0.1}
                ListFooterComponentStyle={{
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                }}
                contentContainerStyle={{ paddingBottom: 20 }}
                ListEmptyComponent={RenderEmptyList}
              />
            )}

            {/* @ts-ignore */}
            <View style={styles.containerflutuantebotton}>
              <TouchableWithoutFeedback onPress={callRegister}>
                {/* <Text>Teste</Text> */}
                <Animated.View style={[styles.button, styles.submenu, userStyle]}>
                  <View style={{ flexDirection: 'row' }}>
                    {/* @ts-ignore */}
                    <Text style={styles.legend}>Adicionar Usuário</Text>
                    <FontAwesome5
                      name="user-plus"
                      size={24}
                      color={Colors.blueColor}
                      style={{ marginLeft: 30 }}
                    />
                  </View>
                </Animated.View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={toggleMenu}>
                <Animated.View style={[styles.button, styles.menu, rotation]}>
                  <AntDesign name="plus" size={24} color={Colors.inputWhite} />
                </Animated.View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </>
      )}
    </View>
  );
};

export default InformacoesUsuarios;
