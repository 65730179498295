import React, { useContext, useEffect, useState } from 'react';
import { View, Text, ScrollView, ActivityIndicator, Platform } from 'react-native';
import { Table, TableWrapper, Row, Cell } from 'react-native-table-component-2';
import { styles } from './styles';
import api from '../../services/api';
import DropDownPicker from 'react-native-dropdown-picker';
import { Colors, canGoToNextPage, canGoToPreviousPage } from '../../shared/utils';
import LoadingModal from '../LoadingModal';
import { Icon } from '../../shared/components';
import UserContext from '../../contexts/User/UserContext';
import { ROW_WIDTH, TABLE_HEAD } from './utils';

type Props = {
  text: string;
  handleChangeTemTouro: (value: boolean) => void;
}

const TableDadosGerais = ({ text, handleChangeTemTouro }: Props) => {
  const { user } = useContext(UserContext);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableTemp, setTableTemp] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tourosLogados, setTourosLogados] = useState<any[]>([]);
  const [admInfo, setAdmInfo] = useState<any[]>([]);
  const [temTouro, setTemTouro] = useState<boolean>(true);
  const [tableDataToShow, setTableDataToShow] = useState<any[]>([]);
  const [initialIndex, setInitialIndex] = useState<number>(0);
  const [finalIndex, setFinalIndex] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < totalPages; i++) {
      temp.push({ label: `${i + 1}`, value: `${i}` });
    }
    setDropDownItems(temp);
  }, [totalPages]);

  useEffect(() => {
    let paginaAtual = currentPage;

    let inicial = paginaAtual * 10;
    let final = inicial + 10;

    setInitialIndex(inicial);
    setFinalIndex(final);
  }, [currentPage]);

  const handleGoToFirstPage = () => {
    setCurrentPage(0);
  };

  const handleGoToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const handleGoToPreviousPage = () => {
    if (currentPage > 0) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage, 10) - 1);
    }
  };

  const handleGoToNextPage = () => {
    // @ts-ignore
    if (parseInt(currentPage, 10) + 1 < totalPages) {
      // @ts-ignore
      setCurrentPage(parseInt(currentPage, 10) + 1);
    }
  };

  useEffect(() => {
    if (tableData.length > 0) {
      if (tableData.length < 10) setTableDataToShow(tableData.slice(0, tableData.length));
      else setTableDataToShow(tableData.slice(initialIndex, finalIndex));
    }
  }, [tableData, initialIndex, finalIndex]);

  useEffect(() => {
    const vidaTouroContratosAdendos: any[] = [];

    // @ts-ignore
    if (admInfo === [undefined] || admInfo.length == 0) {
      return;
    }

    admInfo?.map((elemento: any) => {
      if (Array.isArray(elemento)) {
        elemento?.map((element: any) => {
          vidaTouroContratosAdendos.push([
            element?.codTou,
            element?.nomTou,
            element?.status,
            element?.datEntCen,
            element?.datEntCen,
            element?.datPreCol,
            element?.datIniCol,
            element?.datSaiCen,
          ]);
        });
      } else {
        vidaTouroContratosAdendos.push([
          elemento?.codTou,
          elemento?.nomTou,
          elemento?.status,
          elemento?.datEntCen,
          elemento?.datEntCen,
          elemento?.datPreCol,
          elemento?.datIniCol,
          elemento?.datSaiCen,
        ]);
      }
    });
    

    setTableData(vidaTouroContratosAdendos);
    setTableTemp(vidaTouroContratosAdendos);
    setTotalPages(Math.ceil(vidaTouroContratosAdendos.length / 10));
    setLoading(false);
  }, [admInfo]);

  useEffect(() => {
    if (!loading) return;

    async function fetchDataAdm() {
      const responseAllDocs = await api.get('/getAllUserDocs');
      const documento = responseAllDocs.data.allDocs;

      const array = documento.split(',');

      array.map(async (doc: any) => {
        const response = await api.post('/vidaTouroGeral', {
          codTouro: '',
          cnpjCpf: doc,
        });
        if (response.data.result != undefined) {
          setAdmInfo(prevState => [...prevState, response.data.result]);
        }
      });
    }
    async function fetchData() {
      const response = await api.post('/vidaTouroGeral', {
        codTouro: '',
        // @ts-ignore
        cnpjCpf: user.doc,
      });

      if (response.data.result !== undefined) {
        setAdmInfo(prevState => [...prevState, response.data.result]);
      } else {
        setLoading(false);
        setTemTouro(false);
      }
    }

    // @ts-ignore
    if (user.profile == 'Adm') {
      fetchDataAdm();
    } else {
      fetchData();
    }
  }, [tourosLogados]);

  useEffect(() => {
    if (tableTemp) {
    const filterData = tableTemp.filter(item => {
      return (
        item[1] && item[1].toString().toLowerCase().includes(text.toLowerCase()) ||
        item[0] && item[0].toString().toLowerCase().includes(text.toLowerCase())
      );
    });
    setTableData(filterData);
  }
  }, [text]);

  return (
    <View style={styles.container}>
      {loading ? (
        Platform.OS === 'web' ? (
          <ActivityIndicator size="small" color="#0000ff" />
        ) : (
          <LoadingModal loading={loading} />
        )
      ) : (
        <View style={styles.container}>
          {!temTouro ? (
            <View style={styles.container}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  color: '#000',
                  textAlign: 'center',
                  marginTop: 20,
                }}>
                Nenhum resultado encontrado
              </Text>
            </View>
          ) : null}
        </View>
      )}
      {tableData.length == 0 ? null : (
        <>
          <ScrollView horizontal={true}>
            <View>
              {/* @ts-ignore */}
              <Table borderStyle={{ borderWidth: 0, borderColor: '#C1C0B9' }}>
                <Row
                  data={TABLE_HEAD}
                  widthArr={ROW_WIDTH}
                  style={styles.header}
                  textStyle={styles.textHeaderTable}
                />
              </Table>
              <ScrollView style={styles.dataWrapper}>
                {/* @ts-ignore */}
                <Table
                  borderStyle={{
                    borderWidth: 1,
                    borderColor: '#C1C0B9',
                  }}>
                  {tableDataToShow?.map((rowData: any[], index: number) => (
                    // @ts-ignore
                    <TableWrapper
                      key={index}
                      style={styles.row}
                      textStyle={{ color: Colors.inputWhite }}>
                      <Row
                        key={index}
                        // @ts-ignore
                        data={rowData?.map((cellData: any[], cellIndex: number) => (
                          <Cell key={cellIndex} data={cellData} textStyle={styles.text} />
                        ))}
                        widthArr={ROW_WIDTH}
                        // @ts-ignore
                        style={[styles.row, index % 2 && { backgroundColor: Colors.inputWhite }]}
                        textStyle={styles.text}
                      />
                    </TableWrapper>
                  ))}
                </Table>
              </ScrollView>
            </View>
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <Icon
              name="fast-rewind"
              disabled={!canGoToPreviousPage(currentPage + 1)}
              onPress={handleGoToFirstPage}
            />
            <Icon
              name="chevron-left"
              disabled={!canGoToPreviousPage(currentPage + 1)}
              onPress={handleGoToPreviousPage}
            />
            {/* @ts-ignore */}
            <Text style={styles.pageNumber}>{`${parseInt(currentPage, 10) + 1}/${totalPages}`}</Text>
            <Icon
              name="chevron-right"
              disabled={!canGoToNextPage(currentPage + 1, totalPages)}
              onPress={handleGoToNextPage}
            />
            <Icon
              name="fast-forward"
              disabled={!canGoToNextPage(currentPage + 1, totalPages)}
              onPress={handleGoToLastPage}
            />
            <DropDownPicker
              items={dropDownItems}
              open={open}
              setOpen={setOpen}
              setItems={setDropDownItems}
              value={currentPage}
              setValue={setCurrentPage}
              defaultIndex={1}
              containerStyle={{ height: 50, width: 90 }}
              style={{ backgroundColor: '#fff', borderColor: '#fff' }}
              // @ts-ignore
              placeholder={currentPage + 1}
              dropDownDirection="TOP"
              listMode="SCROLLVIEW"
              itemStyle={{
                justifyContent: 'flex-start',
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                paddingRight: 10,
                fontSize: 20,
                color: '#000',
                backgroundColor: '#fafafa',
              }}
              dropDownStyle={{ backgroundColor: '#fafafa' }}
            />
          </View>
        </>
      )}
    </View>
  );
};

export default TableDadosGerais;
