import React, { useContext, useState } from "react";
import {
  View,
  SafeAreaView,
  TextInput,
} from "react-native";
import { styles } from "./styles";
import Header from "../../components/headerscreens";

import TableDespesas from "../../components/tableDespesas";

import { Colors } from '../../shared/utils';
import UserContext from "../../contexts/User/UserContext";

const InformacoesDespesas = () => {
  const { user } = useContext(UserContext);
  const [docDonoTouro, setDocDonoTouro] = useState("");

  return (
    <View style={styles.container}>
      <Header title={"Despesas"} />
      <SafeAreaView style={styles.safeContainer}>
        <View style={styles.card}>
          <TableDespesas text={docDonoTouro} />
        </View>
      </SafeAreaView>
    </View>
  );
};

export default InformacoesDespesas;
