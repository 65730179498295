import React, { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  ScrollView,
  SafeAreaView,
} from "react-native";
import { styles } from "./styles";

import TableDadosGerais from "../../components/tableDadosGerais";
import BullInformationContext from "../../services/bullInformationContext";
import FotosTouro from "../../components/fotosDoTouro";

import Header from "../../components/headerscreens";

type Props = {
  navigation: any
}

const DadosGeraisTouro = ({ navigation }: Props) => {
  const [nomeTouro, setNomeTouro] = useState<string>("");
  const [bullInformation, setBullInformation] = useContext(
    BullInformationContext
  );

  const [temTouro, setTemTouro] = useState(true);
  const handleChangeTemTouro = (value: boolean) => {
    setTemTouro(value);
  };

  // @ts-ignore
  const { codTou, nomTou, dtNasc } = bullInformation;

  return (
    <View style={styles.containerheader}>
      <Header title={"Dados Gerais"} />
      <SafeAreaView style={styles.safeContainer}>
        <View style={styles.card}>
          {/* <Card containerStyle={styles.card}> */}

          {/* @ts-ignore */}
          {bullInformation.codTou ? (
            <FotosTouro codTou={codTou} nomTou={nomTou} navigation={navigation} />
          )
            : temTouro ? (
              <>
                <TextInput
                  style={styles.inputBusca}
                  placeholder="Pesquisar"
                  value={nomeTouro}
                  onChangeText={(text) => setNomeTouro(text)}
                />

                <ScrollView>
                  <TableDadosGerais
                    text={nomeTouro}
                    handleChangeTemTouro={handleChangeTemTouro}
                  />
                </ScrollView>
              </>
            ) : (
              <View style={styles.textsContainer}>
                <Text style={styles.title}>
                  Você não possui touros cadastrados
                </Text>
              </View>
            )}

          {/* </Card> */}
        </View>

      </SafeAreaView>
    </View>
  );
};

export default DadosGeraisTouro;
