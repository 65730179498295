import React from "react";
import { View, Dimensions, Image } from "react-native";
import Swiper from "react-native-web-swiper";
import Header from "../../components/headerscreens";

const { height, width } = Dimensions.get("window");
const ITEM_LENGTH = width  // Item is a square. Therefore, its height and width are of the same length.

type SwiperItemProps = {
  url: string;
}

type PicCarouselProps = {
  navigation: any;
  route: any;
}

const SwiperItem = ({ url }: SwiperItemProps) => (
  <View style={{ flex: 1, alignItems: 'center' }}>
    <Image
      style={{
        width: ITEM_LENGTH,
        height: height - 200,
        maxWidth: ITEM_LENGTH,
        maxHeight: height,
        resizeMode: 'contain',
      }}
      source={{ uri: url }}
    />
  </View>
);

const PicCarousel = ({ navigation, route }: PicCarouselProps) => {
  const { picArray, index } = route.params;
  return (
    <>
      <Header title={"Fotos do Touro"} />

      <View style={{
        flex: 1,
        marginTop: 20,
      }}>
        <View style={{
          flex: 1,
        }}>
          {/* @ts-ignore */}
          <Swiper
            index={1}
            controlsProps={{
              prevTitle: "ANTERIOR",
              nextTitle: 'PRÓXIMO',
              prevPos: 'left',
              nextPos: 'right',
            }}
            from={index}
          >
            {/* @ts-ignore */}
            {picArray.map((pic, index) => (
              <SwiperItem url={pic.link} key={pic.id} />
            ))}
          </Swiper>
        </View>

      </View>
    </>
  );
}


export default PicCarousel;