import React from "react";
import { styles } from "./styles";
import { Text, TouchableWithoutFeedback, View } from "react-native";

type Props = {
  data: any;
  handleSelectTouro: (newData: any) => void;
}

const Bull = ({ data, handleSelectTouro }: Props) => {
  const onPress = (bull: any) => {
    const newData = {
      codTou: bull.codTou,
      nomTou: bull.nomTou,
    };
    handleSelectTouro(newData);
  };

  return data?.codTou === "" ? null : (
    <View style={styles.listItem}>
      <Text style={styles.listItemNome}>{!data?.nomTou ? '' : data.nomTou.toString().toUpperCase()}</Text>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1, marginTop: 5, }}>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.listItemTextLeft}>Código: </Text>
            <Text style={styles.listItemTextRight}>{data?.codTou ?? ''}</Text>
          </View>
          <View>
            <TouchableWithoutFeedback onPress={() => onPress(data)}>
              <Text style={styles.listItemEditar}>VISUALIZAR</Text>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    </View>
  );
}

export default Bull;