import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

import { styles } from './styles';

type Props = {
  children: any;
  onPress: () => void;
  disabled: boolean;
  style?: object;
}

const Button = ({ children, onPress, disabled = false, style }: Props) => {
  const containerStyle = StyleSheet.flatten([
    disabled ? styles.disabledContainer : styles.container,
    style
  ]);

  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={onPress}
      disabled={disabled}
    >
      <Text style={styles.content}>{children}</Text>
    </TouchableOpacity>
  );
};

export default Button;
