import { Platform, StyleSheet } from "react-native";
// @ts-ignore
import { vh } from "react-native-expo-viewport-units";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.maincolor
  },
  container: {
    height: vh(100),
    backgroundColor: Colors.maincolor,
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",

    ...Platform.select({
      web: {
        marginTop: 24,
        marginLeft: 32,
        marginRight: 32,
        marginBottom: 32,
        backgroundColor: Colors.inputWhite,
        borderRadius: 16,
        height: vh(60),
        width: "100%",
        display: "flex",
        paddingLeft: 50,
      },
      default: {},
    }),
  },
  upperImage: {
    width: "100%",
    height: 500,
    "@media (max-width: 540px)": {
      width: 300,
      height: 300,
    },
  },

  upperImageBack: {
    ...Platform.select({
      web: {
        width: "100%",
        height: 400,
        "@media (max-width: 540px)": {
          width: 300,
          height: 300,
        },
      },
      default: {
        width: "100%",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        height: 120,
      },
    }),
  },

  imageViewContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  content: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#fff",
  },

  textsContainer: {
    ...Platform.select({
      web: {
        marginTop: 32,
      },
      default: { marginTop: 120 },
    }),
  },

  mainText: {
    fontWeight: "bold",
    color: Colors.color1,
    marginBottom: 16,
    textAlign: "center",
    ...Platform.select({
      web: {
        fontSize: 24,
      },
      default: { fontSize: 14, padding: 16 },
    }),
  },

  secundaryText: {
    color: Colors.greyColor,
    marginBottom: 16,
    fontFamily: "Poppins_400Regular",
    textAlign: "center",
    ...Platform.select({
      web: {
        fontSize: 24,
      },
      default: { fontSize: 14, paddingLeft: 16, paddingRight: 16 },
    }),
  },

  title: {
    color: Colors.greyColor,
    fontFamily: "Poppins_400Regular",
    marginBottom: 16,
    textAlign: "center",
    ...Platform.select({
      web: {
        fontSize: 32,
      },
      default: { fontSize: 18 },
    }),
  },
  floatingButton: {
    backgroundColor: "#fff",
  },
  touchableOpacityStyle: {
    position: "absolute",
    width: 70,
    height: 70,
    alignItems: "center",
    justifyContent: "center",
    bottom: 24,
    padding: 5,
    shadowColor: "#000",

    shadowOpacity: 0.4,
    shadowRadius: 40.0,

    backgroundColor: "#fff",
    borderRadius: 100,
    opacity: 1,
    ...Platform.select({
      web: {
        left: 500,
      },
      default: {
        left: 100,
      },
    }),
  },
  floatingButtonStyle: {
    resizeMode: "contain",
    width: 28,
    height: 28,
  },

  card: {
    flex: 3,
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 0,
    elevation: 1,
    flexDirection: "column",
  },
});
