import React from 'react';
import { default as VIcon } from 'react-native-vector-icons/MaterialIcons';

type Props = {
  name: string;
  onPress: () => void;
  size?: number;
  color?: string;
  disabled?: boolean;
}

const Icon = ({ name, size = 30, color = "#7159c1", onPress, disabled = false }: Props) => {
  return (
    <VIcon
      name={name}
      size={size}
      color={!disabled ? color : "#D3D3D3"}
      onPress={!disabled ? onPress : () => { }}
    />
  )
}

export default Icon;