import { Platform } from 'react-native';
// @ts-ignore
import { vw, vh } from 'react-native-expo-viewport-units';
import StyleSheet from 'react-native-media-query';

import { Colors } from '../../shared/utils';

export const { ids, styles } = StyleSheet.create({
  container: {
    height: vh(100),
    backgroundColor: Colors.maincolor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  contentContainer: {
    width: vw(80),
    display: 'flex',
    flexDirection: 'column',
  },
  textsContainer: {
    width: '100%',
  },
  content: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#fff',
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    color: Colors.color1,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  mainText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: Colors.color1,
    marginTop: 16,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  inputBusca: {
    width: '100%',
    height: vh(5),
    borderRadius: 10,
    padding: 10,
    color: Colors.inputWhite,
    marginTop: vh(5),
    backgroundColor: 'rgba(0,0,0,0.5)',
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  tableContainer: {
  },
  backButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    borderWidth: 1,
    borderColor: '#fff',
    marginTop: 20,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    borderColor: '#fff',
  },
  dateTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  searchButton: {},
  dateText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.color1,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  dateInput: {
    width: '100%',
    borderRadius: 10,
    padding: 10,
    color: Colors.inputWhite,
    backgroundColor: 'rgba(0,0,0,0.5)',
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  containerheader: {
    flex: 1,
    backgroundColor: Colors.maincolor,
    paddingBottom: 20,
  },
  safeContainer: {
    flex: 1,
    paddingBottom: 20,
    backgroundColor: Colors.maincolor,

    flexDirection: 'column',

    ...Platform.select({
      web: {
        '@media (max-width: 768px)': {
          width: '30%',
        },
        '@media (max-width: 1024px)': {
          width: '50%',
        },
        '@media (minWidth: 1280px)': {
          width: '100%',
        },
      },
      default: {},
    }),
  },
  card: {
    marginTop: 15,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    height: '100%',
    flex: 1,
  },
  cardView: {
    marginTop: 15,
    borderRadius: 8,

    backgroundColor: '#fff',
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
    padding: 10,
  },
});
