import React from "react";
import {
  View,
  SafeAreaView
} from "react-native";

import { styles } from "./styles";
import Header from "../../components/headerscreens";
import TableEstoque from "../../components/tableEstoque";

const InformacoesEstoque = () => {
  return (
    <View style={styles.container}>
      <Header title={"Movimentação de Estoque"} />
      <SafeAreaView style={styles.safeContainer}>
        <View style={styles.card}>
          <TableEstoque text="" />
        </View>
      </SafeAreaView>
    </View>
  );
};

export default InformacoesEstoque;
