import { useContext, useState, useEffect, createElement, useCallback } from 'react';
import { Platform, View, SafeAreaView, Text, TouchableOpacity, ScrollView } from 'react-native';
import DatePicker from 'react-native-modern-datepicker';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import Feather from 'react-native-vector-icons/Feather';

import { styles } from './styles';
import Header from '../../components/headerscreens';
import { Colors } from '../../shared/utils';
import TableRoyalties from '../../components/tableRoyalties';

const currentDate = new Date();

type Props = {
  navigation: any
}

const InformacoesRoyalties = ({ navigation }: Props) => {
  const [docDonoTouro, setDocDonoTouro] = useState('');
  const [dateInicial, setDateInicial] = useState('');
  const [dateProps, setDateProps] = useState('');
  const [tableFlag, setTableFlag] = useState(false);
  const [dateToShow, setDateToShow] = useState('');
  const [calendarFlag, setCalendarFlag] = useState(false);

  const handleChangeInitialDate = useCallback(
    (value: any) => {
      const date = Platform.OS === 'web' ? value.target.value : value;

      setTableFlag(false);
      //insert day 01 in the middle of the date
      if (Platform.OS === 'web') {
        const dateSplited = date.split('-');
        //change yyyy-mm-dd to mm/yyyy
        const dateToShow = dateSplited[1] + '/' + dateSplited[0];
        setDateToShow(dateToShow);

        setDateInicial(date);
        setDateProps(dateSplited);

        const today = new Date();
        const todayString =
          today.getFullYear() +
          '-' +
          (today.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          today.getDate().toString().padStart(2, '0');

        if (date > todayString) {
          Toast.show({
            type: 'error',
            position: 'top',
            text1: 'Data maior que data atual',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
          });

          setDateInicial('');
        }

        if (date < '2021-01-01' && date !== '') {
          Toast.show({
            type: 'error',
            position: 'top',
            text1: 'Data menor que Jan/2021',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
          });

          setDateInicial('');
        }
      } else {
        setTableFlag(false);
        setCalendarFlag(false);
        const dateSplited = date.split(' ');
        const dateToShow = dateSplited[1] + '/' + dateSplited[0];
        const dateWithDay = dateSplited[0] + '-' + '01' + '-' + dateSplited[1];
        setDateToShow(dateToShow);
        setDateInicial(date);
        setDateProps(dateSplited);

        const today = new Date();
        const todayString =
          today.getFullYear() +
          '-' +
          (today.getMonth() + 1).toString().padStart(2, '0') +
          '-' +
          today.getDate().toString().padStart(2, '0');

        if (dateWithDay > todayString) {
          Toast.show({
            type: 'error',
            position: 'top',
            text1: 'Data maior que data atual',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
          });

          setDateInicial('');
        }

        if (dateWithDay < '2021-01-01' && date !== '') {
          Toast.show({
            type: 'error',
            position: 'top',
            text1: 'Data menor que Jan/2021',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
          });

          setDateInicial('');
        }
      }
    },
    [dateToShow],
  );

  useEffect(() => {
    //unsubscribe
    const unsubscribe = navigation.addListener('focus', () => {
      setTableFlag(false);
      setCalendarFlag(false);
      setDateInicial('');
      setDocDonoTouro('');
    });

    return unsubscribe;
  }, []);

  const handleSearch = async () => {
    if (dateInicial === '') {
      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Preencha as datas',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    } else {
      setTableFlag(true);
    }
  };

  return (
    <View style={styles.containerheader}>
      <Header title="Royalties" />
      <SafeAreaView style={styles.safeContainer}>
        <View style={styles.cardView}>
          <View style={styles.textsContainer}>
            <View style={styles.dateContainer}>
              <View style={styles.dateTextContainer}>
                <View style={styles.dateTextContainer} />
                <View style={styles.dateTextContainer}>
                  {Platform.OS === 'web' ? (
                    <View>
                      <View>
                        {createElement('input', {
                          type: 'month',
                          max: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`,
                          min: `2021-01`,
                          onChange: handleChangeInitialDate,
                          value: dateInicial,
                          style: {
                            width: 200,
                            height: 40,
                            // @ts-ignore
                            backgroundColor: Colors.white,
                            borderRadius: 10,
                            paddingLeft: 10,
                            fontSize: 16,
                            // @ts-ignore
                            color: Colors.black,
                            marginTop: 10,
                            marginBottom: 10,
                            marginRight: 5,
                            // @ts-ignore
                            borderColor: Colors.black,
                            borderWidth: 1,
                          },
                        })}
                      </View>
                    </View>
                  ) : (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      {calendarFlag ? (
                        <DatePicker
                          mode="monthYear"
                          selectorStartingYear={2021}
                          maximumDate={`${currentDate.getFullYear()}-${currentDate.getMonth() + 1
                            }-${currentDate.getDate() + 1}`}
                          onMonthYearChange={handleChangeInitialDate}
                        />
                      ) : (
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}>
                          {dateInicial === '' ? null : (
                            <View>
                              <Text
                                style={{
                                  fontSize: 18,
                                  fontWeight: 'bold',
                                  // @ts-ignore
                                  color: Colors.primary,
                                  marginRight: 5,
                                }}>
                                {dateToShow}
                              </Text>
                            </View>
                          )}
                          <Feather
                            name="calendar"
                            size={20}
                            // @ts-ignore
                            color={Colors.primary}
                            onPress={() => {
                              setTableFlag(false);
                              setCalendarFlag(true);
                            }}
                          />
                        </View>
                      )}
                    </View>
                  )}
                </View>
                <View>
                  {Platform.OS === 'web' ? (
                    <TouchableOpacity onPress={handleSearch}>
                      <Feather name="search" size={30} color="black" style={{ marginLeft: 10 }} />
                    </TouchableOpacity>
                  ) : calendarFlag ? null : (
                    <TouchableOpacity onPress={handleSearch}>
                      <Feather name="search" size={30} color="black" style={{ marginLeft: 10 }} />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </View>
            <Text style={{ fontFamily: 'Hind_400Regular', marginTop: 20 }}>
              *Relatório de royalties anteriores à Janeiro de 2021, não estarão disponíveis na
              plataforma
            </Text>
          </View>
          {tableFlag ? (
            <TableRoyalties
              dateInicial={dateProps}
              dateFinal={dateProps}
            />
          ) : null}
        </View>
      </SafeAreaView>
    </View>
  );
};

export default InformacoesRoyalties;
