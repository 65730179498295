import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TextInput, ActivityIndicator, Modal, TouchableOpacity } from 'react-native';
import { styles } from './styles';
import Header from '../../components/headerscreens';
import { Platform } from 'react-native';
import SelectList from 'react-native-dropdown-select-list';
import api from '../../services/api';
import Toast from 'react-native-toast-message';
import { FontAwesome } from '@expo/vector-icons';
import { Card } from 'react-native-elements';
import Button from '../../components/button';
import ButtonClean from '../../components/buttonclean';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Colors } from '../../shared/utils';
import LoadingModal from '../../components/LoadingModal';

type Props = {
  navigation: any;
  route: any;
}

const EditUser = ({ navigation, route }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [doc, setDoc] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [profile, setProfile] = useState<string>('');
  const [active, setActive] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [data, setData] = useState<any[]>([
    { key: 'Adm', value: 'Administrador' },
    { key: 'Comum', value: 'Usuário' },
    { key: "Tairana", value: "Tairana" }
  ]);

  const getUser = useCallback(async () => {
    setLoading(true);
    const { documento } = route.params;

    try {
      const user = await api.post('/userdoc', {
        doc: documento,
      });

      setEmail(user.data.resp[1].email);
      setName(user.data.resp[1].name);
      setDoc(user.data.resp[1].doc);
      setPhone(user.data.resp[1].phone);
      setProfile(user.data.resp[1].profile);
      setActive(user.data.resp[1].active);
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Usuário não encontrado!',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  const handleSubmit = async () => {
    const response = await api.put('/editUser', {
      email,
      name,
      doc,
      phone,
      profile: profile === "Tairana" ? "Adm" : profile,
      isTairana: profile === "Tairana"
    });

    if (profile !== 'Adm' && profile !== 'Comum' && profile !== 'Tairana') {
      Toast.show({
        type: 'error',
        text1: 'Erro',
        text2: 'Preencha o campo perfil',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      return null;
    }

    if (response.data.error == false) {
      Toast.show({
        type: 'success',
        text1: 'Usuário editado com sucesso!',
        text2: '',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });
      navigation.navigate('InformacoesUsuarios', {
        refresh: true,
      });
    } else {
      Toast.show({
        type: 'error',
        text1: 'Erro ao editar usuário!',
        text2: '',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });
    }
  };

  const handleEnableUser = async () => {
    setLoading(true);
    try {
      await api.post('/users/enable', {
        email,
        cnpjCpf: doc,
      });

      Toast.show({
        type: 'success',
        text1: 'Usuário ativado com sucesso!',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });

      await getUser();
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Ocorreu um erro ao ativar o usuário!',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    const response = await api.post('deleteUser', {
      doc,
    });

    if (response.data.error == false) {
      Toast.show({
        type: 'success',
        text1: 'Usuário deletado com sucesso!',
        text2: '',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });
      navigation.navigate('InformacoesUsuarios', {
        refresh: true,
      });
    } else {
      Toast.show({
        type: 'error',
        text1: 'Erro ao deletar usuário!',
        text2: '',
        // @ts-ignore
        visible: true,
        duration: 3000,
        animation: 'fade',
        hideOnPress: true,
        closeButton: true,
        closeOnPress: true,
        closeOnDoublePress: true,
      });
    }
  };

  return (
    <View style={styles.containerheader}>
      {Platform.OS !== 'web' && <LoadingModal loading={loading} />}
      <Header title={'Edição de Usuários'} />
      {/* @ts-ignore */}
      <Card containerStyle={styles.card}>
        {loading && Platform.OS === 'web' ? (
          <ActivityIndicator size="large" color="#0000ff" />
        ) : null}
        <View style={styles.inputTitleContainer}>
          <Modal
            animationType="slide"
            visible={modalVisible}
            transparent={true}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
            style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalText}>Deseja realmente excluir o usuário?</Text>
              <View style={styles.modalButtons}>
                <TouchableOpacity
                  style={styles.modalButton}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}>
                  <Icon name="close" size={30} color="red" />
                  <Text>Não</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.modalButton}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    handleDeleteUser();
                  }}>
                  <Icon name="delete" size={30} color="green" />
                  <Text>Sim</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.mainText}>CPF/CNPJ</Text>
              <TextInput
                style={styles.inputBusca}
                placeholder="CPF/CNPJ"
                placeholderTextColor="white"
                value={doc}
                onChangeText={text => setDoc(text)}
              />
            </View>
            <View style={{ marginRight: 32 }}>
              <Text style={styles.mainText}>Ativo</Text>
              <Text style={{ marginTop: 4 }}>{active ? 'Sim' : active === false ? 'Não' : '-'}</Text>
            </View>
          </View>
        </View>
        <View style={styles.inputTitleContainer}>
          <Text style={styles.mainText}>E-mail</Text>
          <TextInput
            style={styles.inputBusca}
            placeholder="Email"
            placeholderTextColor="white"
            value={email}
            onChangeText={text => setEmail(text)}
          />
        </View>
        <View style={styles.inputTitleContainer}>
          <Text style={styles.mainText}>Nome</Text>
          <TextInput
            style={styles.inputBusca}
            placeholder="Nome"
            placeholderTextColor="white"
            value={name}
            onChangeText={text => setName(text)}
          />
        </View>

        <View style={styles.inputTitleContainer}>
          <Text style={styles.mainText}>Telefone</Text>
          <TextInput
            style={styles.inputBusca}
            placeholder="Telefone"
            placeholderTextColor="white"
            value={phone}
            onChangeText={text => setPhone(text)}
          />
        </View>

        <View style={styles.inputTitleContainer}>
          <Text style={styles.perfilText}>Perfil</Text>
          {/* @ts-ignore */}
          <SelectList
            onSelect={() => setProfile(selected)}
            placeholder={profile}
            setSelected={setSelected}
            data={data}
            arrowicon={<FontAwesome name="chevron-down" size={10} color={Colors.greyColor} />}
            search={false}
            boxStyles={{
              fontSize: 10,
              borderWidth: 1,
              borderColor: Colors.greyColor,
            }}
            inputStyles={{
              color: Colors.greyColor,
              fontFamily: 'Hind_400Regular',
              marginRight: 5,
              fontSize: 15,
            }} //override default styles
            dropdownTextStyles={{
              color: Colors.greyColor,
              fontSize: 15,
              fontFamily: 'Hind_400Regular',
            }} //override default styles
            dropdownStyles={{
              width: 150,
              fontSize: 15,
              borderWidth: 0,
              borderColor: Colors.greyColor,
            }}
          />
        </View>

        <View>
          <ButtonClean
            onPress={() =>
              navigation.navigate('InformacoesUsuarios', {
                refresh: true,
              })
            }
          >
            <Text>CANCELAR</Text>
          </ButtonClean>
          {/* @ts-ignore */}
          <Button
            onPress={() => handleSubmit()}
          >
            <Text>SALVAR</Text>
          </Button>
          {active === false && (
            // @ts-ignore
            <Button
              onPress={() => handleEnableUser()}
            >
              <Text>ATIVAR USUÁRIO</Text>
            </Button>
          )}
          {/* @ts-ignore */}
          <Button
            onPress={() => setModalVisible(true)}
          >
            <Text>EXCLUIR</Text>
          </Button>
        </View>
      </Card >
    </View >
  );
};

export default EditUser;
