import React from "react";
import { ListItem, Avatar } from "react-native-elements";
import { Colors } from "../../shared/utils";

type Props = {
  id: any;
  chatName: any;
  chatEmail: any;
  enterChat: any;
  aColor: any;
  status: any;
}

const CustomListItem = ({
  id,
  chatName,
  chatEmail,
  enterChat,
  aColor,
  status,
}: Props) => {
  return (
    <ListItem
      onPress={() => enterChat(id, chatEmail, status)}
      key={id}
      bottomDivider
    >
      <ListItem.Content>
        <ListItem.Title
          style={{
            fontFamily: "Poppins_400Regular",
            color: Colors.greyColor,
            fontSize: 18,
          }}
        >
          {chatName}
        </ListItem.Title>
      </ListItem.Content>
      {aColor && (
        <Avatar
          rounded
          containerStyle={{
            backgroundColor: aColor,
          }}
          size={22}
        />
      )}
    </ListItem>
  );
};
export default CustomListItem;
