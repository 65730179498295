export const Colors = {
  maincolor: "#F5F5F0",

  color1: "#000",
  inputWhite: "#fff",
  buttonColor: "#dc3545",
  textButtonColor: "#fff",
  iconColor: "black",
  blueColor: "#2C2967",

  greyColor: "#737375",
  lightGreyColor: "#70707066",
};