import { Platform, StyleSheet } from 'react-native';
// @ts-ignore
import { vw, vh } from 'react-native-expo-viewport-units';
import { Colors } from '../../shared/utils';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  header: {
    height: 40,
    backgroundColor: Colors.blueColor,
    marginTop: 24,
  },
  text: { textAlign: 'center', fontWeight: '100' },
  dataWrapper: {
    marginTop: -1
  },
  row: { height: 40, backgroundColor: Colors.inputWhite },
  head: {
    backgroundColor: '#808B97',
    width: vw(60),
  },
  btn: {
    backgroundColor: Colors.maincolor,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
  },

  btnText: {
    textAlign: 'center',
    color: Colors.blueColor,
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Hind_400Regular',
    textDecorationLine: 'underline',
    fontSize: 16,
  },

  btnIcon: {
    color: Colors.blueColor,
    marginLeft: 4,
    alignSelf: 'center',
  },

  textsContainer: {
    width: "100%",
  },

  inputBusca: {
    width: "100%",
    borderRadius: 5,
    borderColor: "#707070",
    padding: 10,
    borderWidth: 1,
    marginBottom: 24,
    alignSelf: "center",
    backgroundColor: Colors.inputWhite,
    ...Platform.select({
      web: {
        "@media (minWidth: 768px)": {
          width: "50%",
        },
      },
      default: {},
    }),
  },

  containermodal: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff',
    width: vw(35),
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textmodal: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#537791',
    marginTop: vh(2),
    marginBottom: vh(2),
    marginRight: 20,
    // @ts-ignore
    alignSelf: 'left',
  },

  fecharmodal: {
    fontSize: 25,
    fontWeight: 'bold',
    color: Colors.maincolor,
    // @ts-ignore
    alignSelf: 'right',
  },

  headmodal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },

  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },

  modalContent: {
    backgroundColor: Colors.inputWhite,
    borderRadius: 10,
    height: Platform.OS === 'web' ? '80%' : '80%',
    width: Platform.OS === 'web' ? '50%' : '90%',
    alignItems: 'center',
  },

  modalText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
    marginTop: 8,
    marginBottom: 15,
  },

  modalButton: {
    width: '40%',
    height: 40,
    borderRadius: 5,
    marginTop: 8,
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 8,
  },

  textHeaderTable: {
    textAlign: 'center',
    fontWeight: '100',
    color: '#fff',
    fontFamily: 'Hind_400Regular',
  },

  closeModal: {
    fontSize: 25,
    textDecorationLine: 'underline',
    alignSelf: 'center',
    fontFamily: 'Hind_700Bold',
    color: Colors.blueColor,
  },
});
