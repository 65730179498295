import React, { useState } from "react";

import {
  SafeAreaView,
  View,
  Text,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  ImageBackground,
} from "react-native";
import { styles, ids } from "./styles";
import Button from "../../components/button";
import Input from "../../components/input";
import logo from "../../assets/logo.png";
import smallLogo from "../../assets/logomobile.png";
import api from "../../services/api";
import Toast from "react-native-toast-message";
import * as yup from "yup";

import logobackground from "../../assets/logobackground.jpeg";

type Props = {
  navigation: any;
}

const AlterarSenha = ({ navigation }: Props) => {
  const [email, setLogin] = useState<string>("");
  const [codigo, setCodigo] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [emailFlag, setEmailFlag] = useState<boolean>(false);
  const [passwordFlag, setPasswordFlag] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false)

  const schema = yup.object().shape({
    confirmPassword: yup
      .string()
      .min(6, "Senha muito curta")
      .max(20, "Senha muito longa")
      .matches(/[a-z]/, "Senha precisa ter pelo menos uma letra minúscula")
      .matches(/[A-Z]/, "Senha precisa ter pelo menos uma letra maiúscula")
      .matches(/[0-9]/, "Senha precisa ter pelo menos um número")
      .required("Senha é obrigatória"),
    password: yup
      .string()
      .min(6, "Senha muito curta")
      .max(20, "Senha muito longa")
      .matches(/[a-z]/, "Senha precisa ter pelo menos uma letra minúscula")
      .matches(/[A-Z]/, "Senha precisa ter pelo menos uma letra maiúscula")
      .matches(/[0-9]/, "Senha precisa ter pelo menos um número")
      .required("Senha é obrigatória"),
  });

  const handleEmailClick = async () => {
    try {
      if (clicked) return
      setClicked(true)
      const isValid = await api.post("/resetPassword", {
        email,
      });

      if (isValid.data.error) {
        Toast.show({
          type: "error",
          position: "top",
          text1: "E-mail não cadastrado",
          autoHide: true,
          visibilityTime: 3000,
        });
      } else {
        Toast.show({
          type: "success",
          position: "top",
          text1: "E-mail enviado com sucesso",
          text2: "Redirecionando para o login",
          autoHide: true,
          visibilityTime: 3000,
        });
        navigation.navigate("Login");
      }
    } catch (error) {
      Toast.show({
        type: "error",
        position: "top",
        // @ts-ignore
        text1: error.message,
        autoHide: true,
        visibilityTime: 3000,
      });
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <ScrollView>
        <SafeAreaView style={styles.container}>
          <View style={styles.loginContainer}>
            <View style={styles.content} dataSet={{ media: ids.content }}>
              <View style={styles.animationContainer}>
                <View
                  style={styles.smallLogoContainer}
                  dataSet={{ media: ids.smallLogoContainer }}
                >
                  <Image
                    source={smallLogo}
                    resizeMode="contain"
                    style={styles.smallLogo}
                    dataSet={{ media: ids.smallLogo }}
                  />
                </View>

                <View style={styles.inputTitleContainer}>
                  <Text style={styles.titleBemvindo}>Bem vindo</Text>
                  <Text style={styles.title}>A Vida do Touro</Text>
                </View>

                <ImageBackground
                  source={logobackground}
                  resizeMode="contain"
                  style={styles.logobackground}
                >
                  <View>
                    <Text style={styles.titleRecuperar}>Recuperar Senha</Text>
                    <Text style={styles.titleCodigo}>
                      Informe seu e-mail para receber o código
                    </Text>
                    <Text style={styles.titleCodigorec}>de recuperação </Text>

                    <Input
                      placeholder="Email"
                      value={email}
                      onChangeText={(text) => setLogin(text)}
                      keyboardType="email-address"
                      autoCapitalize="none"
                      style={styles.input}
                    />

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: 300,
                        borderRadius: 8,
                      }}
                    ></View>

                    <Button
                      onPress={handleEmailClick}
                      // @ts-ignore
                      style={styles.loginButton}
                    >
                      ENTRAR
                    </Button>
                  </View>
                </ImageBackground>
              </View>
            </View>
          </View>
          <View
            style={styles.logoContainer}
            dataSet={{ media: ids.logoContainer }}
          >
            <Image
              style={styles.logo}
              dataSet={{ media: ids.logo }}
              source={logo}
            />
          </View>
        </SafeAreaView>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default AlterarSenha;
