import { Dimensions, Platform } from "react-native";

const width = Dimensions.get('window');

export const TABLE_HEAD = ['Nome', 'Nome de guerra', 'Partida', 'Quantidade'];

export const ROW_WIDTH = Platform.OS !== 'web' ?
  [150, 300, 90, 80]
  : width.width < 768 ?
    [150, 300, 90, 80]
    : [
      width.width / 7.5,
      width.width / 2,
      width.width / 7,
      width.width / 10,
    ]