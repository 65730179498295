import { StorageReference, getStorage, ref, uploadBytes } from 'firebase/storage'
import api from '../services/api'

export async function uploadChatFiles(userId: string, fileUri: string, fileName: string) {
  const blob = await new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.onload = function () {
      resolve(xhr.response as Blob)
    }

    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };

    xhr.responseType = "blob";

    xhr.open("GET", fileUri, true);

    xhr.send(null);
  });

  const storageRef: StorageReference = ref(getStorage(), `/documentsChat/${userId}/${fileName}`);

  try {
    await api.post('/addDataChat', {
      userId: userId,
      fileId: fileName,
    });

    await uploadBytes(storageRef, blob);
  } catch (e) {
    console.error(e)
  }
};