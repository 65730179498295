import { StyleSheet } from "react-native";
import { Colors } from "../../shared/utils";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.inputWhite,
    height: 44,
    border: 10,
    color: Colors.greyColor,
    marginTop: 16,
    borderRadius: 10,
    // padding: 16,
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    borderWidth: 1,
    justifyContent: "center",
  },

  content: {
    fontFamily: "Hind_700Bold",
    fontSize: 16,
    color: Colors.greyColor,
  },
});
