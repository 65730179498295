import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  FlatList,
  ActivityIndicator,
} from "react-native";
import { styles } from "./styles";
import api from "../../services/api";
import Bull from './Bull'
import DropDownPicker from "react-native-dropdown-picker";
import { Colors, canGoToNextPage, canGoToPreviousPage } from "../../shared/utils";
import { Icon, Loading } from "../../shared/components";
import UserContext from "../../contexts/User/UserContext";
import { TextInput } from "react-native";

type Props = {
  handleChangeTemTouro: (value: boolean) => void;
  handleSelectTouro: (selected: any) => void;
}

const BullsList = ({
  handleChangeTemTouro,
  handleSelectTouro
}: Props) => {
  const { user } = useContext(UserContext);

  const [tableData, setTableData] = useState<any[]>([]);
  const [tableTemp, setTableTemp] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');

  const [allBulls, setAllBulls] = useState<any[]>([]);
  const [table3, setTable3] = useState<any[]>([]);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropDownItems, setDropDownItems] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageFlag, setPageFlag] = useState<boolean>(false);

  useEffect(() => {
    if (tableData.length > 0) {
      let temp = [];
      for (let i = 1; i < totalPages + 1; i++) {
        temp.push({ label: `${i}`, value: `${i}` });
      }
      setDropDownItems(temp);
    }
  }, [totalPages]);

  useEffect(() => {
    async function loadBulls() {
      const response = await api.post("/vidaTouroDadosIniciais", {
        cnpjCpf: 'TODOS',
      });
      setTableTemp(response.data.result);
    }

    // @ts-ignore
    if (user.profile === "Adm") {
      loadBulls();
    }

  }, []);


  useEffect(() => {
    if (allBulls.length === 0) return
    let getTouro: any[] = [];
    const vidaTouroContratosAdendos: any[] = [];

    allBulls.forEach((elemento) => {
      getTouro.push({
        ...elemento, // Nome do Touro
        acao: "",
      });
    });

    getTouro.forEach((elemento) => {
      let acao = [elemento.codTou, elemento.nomTou];
      vidaTouroContratosAdendos.push({
        ...elemento,
        acao
      })
    });


    setLoading(false)
    setTableData(vidaTouroContratosAdendos);
    setTable3(vidaTouroContratosAdendos);
  }, [allBulls])

  useEffect(() => {
    const vidaTouroContratosAdendos: any[] = [];
    async function fetchDataAdm() {
      setLoading(true)
      const allDocs = await api.get("/getAllUserDocs");

      const response = await api.post("/vidaTouroDadosIniciais", {
        cnpjCpf: allDocs.data.allDocs,
        pagina: currentPage,
        profile: "Adm"
      });


      if (response.data.result === undefined) {
        return null;
      }

      setAllBulls(response.data.result);
      if (!pageFlag)
        setTotalPages(response.data.result[response.data.result.length - 1].nroPaginas);
      setPageFlag(true);
    }

    async function fetchData() {
      const response = await api.post("/vidaTouroDadosIniciais", {
        cnpjCpf: user.doc,
        pagina: currentPage,
        profile: "Comum"
      });

      if (response.data.result === undefined || response.data.result.apeTou == '') {
        handleChangeTemTouro(false);
        return null;
      }

      setAllBulls(response.data.result);
      setTotalPages(response.data.result[response.data.result.length - 1].nroPaginas);

      let getTouro: any[] = [];

      response.data.result.forEach((elemento: any) => {
        if (elemento.codTou !== '') {
          getTouro.push({
            ...elemento,
            acao: "",
          });
        }
      });

      getTouro.forEach((elemento) => {
        let acao = [elemento.codTou, elemento.nomTou];
        vidaTouroContratosAdendos.push({
          ...elemento,
          acao,
        });
      });

      setTableData(vidaTouroContratosAdendos);
      setTableTemp(vidaTouroContratosAdendos);
      setLoading(false);
    }
    // @ts-ignore
    if (user.profile == "Adm") {
      fetchDataAdm();
    } else {
      fetchData();
    }
  }, [currentPage]);

  //busca por nome do touro 
  useEffect(() => {
    let temp: any[] = [];
    if (tableTemp) {

      const filterData = tableTemp.filter(item => {
        return item.apeTou.toLowerCase().includes(searchText.toLowerCase())
      }
      )
      // array of arrays with filter data
      filterData.forEach((elemento) => {
        let acao = [elemento.codTou, elemento.nomTou];
        temp.push({
          ...elemento,
          // elemento.codTou, //nome touro
          // elemento.nomTou, // Nome guerra (apelido)
          acao
        })
      });
      if (searchText === '') {
        setTableData(table3)
      } else {
        setTableData(temp)
      }
    }

  }, [searchText, tableTemp])

  const handleGoToFirstPage = () => {
    setCurrentPage(1);
  };
  const handleGoToLastPage = () => {
    setCurrentPage(totalPages);
  }
  const handleGoToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  const handleGoToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  const renderFooter = () => {
    return tableData.length === 0 ? null : (
      <View style={{
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}>
        <Icon
          name="fast-rewind"
          disabled={!canGoToPreviousPage(currentPage)}
          onPress={handleGoToFirstPage}
        />
        <Icon
          name="chevron-left"
          disabled={!canGoToPreviousPage(currentPage)}
          onPress={handleGoToPreviousPage}
        />
        <Text>
          {`${currentPage}/${totalPages}`}
        </Text>
        <Icon
          name="chevron-right"
          disabled={!canGoToNextPage(currentPage, totalPages)}
          onPress={() => handleGoToNextPage()}
        />
        <Icon
          name="fast-forward"
          disabled={!canGoToNextPage(currentPage, totalPages)}
          onPress={() => handleGoToLastPage()}
        />
        <DropDownPicker
          items={dropDownItems}
          open={open}
          setOpen={setOpen}
          setItems={setDropDownItems}
          value={currentPage}
          setValue={setCurrentPage}
          dropDownDirection="TOP"
          listMode="SCROLLVIEW"
          containerStyle={{ height: 40, width: 90 }}
          style={{ backgroundColor: "#fafafa" }}
          placeholder={currentPage.toString()}
        />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {loading ? <Loading /> : (
        <>
          <TextInput
            style={styles.inputBusca}
            placeholder="Pesquisar"
            placeholderTextColor={Colors.lightGreyColor}
            value={searchText}
            onChangeText={text => setSearchText(text)}
          />

          <FlatList
            style={{ marginTop: 10 }}
            data={tableData}
            keyExtractor={(item, index) => {
              return index.toString();
            }}
            renderItem={({ item }) => <Bull data={item} handleSelectTouro={handleSelectTouro} />}
            onEndReachedThreshold={0.1}
            nestedScrollEnabled
            ListFooterComponent={renderFooter}
            showsVerticalScrollIndicator={false}
            ListFooterComponentStyle={{
              marginBottom: 100
            }}
            contentContainerStyle={{ paddingBottom: 20 }}
          />
        </>
      )}
    </View>
  );
};

export default BullsList;
