import { StyleSheet, Platform } from 'react-native';
// @ts-ignore
import { vw, vh } from 'react-native-expo-viewport-units';
import { Colors } from '../../shared/utils';

export const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'black',
    opacity: 0.4,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },

  container: {
    flex: 1,
    padding: 16,
    paddingTop: 30,
    backgroundColor: '#fff',
  },

  text: { textAlign: 'center', fontWeight: '100' },

  dataWrapper: { marginTop: -1 },

  row: { height: 38, backgroundColor: '#fff' },

  head: {
    backgroundColor: '#808B97',
    width: vw(60),
  },

  textsContainer: {
    width: "100%",
  },

  inputBusca: {
    width: "100%",
    // height: vh(5),
    borderRadius: 5,
    borderColor: "#707070",
    padding: 10,
    borderWidth: 1,
    alignSelf: "center",
    fontFamily: "Hind_400Regular",
    backgroundColor: Colors.inputWhite,
    ...Platform.select({
      web: {
        "@media (minWidth: 768px)": {
          width: "50%",
        },
      },
      default: {},
    }),
  },

  btn: {
    backgroundColor: Colors.maincolor,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
  },

  btnText: {
    textAlign: 'center',
    color: Colors.blueColor,
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Hind_400Regular',
    textDecorationLine: 'underline',
    fontSize: 16,
  },

  btnIcon: {
    color: Colors.blueColor,
    marginLeft: 4,
    alignSelf: 'center',
  },

  disabledBtnText: {
    textAlign: 'center',
    color: 'gray',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Hind_400Regular',
    textDecorationLine: 'underline',
    fontSize: 16,
  },

  containermodal: {
    flex: 1,
    padding: 16,
    backgroundColor: '#fff',
    width: vw(35),
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textmodal: {
    fontSize: 25,
    fontWeight: 'bold',
    color: '#537791',
    marginTop: vh(2),
    marginBottom: vh(2),
    marginRight: 20,
    // @ts-ignore
    alignSelf: 'left',
  },

  fecharmodal: {
    fontSize: 25,
    fontWeight: 'bold',
    color: Colors.maincolor,
    // @ts-ignore
    alignSelf: 'right',
  },

  headmodal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },

  textHeaderTable: {
    textAlign: 'center',
    fontWeight: '100',
    color: Colors.inputWhite,
    fontFamily: 'Hind_400Regular',
  },

  header: {
    height: 40,
    backgroundColor: Colors.blueColor,
    marginTop: 24,
  },
});
