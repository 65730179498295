import React, { useContext } from "react";
import {
  View,
  TouchableOpacity,
  SafeAreaView,
  Text,
} from "react-native";
import { styles } from "./styles";
import Icon from "react-native-vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";
import BullInformationContext from "../../services/bullInformationContext";

type Props = {
  title: string;
  action?: any;
  flagLocalExato?: any;
}

const Header = ({ title, action, flagLocalExato }: Props) => {
  const [bullInformation, setBullInformation] = useContext(BullInformationContext);

  const navigation = useNavigation();

  const openMenu = () => {
    // @ts-ignore
    navigation.openDrawer();
  };

  const handleBack = () => {
    if (action) {
      action();
    } else {
      navigation.goBack();
    }
  };

  return (
    <SafeAreaView style={{ marginTop: 30 }}>
      <View style={styles.container}>
        <TouchableOpacity
          style={{ paddingLeft: 10 }}
          onPress={() => {
            if (flagLocalExato) {
              // @ts-ignore
              if (bullInformation.codTou) {
                // @ts-ignore
                setBullInformation({});
              }
              else {
                handleBack();
              }
            } else {
              handleBack()
            }
          }}
        >
          <Icon name="arrow-back-ios" size={24} color="white" />
        </TouchableOpacity>
        <View>
          <Text style={styles.text}>{title}</Text>
        </View>
        <View>
          <TouchableOpacity style={{}} onPress={() => openMenu()}>
            <Icon name="menu" size={30} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default Header;
