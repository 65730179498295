import React, { useContext, useEffect, useState, createElement } from 'react';
import {
  View, Image, Modal, Text, TouchableOpacity,
  ActivityIndicator, Platform, ScrollView,
} from 'react-native';
import { styles } from './styles';
import Icon from 'react-native-vector-icons/MaterialIcons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import BullInformationContext from "../../services/bullInformationContext";
import * as ImagePicker from 'expo-image-picker';
import api from '../../services/api';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { getApps, initializeApp } from "firebase/app";
// @ts-ignore
import Grid from 'react-native-grid-component';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import { Colors } from '../../shared/utils';
import Button from "../../components/button";
import ButtonClean from "../../components/buttonclean";
import messageIdGenerator from "../../utils/messageIdGenerator";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Feather from "react-native-vector-icons/Feather";
import * as Permissions from 'expo-permissions'

type Props = {
  codTou: string;
  nomTou: string;
  navigation: any;
}

const FotosTouroAdm = ({ codTou, nomTou, navigation }: Props) => {
  const [bullInformation, setBullInformation] = useContext(BullInformationContext);
  const [carouselFlag, setCarouselFlag] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [image, setImage] = useState<any[]>([]);
  const [uploadFlag, setUploadFlag] = useState<boolean>(false);
  const [picDate, setPicDate] = useState<string>('');
  const [picArray, setPicArray] = useState<any[]>([]);
  const [flag, setFlag] = useState<boolean>(false);
  const [currentPicId, setCurrentPicId] = useState<string>('');
  const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
  const [reloadPicsFlag, setReloadPicsFlag] = useState<boolean>(false);
  const [picArrayTemp, setPicArrayTemp] = useState<any[]>([]);
  const [changeDateFlag, setChangeDateFlag] = useState<boolean>(false);
  const [backupPicArray, setBackupPicArray] = useState<any[]>([]);
  const [flagTemTouro, setFlagTemTouro] = useState<boolean>(true);
  const returnPage = "adm"
  const firebaseConfig = {
    apiKey: "AIzaSyAVTPyLZoZ1yZC65eRHh21JocH4QP1hZEw",
    authDomain: "hml-vida-do-touro.firebaseapp.com",
    databaseURL: "https://hml-vida-do-touro.firebaseio.com",
    storageBucket: "hml-vida-do-touro.appspot.com/fotosDoTouro",
    messagingSenderId: "174290674130",
    APP_ID: "1:174290674130:web:147fa12c3d506f9f94680b",
  };

  // Editing this file with fast refresh will reinitialize the app on every refresh, let's not do that
  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }


  useEffect(() => {
    //get all the pictures from firebase
    const getImages = async () => {
      setLoadingFlag(true);
      const storage = getStorage();
      const pathReference = ref(storage, `/fotosDoTouro/${codTou}/`);
      listAll(pathReference)
        .then(async (res) => {
          if (res.items.length < 1) {
            setFlagTemTouro(false);
          }
          let arrayDasFotos = [];
          const items = res.items;
          for (const item of items) {
            //get url of the picture
            let url = await getDownloadURL(item)
            let id = item.name
            const date = await api.post(`/getPicData`, {
              codTou: codTou,
              picId: id
            })
            if (date.data.error) {
              setLoadingFlag(false)
              return setFlagTemTouro(false)
            }
            let dateArray = date.data.dataFoto.split('-')
            let dateFormated = dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2]
            if (dateFormated == 'undefined/undefined/' || dateFormated == '-undefined-undefined') {
              dateFormated = 'Data não informada';
            }
            arrayDasFotos.push({
              link: url, picDate: dateFormated, id: id
            })
          }
          setPicArray(arrayDasFotos);
          setBackupPicArray(arrayDasFotos);
          setLoadingFlag(false);
        }).catch((error) => {
          console.log(error)
        });

    }
    if (!flag) {
      getImages();
    }
    setFlag(true);
  }, [picArray, reloadPicsFlag])

  const [currentPicIndex, setCurrentPicIndex] = useState(0);

  const deletePic = async () => {
    if (uploadFlag) {
      let array = picArrayTemp;
      let arrayImage = image
      let imageIndex
      if (picArray.length > 0) {
        imageIndex = currentPicIndex - picArray.length;
      }
      array.splice(currentPicIndex, 1);
      // @ts-ignore
      arrayImage.splice(imageIndex, 1);
      setImage(arrayImage);
      setPicArrayTemp(array);
      Toast.show({
        type: 'success',
        position: 'top',
        text1: 'Foto excluída com sucesso!',
        visibilityTime: 3000,
        autoHide: true
      })
    }
    else {
      await api.post(`/deleteData`, {
        codTou: codTou,
        picId: currentPicId
      })
      const storage = getStorage();
      const pathReference = ref(storage, `/fotosDoTouro/${codTou}/${currentPicId}`);
      deleteObject(pathReference)
        .then(() => {
          setPicArray(prevState => prevState.filter(item => item.id !== currentPicId))
          setPicArrayTemp(prevState => prevState.filter(item => item.id !== currentPicId))
          Toast.show({
            type: 'success',
            position: 'top',
            text1: 'Foto excluída com sucesso!',
            visibilityTime: 3000,
            autoHide: true
          })
        }
        ).catch((error) => {
          console.log(error)
        }
        )

      setReloadPicsFlag(!reloadPicsFlag)
    }
  }
  const onPress = (rowData: any[]) => {
    const bullData = {
    }

    // @ts-ignore
    setBullInformation(bullData);
  }

  const loadPicture = async () => {
    const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY)

    if (status === 'granted' || Platform.OS === 'web') {
      let response = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        allowsMultipleSelection: true,
        aspect: [16, 9],
        base64: true,
        quality: 1,
      });

      if (response.cancelled) {
        return
      }

      if (Platform.OS === 'web') {
        // @ts-ignore
        if (picArray.length + response.assets.length > 10) {
          Toast.show({
            type: 'error',
            text1: 'Limite de fotos excedido',
            text2: 'O limite de fotos por touro é de 10',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
          });
          return;
        }
      } else {
        if (picArrayTemp.length > 9 || picArray.length > 9) {
          Toast.show({
            type: 'error',
            text1: 'Limite de fotos excedido',
            text2: 'O limite de fotos por touro é de 10',
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
          });
          return;
        }
      }
      setUploadFlag(true)

      if (picArrayTemp.length < 1) {
        setPicArrayTemp(picArray)
      }
      if (Platform.OS === 'web') {
        // @ts-ignore
        response.assets.map((item, index) => {
          setImage(prevState => [...prevState, {
            uri: item.uri,
            picDate: picDate,
          }])
          setPicArrayTemp(prevState => [...prevState, {
            link: item.uri,
            picDate: picDate,
          }])
        })
      } else {
        const im = { uri: response.uri, picDate: picDate };
        const im2 = { link: response.uri, picDate: picDate };
        setImage(prevState => [...prevState, im])
        setPicArrayTemp(prevState => [...prevState, im2])
      }
    } else {
      Toast.show({
        type: 'error',
        text1: 'Erro ao carregar a foto',
        text2: 'Verifique se você tem permissão para acessar a galeria',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    }
  }

  const uploadImageAsync = async (uri: any) => {
    uri.map(async (item: any) => {
      const blob = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          resolve(xhr.response);
        };
        xhr.onerror = function (e) {
          console.log(e);
          reject(new TypeError("Network request failed"));
        };
        xhr.responseType = "blob";
        xhr.open("GET", item.uri, true);
        xhr.send(null);
      });
      const uuid = messageIdGenerator();
      const fileRef = ref(getStorage(), `/fotosDoTouro/${codTou}/${uuid}`);
      await api.post('/addData', {
        codTou: codTou,
        picId: uuid,
        dataFoto: item.picDate,
      })
      // @ts-ignore
      await uploadBytes(fileRef, blob);

    })
    saveDatesChanged()

    setUploadFlag(false)
    setReloadPicsFlag(!reloadPicsFlag)
    // @ts-ignore
    setBullInformation({});


    Toast.show({
      type: 'success',
      position: 'top',
      text1: 'Foto salva com sucesso',
      visibilityTime: 3000,
      autoHide: true
    })

  };

  const handleCancelUpload = () => {
    setImage([]);
    setUploadFlag(false);
    setPicArrayTemp([]);
    // @ts-ignore
    setBullInformation({});
  }

  const handleChangeDate = (date: any, index: number) => {
    setChangeDateFlag(true)
    //format date to yyyy-mm-dd
    const today = new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, "0") + '-' + new Date().getDate().toString().padStart(2, "0");
    //const today = new Date().getDate().toString().padStart(2, "0") + '/' + (new Date().getMonth() + 1).toString().padStart(2, "0") + '/' + new Date().getFullYear();

    if (date > today) {
      Toast.show({
        type: 'error',
        text1: 'Data inválida',
        text2: 'A data não pode ser maior que a data atual',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      return;
    }

    if (uploadFlag) {
      let array = picArrayTemp;
      let arrayImage = image
      let imageIndex = index

      if (picArrayTemp.length > picArray.length) {
        imageIndex = index - picArray.length
      }
      array[index].picDate = date;
      arrayImage[imageIndex].picDate = date;
      setImage(arrayImage);
      setPicArrayTemp(prevState => {
        let newArray = [...prevState];
        newArray[index] = array[index];
        return newArray
      })
    }
    else {
      setPicArray(prevState => {
        let newArray = [...prevState];
        newArray[index].picDate = date;
        return newArray;
      })
    }
  }

  const handleSave = (image: any) => {
    if (image.length > 0) {
      uploadImageAsync(image)
    }
    else if (changeDateFlag) {
      saveDatesChanged()
    }
    else {
      Toast.show({
        type: 'success',
        position: 'top',
        text1: 'alterações salvas com sucesso',
        visibilityTime: 3000,
        autoHide: true
      })
    }
    // @ts-ignore
    setBullInformation({});
  }

  const saveDatesChanged = () => {
    try {
      picArray.map(async (item, index) => {

        if (item.picDate == "Data não informada") {
          await api.put(`/editPicDate`, {
            codTou: codTou,
            picId: item.id,
            dataFoto: "",
          })
        }
        else {
          await api.put(`/editPicDate`, {
            codTou: codTou,
            picId: item.id,
            dataFoto: picArray[index].picDate,
          })
        }
      })
      setChangeDateFlag(false)
      setReloadPicsFlag(!reloadPicsFlag)

      Toast.show({
        type: 'success',
        position: 'top',
        text1: 'Datas alteradas com sucesso',
        visibilityTime: 3000,
        autoHide: true
      })
      // @ts-ignore
      setBullInformation({});

    } catch (error) {
      console.log(error)

      Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Erro ao alterar as datas',
        visibilityTime: 3000,
        autoHide: true
      })

    }
  }

  const [index, setIndex] = useState(-1);
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  useEffect(() => {
    if (index !== -1) {
      showDatePicker()
    }
  }, [index])
  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };
  const hideDatePicker = () => {
    setIndex(-1)
    setDatePickerVisibility(false);
  };
  const handleConfirm = (date: any) => {
    const splittedDate = date.toString().split(' ');
    const monthNameToNumber = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12'
    }
    // @ts-ignore
    const month = monthNameToNumber[splittedDate[1]];
    const day = splittedDate[2];
    const year = splittedDate[3];
    const formattedDate = year + '-' + month + '-' + day;

    setChangeDateFlag(true)
    const today = new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, "0") + '-' + new Date().getDate().toString().padStart(2, "0");
    if (formattedDate > today) {
      Toast.show({
        type: 'error',
        text1: 'Data inválida',
        text2: 'A data não pode ser maior que a data atual',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      setIndex(-1)

      return;
    }

    if (uploadFlag) {
      let array = picArrayTemp;
      let arrayImage = image
      let imageIndex = index
      setPicArrayTemp(prevState => {
        let newArray = [...prevState];
        newArray[index].picDate = formattedDate;
        return newArray;
      })
      setIndex(-1)
      hideDatePicker();
      array[index].picDate = formattedDate;
      if (picArrayTemp.length > picArray.length) {
        imageIndex = index - picArray.length
      }
      arrayImage[imageIndex].picDate = formattedDate;
      setImage(arrayImage);
      // setPicArrayTemp(array);
      setIndex(-1)

    }
    else {
      setPicArray(prevState => {
        let newArray = [...prevState];
        newArray[index].picDate = formattedDate;
        return newArray;
      })
    }

    setIndex(-1)
    hideDatePicker();
  };

  const reverseDataString = (data: any) => {
    const splittedDate = data.split('-');
    const year = splittedDate[0];
    const month = splittedDate[1];
    const day = splittedDate[2];
    const formattedDate = day + '/' + month + '/' + year;
    if (formattedDate == 'undefined/undefined/Data não informada') {
      return 'Data não informada';
    }
    return formattedDate;
  }

  const handleImageStyle = () => {
    if (Platform.OS === 'web') {
      return {
        width: '90%',
        height: 140,
        marginRight: 10,
        marginLeft: 10,
        marginBottom: 10
      }
    }
    return styles.card
  }

  const _renderItem = (data: any, i: number) => {
    return (
      <View key={data.id} style={styles.imageContainer}>
        <View style={styles.imageContainer2}>
          <TouchableOpacity onPress={() => {
            setModalVisible(!modalVisible)
            setCurrentPicId(data.id)
            setCurrentPicIndex(i)
          }}
            // @ts-ignore
            style={handleImageStyle}
          >
            <AntDesign name="closecircle" size={20} color="#C72B2B" />
          </TouchableOpacity>
          <TouchableOpacity
            key={data.id}
            onPress={() => {
              if (uploadFlag) {
                return Toast.show({
                  type: 'info',
                  position: 'top',
                  text1: 'Necessário salvar as alterações!',
                  visibilityTime: 3000,
                  autoHide: true
                })
              }
              navigation.navigate("PicCarousel", {
                picArray: loadingFlag ? picArrayTemp : picArray,
                index: i,
                returnPage,
              })
            }
            }>
            <Image
              style={{
                width: '90%',
                height: 140,
                marginRight: 10,
                marginLeft: 10,
                marginBottom: 10
              }}
              source={{ uri: data.link }}
            />

          </TouchableOpacity>

          <Modal
            animationType="slide"
            visible={modalVisible}
            transparent={true}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
            style={styles.modalContainer}>

            <View style={styles.modalContent}>
              <Text style={styles.modalText}>
                Deseja realmente excluir a foto?
              </Text>
              <View style={styles.modalButtons}>
                <TouchableOpacity
                  style={styles.modalButton}
                  onPress={() => { setModalVisible(!modalVisible); }}>
                  <Icon name="close" size={30} color="red" />
                  <Text style={styles.modalChoiceText}>Não</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.modalButton}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                    //@ts-ignore
                    deletePic(data.id);
                  }}>
                  <Icon name="delete" size={30} color="green" />
                  <Text style={styles.modalChoiceText}>Sim</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
          {Platform.OS === "web" ? (
            <View>
              {
                createElement(
                  'input',
                  {
                    type: 'date',
                    onChange: (e: any) => handleChangeDate(e.target.value, i),
                    value: data.picDate,
                    style: {
                      width: "70%",
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      marginLeft: 14,
                    }
                  }
                )
              }
            </View>
          ) : (
            <View>
              <TouchableOpacity onPress={() => {
                setCurrentPicId(data.id)
                setIndex(i)
              }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Text>{data.picDate !== '' ? reverseDataString(data.picDate) : "Data não informada"}</Text>
                <Feather name="calendar" size={24} color="black" />
              </TouchableOpacity>

            </View>
          )}

        </View>
      </View>
    )
  };

  const normalRender = () => {
    return (
      <ScrollView style={styles.container}
        contentContainerStyle={{
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <View style={styles.firstContainer}>
          <View style={styles.textContainer}>

            <Text style={styles.textHeader}>
              {nomTou}
            </Text>

            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
            }} >
              <Text style={styles.textHeader}>
                Código:
              </Text>
              <Text style={styles.textAnswerHeader}>
                {codTou}
              </Text>
            </View>

          </View>
          <View style={styles.uploadContainer}>
            {picArray.length < 11 && !loadingFlag ?
              <TouchableOpacity
                style={styles.uploadButton}
                onPress={() => loadPicture()}>
                <Icon name="cloud-upload" size={60} color={Colors.blueColor} />
                <Text style={{
                  fontSize: 12,
                  color: Colors.greyColor,
                }}>
                  Adicionar foto
                </Text>
              </TouchableOpacity>
              : null}

          </View>
        </View>

        <ScrollView style={styles.secondContainer}>
          {loadingFlag ? <ActivityIndicator size="small" color="#0000ff" /> : null}
          <View>
            {flagTemTouro || picArrayTemp.length > 0 ? (null) : <Text style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: 'black',
              textAlign: 'center'
            }}>Esse touro ainda não possui fotos cadastradas</Text>}
          </View>
          {uploadFlag ? (
            <ScrollView>
              <DateTimePickerModal
                isVisible={isDatePickerVisible}
                mode="date"
                onConfirm={handleConfirm}
                onCancel={hideDatePicker}
                date={new Date()}
              />

              <Grid
                style={{ flex: 1, marginBottom: 20, left: -5 }}
                data={picArrayTemp}
                renderItem={_renderItem}
                numColumns={2}
                keyExtractor={(item: any, index: number) => index.toString()}
              />
              <View style={styles.controlButtons} >
                <ButtonClean
                  onPress={() => handleCancelUpload()}
                  // @ts-ignore
                  style={{
                    width: 200,
                    height: 60,
                  }}
                >
                  CANCELAR
                </ButtonClean>
                <Button
                  disabled={false}
                  onPress={() => handleSave(image)}
                  // @ts-ignore
                  style={{
                    width: 200,
                    height: 60,
                  }}
                >
                  SALVAR
                </Button>
              </View>
            </ScrollView>
          ) :
            <ScrollView>
              <DateTimePickerModal
                isVisible={isDatePickerVisible}
                mode="date"
                onConfirm={handleConfirm}
                onCancel={hideDatePicker}
                date={new Date()}
              />
              <Grid
                style={{ flex: 1, marginBottom: 20, left: -5 }}
                data={picArray}
                renderItem={_renderItem}
                numColumns={2}
                keyExtractor={(item: any, index: number) => index.toString()}

              />
              <View style={styles.controlButtons} >
                <ButtonClean
                  onPress={() => handleCancelUpload()}
                  // @ts-ignore
                  style={{
                    width: 200,
                    height: 60,
                  }}
                >
                  CANCELAR
                </ButtonClean>
                <Button
                  disabled={false}
                  onPress={() => handleSave(image)}
                  // @ts-ignore
                  style={{
                    width: 200,
                    height: 60,
                  }}
                >
                  SALVAR
                </Button>
              </View>
            </ScrollView>
          }
        </ScrollView >
      </ScrollView >

    )
  }

  if (carouselFlag) {
    // @ts-ignore
    return carouselRender();
  } else {
    return normalRender();
  }


}

export default FotosTouroAdm