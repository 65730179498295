import { initializeApp, FirebaseApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  databaseURL: string;
}

// HML
// const firebaseConfig: FirebaseConfig = {
//   apiKey: 'AIzaSyAVTPyLZoZ1yZC65eRHh21JocH4QP1hZEw',
//   authDomain: 'hml-vida-do-touro.firebaseapp.com',
//   projectId: 'hml-vida-do-touro',
//   storageBucket: 'hml-vida-do-touro.appspot.com',
//   messagingSenderId: '174290674130',
//   appId: '1:174290674130:web:147fa12c3d506f9f94680b',
//   measurementId: 'G-24RSGQJ4WW',
//   databaseURL: 'https://hml-vida-do-touro.firebaseio.com',
// };

// PROD
const firebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyC9VUjibMvbWgEI1VIL2ICNl8sw6s0iHYU",
  authDomain: "tairana-vida-do-touro.firebaseapp.com",
  projectId: "tairana-vida-do-touro",
  storageBucket: "tairana-vida-do-touro.appspot.com",
  messagingSenderId: "62704896483",
  appId: "1:62704896483:web:0650ad7434fc167bffb8d6",
  measurementId: "G-NTTTNMFF30",
  databaseURL: 'https://tairana-vida-do-touro.firebaseio.com',
};

const app: FirebaseApp = initializeApp(firebaseConfig);

export const auth: Auth = getAuth(app);
export const database: Firestore = getFirestore(app);
export const config: FirebaseConfig = firebaseConfig;