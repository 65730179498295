import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: "#232129",
    borderRadius: 10,
    padding: 16,
    color: "white",
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    marginVertical: 5,
    marginHorizontal: 5
  },
  content: {
    fontWeight: "bold",
    fontSize: 16,
    color: 'white'
  },
  avoidingView: {
    flex: 1,
  },
});




