import AsyncStorage from '@react-native-async-storage/async-storage';

import { createContext, useEffect, useMemo, useState } from "react";

import Toast from 'react-native-toast-message';

import api from '../../services/api';

import {
  IUserContextState,
  UserProviderProps,
} from "./types";
import { Platform } from 'react-native';

export const UserContext = createContext({} as IUserContextState);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [user, setUser] = useState<IUser>({} as IUser)

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleLogin = async (doc: string, password: string) => {
    try {
      const docRegex = doc.replace(/[^\d]+/g, '');

      if (doc === '' || password === '')
        return Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Preencha os campos',
          autoHide: true,
          visibilityTime: 3000,
        });

      setIsLoading(true);

      // test this response
      const response = await api.post('/userdoc', {
        doc: docRegex,
      });

      let isAcessError = response.data.resp.length === 0;

      let email = ''
      if (!isAcessError) {
        email = response.data.resp[1].email;
        const isLoginError = await api.post('/login', {
          email,
          password,
        });

        isAcessError = isLoginError.data.error;
      }

      if (isAcessError) {
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Documento ou senha incorretos',
          autoHide: true,
          visibilityTime: 3000,
        });
        setIsLoading(false);
      } else {
        Toast.show({
          type: 'success',
          position: 'top',
          text1: 'Login realizado com sucesso',
          text2: 'Redirecionando para a página principal',
          autoHide: true,
          visibilityTime: 1000,
        });

        setIsLoading(false);

        // get user types here
        const userResponse = await api.post('/user', {
          email,
        });

        const userData = {
          id: userResponse.data.resp[0].id,
          password,
          phone: userResponse.data.resp[1].phone,
          name: userResponse.data.resp[1].name,
          doc: userResponse.data.resp[1].doc,
          email: userResponse.data.resp[1].email,
          profile: userResponse.data.resp[1].profile,
          isTairana: userResponse.data.resp[1].isTairana,
          isLoggedIn: true,
        };

        setUser(userData);
        //reset all components state

        if (Platform.OS === 'web') {
          localStorage.setItem('user', JSON.stringify(userData));
        } else {
          await AsyncStorage.setItem('user', JSON.stringify(userData));
        }
      }
    } catch (error) {
      setIsLoading(false);
      Toast.show({
        type: 'error',
        position: 'top',
        // @ts-ignore
        text1: error.message,
        autoHide: true,
        visibilityTime: 3000,
      });
    }
  };

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true)
      const resp = await api.post('/userdoc', {
        doc: user.doc,
      });

      const email = resp.data.resp[1].email;
      await api.post('/users/delete', { email: email });
      setUser({} as IUser);

      setIsDeleteModalVisible(false)

      return Toast.show({
        type: 'success',
        position: 'top',
        text1: 'Conta deletada com sucesso!',
        autoHide: true,
        visibilityTime: 3000,
      });
    } catch (err) {
      return Toast.show({
        type: 'error',
        position: 'top',
        text1: 'Não foi possível deletar a conta.',
        autoHide: true,
        visibilityTime: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const UserProviderValues = useMemo(
    () => ({
      user,
      setUser,
      handleLogin,
      isLoading,
      handleDeleteUser,
      isDeleteModalVisible,
      setIsDeleteModalVisible
    }),
    [
      user,
      setUser,
      handleLogin,
      isLoading,
      handleDeleteUser,
      isDeleteModalVisible,
      setIsDeleteModalVisible
    ]
  );

  return (
    <UserContext.Provider value={UserProviderValues}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
