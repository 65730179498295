import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'black',
    opacity: 0.4,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});
