import StyleSheet from 'react-native-media-query';
// @ts-ignore
import { vw, vh } from 'react-native-expo-viewport-units';
import { Platform } from 'react-native';
import { Colors } from '../../shared/utils';

export const { ids, styles } = StyleSheet.create({
  container: {
    height: vh(100),
    backgroundColor: Colors.maincolor,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
    width: '100%',
  },

  contentContainer: {
    width: vw(80),
    display: 'flex',
    flexDirection: 'column',
  },
  textsContainer: {
    width: '100%',
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  whiteLine: {
    displa: 'flex',
    alignContent: 'center',
    borderRadius: 16,
    justifyContent: 'center',
    width: '100%',
    height: 50,
    backgroundColor: '#fff',
    padding: 10,
    marginTop: 10,
  },
  content: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#fff',
    marginTop: 20,
  },
  avoidingView: {
    flex: 1,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    color: Colors.color1,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
  mainText: {
    fontSize: 14,
    fontFamily: 'Hind_700Bold',
    color: Colors.greyColor,
    marginTop: 8,
    ...Platform.select({
      web: {
        fontSize: 16,
      },
      default: {},
    }),
  },
  inputTitleContainer: {
    marginTop: 2,
    marginBottom: 5,
    borderBottomWidth: 0.25,
    borderBottomColor: Colors.lightGreyColor,
  },
  inputBusca: {
    ...Platform.select({
      web: {
        display: 'flex',
        flexDirection: 'row',
        width: 500,
        maxWidth: '100%',
        marginTop: 4,
        backgroundColor: Colors.inputWhite,
        color: '#000',
        fontFamily: 'Hind_400Regular',
        fontSize: 15,
      },
      default: {
        display: 'flex',
        flexDirection: 'row',
        width: 300,
        maxWidth: '100%',
        marginTop: 4,
        backgroundColor: Colors.inputWhite,
        color: '#000',
        fontFamily: 'Hind_400Regular',
        fontSize: 12,
      },
    }),
  },
  tableContainer: {
    marginTop: 32,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'lightblue',
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalContent: {
    backgroundColor: 'aliceblue',
    position: 'absolute',
    top: '50%',
    width: 300,
    height: 150,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    opacity: 0.9,
  },

  modalText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
    marginTop: 8,
    marginBottom: 15,
  },

  modalButton: {
    width: '40%',
    height: 40,
    borderRadius: 5,
    marginTop: 8,
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 8,
  },
  modalChoiceText: {
    fontFamily: 'Hind_700Bold',
    color: Colors.blueColor,
  },

  button: {
    width: 100,
    height: 50,
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
    backgroundColor: Colors.buttonColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },

  buttonText: {
    fontSize: 15,
    color: Colors.textButtonColor,
  },

  containerheader: {
    ...Platform.select({
      web: {
        flex: 1,
        backgroundColor: Colors.maincolor,
        marginBottom: 50,
        paddingBottom: 20,
        // @ts-ignore
        flex: 1,
      },
      default: {
        flex: 1,
        backgroundColor: Colors.maincolor,
        marginBottom: 50,
        paddingBottom: 20,
      },
    }),
  },
  card: {
    ...Platform.select({
      web: {
        marginTop: 15,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        borderBottomWidth: 0,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '90%',
        flex: 1,
      },
      default: {
        marginTop: 15,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#ddd',
        borderBottomWidth: 0,
        paddingBottom: 10,
        height: '90%',
      },
    }),
  },
  perfilText: {
    fontSize: 14,
    fontFamily: 'Poppins_700Bold',
    color: Colors.greyColor,
    marginTop: 16,
    marginBottom: 8,
    ...Platform.select({
      web: {
      },
      default: {},
    }),
  },
});
