import React, { useContext, useEffect } from 'react';
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Platform } from 'react-native';

import UserContext from "../contexts/User/UserContext";
import AsyncStorage from '@react-native-async-storage/async-storage';

import * as Permissions from 'expo-permissions'
import LoginStackNavigator from './LoginStackNavigator';
import MainStackNavigator from './MainStackNavigator';
import ChatStackNavigator from './ChatStackNavigator';
import GeneralInformationStackNavigator from './GeneralInformationStackNavigator';
import Signup from '../pages/signup';

const Drawer = createDrawerNavigator();

const DrawerNavigator = () => {
  const { user } = useContext(UserContext);

  let usuario: any
  let isAdm: any
  let usuario2: any
  let isLoggedIn: any
  if (Platform.OS === 'web') {
    usuario = localStorage.getItem("user");
    usuario2 = JSON.parse(usuario)

    if (usuario !== undefined && usuario !== null) {
      isAdm = usuario2.profile === "Adm";
      isLoggedIn = usuario2.isLoggedIn
    }
    else {
      isAdm = user.profile === "Adm";
      isLoggedIn = user.isLoggedIn
    }

  } else {
    async function getUser() {
      usuario = await AsyncStorage.getItem("user");
      usuario2 = JSON.parse(usuario)
      if (usuario !== undefined && usuario !== null) {
        isAdm = usuario2.profile === "Adm";
        isLoggedIn = usuario2.isLoggedIn
      }
    }
    getUser();
    isAdm = user.profile === "Adm";
    isLoggedIn = user.isLoggedIn
  }

  //ask permissions for media library
  useEffect(() => {
    if (Platform.OS !== 'web') {
      async function askPermissions() {
        const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
        if (status !== 'granted') {
          alert('Por favor, permita o acesso a sua biblioteca de mídia para que o app funcione corretamente.');
        }
      }
      askPermissions();
    }
  }, []);


  if (isLoggedIn) {
    return (
      <Drawer.Navigator initialRouteName="Homepage">
        <Drawer.Screen
          name="Sair"
          component={LoginStackNavigator}
          options={{
            headerShown: false,
            swipeEnabled: false,
          }}
        />

        <Drawer.Screen
          name="Homepage"
          component={MainStackNavigator}
          options={{
            headerShown: false,
          }}
        />

        {isAdm ? (
          <Drawer.Screen
            name="Administração"
            component={ChatStackNavigator}
            options={{
              headerShown: false,
            }}
          />
        ) : null}

        <Drawer.Screen
          name="Informações gerais"
          component={GeneralInformationStackNavigator}
          options={{
            headerShown: false,
          }}
        />
      </Drawer.Navigator>
    );
  } else {
    return (
      <Drawer.Navigator initialRouteName="Login">
        <Drawer.Screen
          name="LoginPage"
          component={LoginStackNavigator}
          options={{
            headerShown: false,
            swipeEnabled: false,
          }}
        />
        <Drawer.Screen
          name="Signup"
          component={Signup}
          options={{
            headerShown: false,
          }}
        />
      </Drawer.Navigator>
    );
  }
};

export default DrawerNavigator;