import { Dimensions } from "react-native";

const width = Dimensions.get('window');

export const TABLE_HEAD = [
  'Código do Touro',
  'Nome',
  'Nome de guerra',
  '% Proprietário',
  'Dt Início',
  'Dt Fim',
  'Empresa Contratada',
  'Qtd Contratada',
  'Documentos',
];

export const TABLE_HEAD_MODAL = ['Sequência', 'Descrição', 'Visualizar'];

export const ROW_WIDTH = [80, 160, 300, 100, 100, 100, 130, 110, 110];

export const WEB_ROW_WIDTH = width.width < 1900 ? [200, 200, 300, 200, 120, 140, 160, 180, 200] : [
  width.width / 12,
  width.width / 7.2,
  width.width / 4,
  width.width / 12,
  width.width / 12,
  width.width / 12,
  width.width / 12,
  width.width / 12,
  width.width / 12,
]
