import React from "react";

import {
  SafeAreaView,
  View,
  Text,
  TextInput,
} from "react-native";
import { styles } from "./styles";

import * as yup from "yup";
import Toast from "react-native-toast-message";
import api from "../../services/api";

import Header from "../../components/headerscreens";

import SelectList from "react-native-dropdown-select-list";

import { FontAwesome } from "@expo/vector-icons";
import { Card } from "react-native-elements";
import Button from "../../components/button";
import ButtonClean from "../../components/buttonclean";

import { Colors } from "../../shared/utils";

type Props = {
  navigation: any;
}

const Signup = ({ navigation }: Props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("Av4sS@#$Af");
  const [name, setName] = React.useState("");
  const [doc, setDoc] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [profile, setProfile] = React.useState("");

  const [selected, setSelected] = React.useState("");
  const [data, setData] = React.useState([
    { key: "Adm", value: "Administrador" },
    { key: "Comum", value: "Usuário" },
    { key: "Tairana", value: "Tairana" }
  ]);

  const schema = yup.object().shape({
    email: yup.string().email().required("Email é obrigatório"),
    name: yup.string().required("Nome é obrigatório"),
    doc: yup.string().required("CPF/CNPJ é obrigatório"),
    phone: yup.string().required("Telefone é obrigatório"),
    profile: yup.string().required("Tipo de usuário é obrigatório"),
  });

  const phoneMask = (value: any) => {
    let data = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setPhone(data);
  };

  const handleSubmit = async () => {
    try {
      await schema.validate({ email, name, doc, phone, profile });

      const phoneRegex = phone.replace(/[^\d]+/g, "");
      const cpfRegex = doc.replace(/[^\d]+/g, "");

      const resp = await api.post("/userdoc", {
        cpfRegex,
      });

      if (!resp.data.error) {
        Toast.show({
          type: "error",
          position: "top",
          text1: "Documento já cadastrado",
          autoHide: true,
          visibilityTime: 3000,
        });
        throw new Error("Documento já cadastrado");
      }

      const isValid = await api.post("/signup", {
        email,
        password,
      });

      if (isValid.data.error) {
        Toast.show({
          type: "error",
          position: "top",
          text1: "E-mail já cadastrado",
          autoHide: true,
          visibilityTime: 3000,
        });
      } else {
        await api.post("/users/create", {
          active: true,
          email,
          password,
          name,
          doc: cpfRegex,
          phone: phoneRegex,
          profile: profile === "Tairana" ? "Adm" : profile,
          isTairana: profile === "Tairana"
        });
        await api.post("/resetPassword", {
          email,
        });
        Toast.show({
          type: "success",
          position: "top",
          text1: "Cadastro realizado com sucesso",
          text2: "Cadastro realizado com sucesso",
          autoHide: true,
          visibilityTime: 3000,
        });
        navigation.navigate("InformacoesUsuarios", {
          'refresh': true
        });
      }
    } catch (error) {
      Toast.show({
        type: "error",
        position: "top",
        text1: "Ocorreu um erro",
        // @ts-ignore
        text2: error.message,
        autoHide: true,
        visibilityTime: 3000,
      });
    }
  };

  /////////New/////////
  const handleCpfCnpjChange = (event: any) => {
    // Get only the numbers from the data input
    let data = event.replace(/[^\d]+/g, "");
    // Checking data length to define if it is cpf or cnpj
    if (data.length > 11) {
      // It's cnpj
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
        5,
        3
      )}/`;
      if (data.length > 12) {
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      } else {
        cnpj += data.substr(8);
      }
      data = cnpj;
    } else {
      // It's cpf
      let cpf = "";
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
      }
      data = cpf;
    }

    setDoc(data);
  };

  return (
    <View style={styles.containerheader}>
      <Header title={"Cadastro de Usuários"} />
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          {/* @ts-ignore */}
          <Card containerStyle={styles.card}>
            <View style={styles.inputTitleContainer}>
              <Text style={styles.mainText}>CPF/CNPJ</Text>
              <TextInput
                style={styles.inputBusca}
                placeholder="CPF/CNPJ"
                placeholderTextColor="white"
                value={doc}
                onChangeText={(text) => handleCpfCnpjChange(text)}
              />
            </View>
            <View style={styles.inputTitleContainer}>
              <Text style={styles.mainText}>E-mail</Text>
              <TextInput
                style={styles.inputBusca}
                placeholder="Email"
                placeholderTextColor="white"
                value={email}
                onChangeText={(text) => setEmail(text)}
              />
            </View>
            <View style={styles.inputTitleContainer}>
              <Text style={styles.mainText}>Nome</Text>
              <TextInput
                style={styles.inputBusca}
                placeholder="Nome"
                placeholderTextColor="white"
                value={name}
                onChangeText={(text) => setName(text)}
              />
            </View>

            <View style={styles.inputTitleContainer}>
              <Text style={styles.mainText}>Telefone</Text>
              <TextInput
                style={styles.inputBusca}
                placeholder="Telefone"
                placeholderTextColor="white"
                value={phone}
                onChangeText={(text) => phoneMask(text)}
              />
            </View>

            <View style={styles.inputTitleContainer}>
              <Text style={styles.perfilText}>Perfil</Text>
              {/* @ts-ignore */}
              <SelectList
                onSelect={() => setProfile(selected)}
                placeholder="Selecione o perfil"
                setSelected={setSelected}
                data={data}
                arrowicon={
                  <FontAwesome
                    name="chevron-down"
                    size={10}
                    color={Colors.greyColor}
                  />
                }
                // searchicon={<FontAwesome name="search" size={12} color={"black"} />}
                search={false}
                boxStyles={{
                  // width: 120,
                  // postion: "absolute",
                  fontSize: 10,
                  borderWidth: 1,
                  borderColor: Colors.greyColor,
                  // height: 30,
                }}
                // defaultOption={{ key: "2", value: "Usuário" }} //default selected option
                inputStyles={{
                  color: Colors.greyColor,
                  fontFamily: "Hind_400Regular",
                  marginRight: 5,
                  fontSize: 14,
                }} //override default styles
                dropdownTextStyles={{
                  color: Colors.greyColor,
                  fontSize: 14,
                  fontFamily: "Hind_400Regular",
                }} //override default styles
                dropdownStyles={{
                  width: 150,
                  // postion: "absolute",
                  fontSize: 14,
                  borderWidth: 0,
                  borderColor: Colors.greyColor,
                }}
              />
            </View>

            <View style={{ marginBottom: 60 }}>
              <ButtonClean
                onPress={() => navigation.navigate("InformacoesUsuarios", {
                  'refresh': true
                })}
              // style={styles.loginButton}
              >
                <Text>
                  CANCELAR
                </Text>
              </ButtonClean>
              {/* @ts-ignore */}
              <Button
                onPress={() => handleSubmit()}
              // style={styles.loginButton}
              >
                <Text>
                  SALVAR
                </Text>
              </Button>
            </View>
          </Card>
        </View>
      </SafeAreaView>
    </View>
  );
};

export default Signup;
