import React, { useCallback, useContext, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  Modal,
  ActivityIndicator,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

import { styles } from './styles';
import Header from '../../components/header';
import { Colors } from '../../shared/utils';
import LoadingModal from '../../components/LoadingModal';
import UserContext from '../../contexts/User/UserContext';

const optionsArray = [
  {
    key: 'Informações de Contrato',
    componentName: 'InformacoesContrato',
  },
  {
    key: 'Informações de Estoque',
    componentName: 'InformacoesEstoque',
  },
  {
    key: 'Informações de Despesa',
    componentName: 'InformacoesDespesas',
  },
  {
    key: 'Informações de Royalties',
    componentName: 'InformacoesRoyalties',
  },
  {
    key: 'Informações do Touro - Fotos',
    componentName: 'InformacoesTouro',
  },
  {
    key: 'Informações do Touro - Status',
    componentName: 'DadosGeraisTouro',
  },
  {
    key: 'Chat',
    componentName: 'ChatSelection',
  },
];

type Props = {
  navigation: any;
}

const InformacoesGerais = ({ navigation }: Props) => {
  const { handleDeleteUser, isDeleteModalVisible, setIsDeleteModalVisible, isLoading } = useContext(UserContext);

  const deleteUser = useCallback(async () => {
    try {
      await handleDeleteUser()

      navigation.navigate('LoginStackNavigator', { screen: 'Login' });
    } catch (err) {
      console.error('Could not delete user, error: ', err)
    }
  }, []);

  return (
    // @ts-ignore
    <SafeAreaView style={{ flex: 1 }} backgroundColor={Colors.maincolor}>
      {Platform.OS !== 'web' && <LoadingModal loading={isLoading} />}
      <Modal visible={isDeleteModalVisible} transparent animationType="fade">
        {/* @ts-ignore */}
        <View style={styles.modal}>
          {/* @ts-ignore */}
          <View style={styles.modalContent}>
            {/* @ts-ignore */}
            <Text style={styles.modalText}>Deseja realmente excluir sua conta?</Text>
            {/* @ts-ignore */}
            <View style={styles.modalOptionsContainer}>
              <TouchableOpacity
                // @ts-ignore
                style={styles.modalYesBtn}
                disabled={isLoading}
                onPress={deleteUser}>
                {/* @ts-ignore */}
                <Text style={styles.modalOptionText}>Sim</Text>
                {isLoading && Platform.OS === 'web' ? (
                  <ActivityIndicator size="small" color={Colors.blueColor} />
                ) : (
                  <Icon style={{ alignSelf: 'center' }} name="delete" size={20} color="green" />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                // @ts-ignore
                style={styles.modalNoBtn}
                disabled={isLoading}
                onPress={() => setIsDeleteModalVisible(false)}>
                {/* @ts-ignore */}
                <Text style={styles.modalOptionText}>Não</Text>
                <Icon style={{ alignSelf: 'center' }} name="close" size={20} color="red" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      {/* @ts-ignore */}
      <View style={styles.container}>
        {/* @ts-ignore */}
        <Header title={'Menu Geral'} />
        {/* @ts-ignore */}
        <View style={styles.textsContainer}>
          {optionsArray.map(option => (
            // @ts-ignore
            <View style={styles.whiteLine}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate(option.componentName, {
                    navigation: navigation,
                  })
                }>
                {/* @ts-ignore */}
                <Text style={styles.item}>{option.key}</Text>
              </TouchableOpacity>
            </View>
          ))}
          <TouchableOpacity
            // @ts-ignore
            style={[styles.whiteLine]}
            onPress={() => setIsDeleteModalVisible(true)}>
            {/* @ts-ignore */}
            <Text style={styles.item}>Excluir conta</Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default InformacoesGerais;
