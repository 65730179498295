import React from 'react';


import { createStackNavigator } from '@react-navigation/stack';
import InformacoesAdmin from '../pages/informacoesAdm';
import InformacoesUsuarios from '../pages/informacoesUsuarios';
import EditUser from '../pages/editarUsuarios';
import Signup from '../pages/signup';
import InformacoesTouroAdm from '../pages/informacoesTouroAdm';
import PicCarousel from '../components/carousel';
import ChatAdm from '../pages/chatadm';

const Stack = createStackNavigator();

const ChatStackNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="AdmChat">
      <Stack.Screen
        name="AdmChat"
        component={InformacoesAdmin}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ChatAdm"
        // @ts-ignore
        component={ChatAdm}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="InformacoesUsuarios"
        component={InformacoesUsuarios}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="EditUser"
        component={EditUser}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Signup"
        component={Signup}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="InformacoesTouroAdm"
        component={InformacoesTouroAdm}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="PicCarousel"
        component={PicCarousel}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default ChatStackNavigator;