import React from 'react';


import { createStackNavigator } from '@react-navigation/stack';
import Login from '../pages/login';
import AlterarSenha from '../pages/alterarSenha';

const Stack = createStackNavigator();

const LoginStackNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        name="Login"
        component={Login}
        options={{
          headerShown: false,
          // @ts-ignore
          swipeEnabled: false,
        }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={AlterarSenha}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};

export default LoginStackNavigator;